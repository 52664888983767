import React from "react";
import { Media } from "reactstrap";
import Avatar from "../../common/Avatar";
import Flex from "../../common/Flex";

const Person = (props) => {
  const { person } = props;
  const displayName = person.name ? person.name.first + " " + person.name.last : "Unknown";

  const avatar = {
    size: "xl",
    name: displayName,
    round: "circle",
  };

  const personColor = (person) => {
    switch (person.status) {
      case "inactive":
        return "text-danger";
      case "pending":
        return "text-warning";
      default:
        return "";
    }
  };

  const textClass = "mb-0 " + personColor(person);

  return (
    <Media tag={Flex} className="d-inline-flex align-items-center px-1 py-1 mr-1">
      <Avatar {...avatar} />
      <Media body className="ml-2">
        <div className={textClass}>{displayName}</div>
      </Media>
    </Media>
  );
};

export default Person;

import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { ADDRESS, CONTACT } from "../../common/forms/sharedViews";

const HomeInsuranceCard = (props) => {
  const left = [ADDRESS];
  const right = [CONTACT];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default HomeInsuranceCard;

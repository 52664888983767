import classNames from "classnames";
import is from "is_js";
import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Button, Collapse, Nav, Navbar } from "reactstrap";
import bgNavbarImg from "../../assets/img/generic/bg-navbar.png";
import { navbarBreakPoint } from "../../config";
import AppContext from "../../context/Context";
import routes from "../../routes/falcon";
import {
  FAMILY_BINDER,
  ABOUT_MY,
  DASHBOARD,
  RECOMMENDATIONS,
  INBASKET,
  OTHERS_SHARES,
  SHARES,
} from "../../routes/oeRoutes";
import Flex from "../common/Flex";
import Logo from "./Logo";
import NavbarVerticalMenu from "./NavbarVerticalMenu";
import ToggleButton from "./ToggleButton";
import { sharingNavSection } from "../oe/common/util.js";
import OnboardingWizard from "../oe/wizards/OnboardingWizard";

const NavbarVertical = (props) => {
  const { navbarStyle, othersRecords, persons } = props;
  const navBarRef = useRef(null);
  const [showFalcon, setShowFalcon] = useState(false);

  const { showBurgerMenu, isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed } = useContext(AppContext);

  const HTMLClassList = document.getElementsByTagName("html")[0].classList;
  //Control Component did mount and unmounted of hover effect
  if (isNavbarVerticalCollapsed) {
    HTMLClassList.add("navbar-vertical-collapsed");
  }

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add("windows");
    }
    if (is.chrome()) {
      HTMLClassList.add("chrome");
    }
    if (is.firefox()) {
      HTMLClassList.add("firefox");
    }
    return () => {
      HTMLClassList.remove("navbar-vertical-collapsed-hover");
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add("navbar-vertical-collapsed-hover");
      }, 100);
    }
  };

  const falconNavs = () => {
    if (showFalcon) {
      return (
        <>
          <Nav navbar vertical>
            <NavbarVerticalMenu routes={routes} />
          </Nav>
          <hr className="border-300 my-3" />
        </>
      );
    }
  };

  // TODO(ian): replace with an API call.  This will have to do for the demo.
  const sharedWithMe = () => {
    const navs = cloneDeep(OTHERS_SHARES);

    // collect the IDs of people sharing visible cards with the user
    // TODO(ian): the next line is a stupid hack for the pre-funding demo.  The API should not return the hidden prop.
    const visible = othersRecords.filter((e) => !e.hidden);
    const visiblePersons = {};
    visible.forEach((r) => {
      const existing = visiblePersons[r.owner];
      if (existing) {
        return;
      } else {
        const person = persons.find((p) => p.id === r.owner);
        visiblePersons[r.owner] = {
          id: r.owner,
          firstName: person.name.first,
          lastName: person.name.last,
        };
      }
    });

    let children;
    if (Object.keys(visiblePersons).length > 0) {
      children = Object.keys(visiblePersons).map((personId) => {
        const visiblePerson = visiblePersons[personId];
        return sharingNavSection(visiblePerson.firstName + " " + visiblePerson.lastName, personId);
      });
    } else {
      children = [{ name: "No Shares Yet", to: "#" }];
    }

    navs.children = children;
    return navs;
  };

  const navs = () => {
    const routes = [DASHBOARD, RECOMMENDATIONS, ABOUT_MY, FAMILY_BINDER, INBASKET, SHARES];
    const inboundShares = sharedWithMe();
    routes.push(inboundShares);
    return (
      <>
        <Nav navbar vertical>
          <NavbarVerticalMenu routes={routes} />
        </Nav>
      </>
    );
  };

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames("navbar-vertical navbar-glass", {
        [`navbar-${navbarStyle}`]: navbarStyle !== "transparent",
      })}
      light
    >
      <Flex align="center">
        <ToggleButton
          isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
          setIsNavbarVerticalCollapsed={setIsNavbarVerticalCollapsed}
        />
        <Logo at="navbar-vertical" width={160} />
      </Flex>

      <Collapse
        navbar
        isOpen={showBurgerMenu}
        className="scrollbar"
        innerRef={navBarRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => {
          clearTimeout(time);
          HTMLClassList.remove("navbar-vertical-collapsed-hover");
        }}
        style={
          navbarStyle === "vibrant" && {
            backgroundImage: `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbarImg})`,
          }
        }
      >
        {falconNavs()}
        {navs()}

        {process.env.NODE_ENV === "development" && (
          <>
            <hr className="border-300 my-3" />
            <Button onClick={() => setShowFalcon(!showFalcon)}>Other Features</Button>
          </>
        )}
        <hr className="border-300 my-3" />
        <OnboardingWizard />
        {/* </Scrollbar> */}
      </Collapse>
    </Navbar>
  );
};

function mapStateToProps(state) {
  return {
    othersRecords: state && state.othersRecords && state.othersRecords.items ? state.othersRecords.items : [],
    persons: state && state.persons && state.persons.items ? state.persons.items : [],
  };
}

export default connect(mapStateToProps)(NavbarVertical);

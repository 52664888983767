import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Authenticated from "../sandbox/Authenticated";
import Page404 from "../sandbox/Page404";
import SandboxHome from "../sandbox/SandboxHome";
import SignIn from "../sandbox/SignIn";
import SignUp from "../sandbox/SignUp";
import WithOeAuth from "../sandbox/WithOeAuth";

const PublicRoutes = ({match: { url } }) => {
  return (
    <Switch>
      <Route path={`${url}/signup`} exact component={SignUp} />
      <Route path={`${url}/signin`} exact component={SignIn} />

      <Redirect to="/sandbox/404" />
    </Switch>
  )
}

const AuthRoutes = ({match: { url } }) => {
  return (
    <WithOeAuth>
      <Switch>
        <Route path={`${url}/authenticated`} exact component={Authenticated} />

        <Redirect to="/sandbox/404" />
      </Switch>
    </WithOeAuth>
  )
}

const SandboxRoutes = () => (
  <Switch>
    <Route path="/sandbox" exact component={SandboxHome} />
    <Route path="/sandbox/404" exact component={Page404} />
    <Route path="/sandbox/public" component={PublicRoutes} />
    <Route path="/sandbox/" component={AuthRoutes} />

    {/*Redirect*/}
    <Redirect to="/sandbox/404" />
  </Switch>
);

export default SandboxRoutes;

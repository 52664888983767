import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { CONTACT, PLEASE_SELECT } from "../../common/forms/sharedViews";
import { cloneDeep } from "lodash";

const TRUST_ADVISOR_CONTACT = cloneDeep(CONTACT);
TRUST_ADVISOR_CONTACT.name = "trust_advisor";
TRUST_ADVISOR_CONTACT.label = "Trust Advisor Contact";

const TRUST = {
  name: "trust",
  label: "trust Information",
  items: [
    {
      name: "trust_name",
      label: "Name of Trust",
      toolTip: "Enter the legal name of this trust.",
      type: "string",
      validator: null,
    },
    {
      name: "trust_revocable",
      label: "Trust revocable?",
      toolTip: "Select if this trust is revocable.",
      type: "select",
      validator: null,
      options: [PLEASE_SELECT, "Revocable", "Irrevocable", "I don't know"],
    },
    {
      name: "trust_type",
      label: "Type of Trust",
      toolTip: "Select the type of trust.",
      type: "select",
      validator: null,
      options: [PLEASE_SELECT, "Living Trust", "Spousal", "Custodial", "S-Corp", "EBST", "SLAC", "CLAC", "Other"],
    },
    {
      name: "trust_trustee",
      label: "Trustee",
      toolTip: "Enter the name of the trustee for this trust.",
      type: "string",
      validator: null,
    },
    {
      name: "trust_alt_trustee",
      label: "Alternate Trustee",
      toolTip: "Enter the name of the alternate trustee of this trust.",
      type: "string",
      validator: null,
    },
    {
      name: "trust_grantor",
      label: "Grantor",
      toolTip: "Enter the name of the grantor for this trust.",
      type: "string",
      validator: null,
    },
    {
      name: "trust_primary_beneficiary",
      label: "Primary Beneficiaries",
      toolTip: "Enter the names of the primary beneficiaries for this trust.",
      type: "string",
      validator: null,
    },
    {
      name: "trust_secondary_beneficiary",
      label: "Secondary Beneficiaries",
      toolTip: "Enter the names of the secondary beneficiaries for this trust.",
      type: "string",
      validator: null,
    },
    {
      name: "trust_bank",
      label: "Bank Name",
      toolTip: "Enter the name(s) of the bank(s) where accounts are kept for this trust.",
      type: "string",
      validator: null,
    },
    {
      name: "trust_account",
      label: "Account # (Last 4)",
      toolTip: "Enter the account # or last 4 digits for this trust.",
      type: "string",
      validator: null,
    },
    {
      name: "trust_tax_id",
      label: "Trust Tax #",
      toolTip: "Please enter the Tax ID (EIN or SSN) for this trust.",
      type: "string",
      validator: null,
    },
  ],
};

const TrustCard = (props) => {
  const left = [TRUST];
  const right = [TRUST_ADVISOR_CONTACT];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default TrustCard;

/************************ ACTIONS ***************************/

/*************************** REDUCER *************************/

/**
 * REDUCER: accepts the videos slice of the overall state and returns a new version of it.
 */
export function videosReducer(state = { items: {} }, action) {
  return state;
}

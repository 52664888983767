/*
List of form fields shared between complex forms.
*/

export const PLEASE_SELECT = "Please Select";

export const PAYMENT_TYPES = [PLEASE_SELECT, "AutoPay", "Mail", "Online", "Phone", "Other"];

export const ADDRESS = {
  name: "address",
  label: "Address",
  items: [
    {
      name: "street1",
      label: "Address 1",
      type: "string",
      validator: null,
    },
    {
      name: "street2",
      label: "Address 2",
      type: "string",
      validator: null,
    },
    {
      name: "city",
      label: "City",
      type: "string",
      validator: null,
    },
    {
      name: "state",
      label: "State",
      type: "select",
      placeholder: "Choose State",
      validator: null,
      options: [
        PLEASE_SELECT,
        "AL",
        "AK",
        "AR",
        "AZ",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
      ],
    },
    {
      name: "zip",
      label: "Zip",
      type: "string",
      validator: null,
    },
  ],
};

export const CONTACT = {
  name: "contact",
  label: "Contact Name",
  items: [
    {
      name: "first",
      label: "First",
      type: "string",
      validator: null,
    },
    {
      name: "middle",
      label: "Middle",
      type: "string",
      validator: null,
    },
    {
      name: "last",
      label: "Last",
      type: "string",
      validator: null,
    },
    {
      name: "email",
      label: "Email",
      type: "email",
      validator: null,
    },
    {
      name: "phone",
      label: "Phone",
      type: "tel",
      validator: null,
    },
  ],
};

export const PROVIDER = {
  name: "provider",
  label: "Provider",
  items: [
    {
      name: "provider_company",
      label: "Company",
      toolTip: "Enter the name of the provider company",
      type: "string",
      validator: null,
    },
    {
      name: "provider_contact",
      label: "Contact Name",
      toolTip: "Enter the full name of the contact at this company",
      type: "string",
      validator: null,
    },
    {
      name: "provider_email",
      label: "Email",
      toolTip: "Enter the contact's email address",
      type: "email",
      validator: null,
    },
    {
      name: "provider_phone",
      label: "Phone",
      toolTip: "Enter the contact's phone number",
      type: "tel",
      validator: null,
    },
    {
      name: "provider_url",
      label: "Website",
      toolTip: "Enter the web address of the provider company",
      type: "string",
      validator: null,
    },
  ],
};

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, CardBody, Col, Collapse, Row } from "reactstrap";
import { removeNewCard } from "../../../../redux/newCardsSlice.js";
import CustomFieldsView from "../forms/CustomFieldsView";
import FormView from "../forms/FormView";
import { isRecordBlank } from "../util";
import CardFooter from "./CardFooter";
import CardHeader from "./CardHeader";
import CardTitle from "./CardTitle";

const TwoColumnCard = (props) => {
  const { record = { value: {} }, attachments = [], readOnly, id, left, right, newCards, dispatch } = props;
  const [open, setOpen] = useState(newCards.items.includes(id));

  const title = record.value.title;

  const isBlank = isRecordBlank(record.value);

  // unregister this card as a new card when the user leaves the Main (it unmounts)
  // We don't want the returned cleanup function to run on every re-render, so pass in an empty dependency array.
  useEffect(() => {
    if (newCards.items.includes(id) && !readOnly) {
      return () => {
        dispatch(removeNewCard(id));
      };
    }
    //eslint-disable-next-line
  }, []);

  const cols = () => {
    return (
      <>
        <Row>
          <Col lg className="col-sm-6 mx-3">
            {left &&
              left.map((fv) => (
                <FormView
                  key={fv.name}
                  id={id}
                  value={record.value[fv.name]}
                  title={title}
                  fields={fv}
                  readOnly={readOnly}
                />
              ))}
          </Col>
          <Col lg className="col-sm-6 mx-3">
            {right &&
              right.map((fv) => (
                <FormView
                  key={fv.name}
                  id={id}
                  value={record.value[fv.name]}
                  title={title}
                  fields={fv}
                  readOnly={readOnly}
                />
              ))}
          </Col>
        </Row>
        <Row>
          <Col lg className="col-sm-6 mx-3">
            <CustomFieldsView id={id} value={record.value} title={record.value.title} readOnly={readOnly} />
          </Col>
          <Col lg className="col-sm-6 mx-3" />
        </Row>
        <hr />
      </>
    );
  };

  return (
    <Card className="mb-3">
      <CardTitle
        id={id}
        title={title}
        name="title"
        isOpen={open}
        isBlank={isBlank}
        onShowMore={() => setOpen(!open)}
        readOnly={readOnly}
        card={record.card}
      />
      <Collapse isOpen={open}>
        <CardBody className="bg-light border-top">
          <CardHeader />
          {(left || right) && cols()}
          <CardFooter id={id} value={record.value} title={title} attachments={attachments} readOnly={readOnly} />
        </CardBody>
      </Collapse>
    </Card>
  );
};

function mapStateToProps(state) {
  return {
    newCards: state.newCards,
  };
}

export default connect(mapStateToProps)(TwoColumnCard);

import { createSlice } from "@reduxjs/toolkit";

/**
 * State Shape:
 *{
 *  items: [
 *    {
 *      id: "rebecca_med_remicade",
 *      owner: "person006",
 *      eventId: "others_event_rebecca_incap",  // temporary, for demo only.
 *      hidden: true,  // temporary, for demo only.
 *      type: "some/other/type",
 *      value: {
 *        key: "value",
 *        key2: "value2",
 *        sub_record: {
 *          more: "stuff",
 *        }
 *      }
 *    },
 *    ...
 *  ]
 *}
 */

// uses Immer internally to maintain state immutability
export const othersRecordsSlice = createSlice({
  name: "othersRecords",
  initialState: {
    items: []
  },
  reducers: {
    /**
     * payload: event id
     */
    showCardsForEvent: (state, action) => {
      state.items = state.items.map((i) => {
        if (i.eventId === action.payload) {
          i.hidden = false;
        }
        return i;
      })
    },
  }
});

export const { showCardsForEvent } = othersRecordsSlice.actions;

export default othersRecordsSlice.reducer;

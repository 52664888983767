import React, { useState } from "react";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import CustomInput from "reactstrap/lib/CustomInput";

const ResidenceStage = (props) => {
  return (
    <>
      <Row className="mb-0 mt-0">
        <em>My Primary Residence</em>
      </Row>
      <hr />
      <Row>
        <div className="d-flex flex-column">
          <CustomInput
            type="radio"
            id="wizard_facts_residence1"
            name="wizard_facts_residenceRadio"
            label="I own my primary residence"
            inline
          />
          <CustomInput
            type="radio"
            id="wizard_facts_residence2"
            name="wizard_facts_residenceRadio"
            label="Rent or lease"
            inline
          />
          <CustomInput
            type="radio"
            id="wizard_facts_residence3"
            name="wizard_facts_residenceRadio"
            label="I live with family or friends"
            inline
          />
          <CustomInput
            type="radio"
            id="wizard_facts_residence4"
            name="wizard_facts_residenceRadio"
            label="I live at a facility"
            inline
          />
          <CustomInput
            type="radio"
            id="wizard_facts_residence5"
            name="wizard_facts_residenceRadio"
            label="Other"
            inline
          />
        </div>
      </Row>
    </>
  );
};

const RelationshipStage = (props) => {
  return (
    <>
      <Row className="mb-0 mt-0">
        <em>My Relationship</em>
      </Row>
      <hr />
      <Row>
        <div className="d-flex flex-column">
          <CustomInput
            type="radio"
            id="wizard_facts_relationship1"
            name="wizard_facts_relationshipRadio"
            label="Single"
            inline
          />
          <CustomInput
            type="radio"
            id="wizard_facts_relationship2"
            name="wizard_facts_relationshipRadio"
            label="Married"
            inline
          />
          <CustomInput
            type="radio"
            id="wizard_facts_relationship3"
            name="wizard_facts_relationshipRadio"
            label="With Partner"
            inline
          />
          <CustomInput
            type="radio"
            id="wizard_facts_relationship4"
            name="wizard_facts_relationshipRadio"
            label="Separated"
            inline
          />
          <CustomInput
            type="radio"
            id="wizard_facts_relationship5"
            name="wizard_facts_relationshipRadio"
            label="Divorced"
            inline
          />
          <CustomInput
            type="radio"
            id="wizard_facts_relationship6"
            name="wizard_facts_relationshipRadio"
            label="Other"
            inline
          />
        </div>
      </Row>
    </>
  );
};

const DependentsStage = (props) => {
  return (
    <>
      <Row className="mb-0 mt-0">
        <em>I have the following Dependents</em>
      </Row>
      <hr />
      <Row>
        <div className="d-flex flex-column">
          <CustomInput type="checkbox" id="wizard_facts_minorDependent" label="Dependents UNDER 18" inline />
          <CustomInput type="checkbox" id="wizard_facts_adultDependents" label="Dependents OVER 18" inline />
          <CustomInput type="checkbox" id="wizard_facts_pets" label="Pets" inline />
        </div>
      </Row>
    </>
  );
};

const EmploymentStage = (props) => {
  return (
    <>
      <Row className="mb-0 mt-0">
        <em>Employment</em>
      </Row>
      <hr />
      <Row>
        <div className="d-flex flex-column">
          <CustomInput type="checkbox" id="wizard_facts_isEmployed" label="I am Currently Employed" inline />
          <CustomInput type="checkbox" id="wizard_facts_isBizOwner" label="I own a business" inline />
          <CustomInput type="checkbox" id="wizard_facts_isRetired" label="I am Retired" inline />
        </div>
      </Row>
    </>
  );
};

const InsuranceStage = (props) => {
  return (
    <>
      <Row className="mb-0 mt-0">
        <em>I have the following Insurance</em>
      </Row>
      <hr />
      <Row>
        <div className="d-flex flex-column">
          <CustomInput type="checkbox" id="wizard_facts_healthInsurance" label="Health Insurance" inline />
          <CustomInput type="checkbox" id="wizard_facts_lifeInsurance" label="Life Insurance" inline />
          <CustomInput type="checkbox" id="wizard_facts_disabilityInsurance" label="Disability Insurance" inline />
          <CustomInput type="checkbox" id="wizard_facts_longTermInsurance" label="Long Term Care Insurance" inline />
          <CustomInput type="checkbox" id="wizard_facts_propertyInsurance" label="Property Insurance" inline />
        </div>
      </Row>
    </>
  );
};

const LegalStage = (props) => {
  return (
    <>
      <Row className="mb-0 mt-0">
        <em>I have signed the following Legal Documents</em>
      </Row>
      <hr />
      <Row>
        <div className="d-flex flex-column">
          <CustomInput type="checkbox" id="wizard_facts_will" label="A Will" inline />
          <CustomInput type="checkbox" id="wizard_facts_trust" label="A Trust" inline />
          <CustomInput type="checkbox" id="wizard_facts_financePOA" label="Financial Power of Attorney" inline />
          <CustomInput type="checkbox" id="wizard_facts_healthPOA" label="Health Power of Attorney" inline />
          <CustomInput
            type="checkbox"
            id="wizard_facts_AdvanceDirective"
            label="Living Will or Advance Directive"
            inline
          />
        </div>
      </Row>
    </>
  );
};

const ProfessionalsStage = (props) => {
  return (
    <>
      <Row className="mb-0 mt-0">
        <em>I use the following Professionals</em>
      </Row>
      <hr />
      <Row>
        <div className="d-flex flex-column">
          <CustomInput type="checkbox" id="wizard_facts_primaryPhysician" label="Primary Care Physician" inline />
          <CustomInput type="checkbox" id="wizard_facts_attorney" label="Attorney" inline />
          <CustomInput type="checkbox" id="wizard_facts_accountant" label="Accountant" inline />
          <CustomInput type="checkbox" id="wizard_facts_financialPlanner" label="Financial Planner" inline />
          <CustomInput type="checkbox" id="wizard_facts_taxAdvisor" label="Tax Advisor" inline />
        </div>
      </Row>
    </>
  );
};

const DigitalStage = (props) => {
  return (
    <>
      <Row className="mb-0 mt-0">
        <em>I use the following to access my Digital Devices</em>
      </Row>
      <hr />
      <Row>
        <div className="d-flex flex-column">
          <CustomInput type="checkbox" id="wizard_facts_isComputerPassword" label="Password for Computer" inline />
          <CustomInput type="checkbox" id="wizard_facts_isPhoneUnlock" label="Code to Unlock Phone" inline />
          <CustomInput type="checkbox" id="wizard_facts_hasPasswordManager" label="A Password Manager" inline />
          <CustomInput type="checkbox" id="wizard_facts_hasAuthDevice" label="Authentication Device" inline />
        </div>
      </Row>
    </>
  );
};

const componentForStage = (i) => {
  switch (i) {
    case 1:
      return ResidenceStage;
    case 2:
      return RelationshipStage;
    case 3:
      return DependentsStage;
    case 4:
      return EmploymentStage;
    case 5:
      return InsuranceStage;
    case 6:
      return LegalStage;
    case 7:
      return ProfessionalsStage;
    case 8:
      return DigitalStage;
    default:
      return null;
  }
};

const MIN_STAGE = 1;
const MAX_STAGE = 8;

const WizardStage = (props) => {
  const { stage } = props;

  const Component = componentForStage(stage);
  if (Component) {
    return <Component {...props} />;
  } else {
    return <span>No such stage: {stage}</span>;
  }
};

const OnboardingWizard = (props) => {
  const [open, setOpen] = useState();
  const [stage, setStage] = useState(1);

  const onDone = () => {
    setOpen(false);
  };

  const onNext = () => {
    if (stage <= MAX_STAGE) {
      setStage(stage + 1);
    } else {
      console.error("Already at max stage!");
    }
  };

  const onBack = () => {
    if (stage >= MIN_STAGE) {
      setStage(stage - 1);
    } else {
      console.error("Already at min stage!");
    }
  };

  const onCancel = () => {
    setOpen(false);
    setStage(MIN_STAGE);
  };

  return (
    <>
      <Modal size="md" isOpen={open}>
        <ModalBody className="ml-2">
          <WizardStage stage={stage} />
          {/* buttons */}
          <hr />
          <Row className="d-flex justify-content-end">
            <Col>
              <Button color="secondary" onClick={onCancel}>
                Cancel
              </Button>
              {stage > MIN_STAGE && (
                <Button color="secondary" className="ml-1" onClick={onBack}>
                  Back
                </Button>
              )}
            </Col>
            <Col className="col-auto">
              {stage < MAX_STAGE && (
                <Button color="primary" className="mr-2" onClick={onNext}>
                  Next
                </Button>
              )}
              {stage === MAX_STAGE && (
                <Button color="primary" className="mr-2" onClick={onDone}>
                  Done
                </Button>
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Button
        className={"mb-3"}
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        Life Facts Interview
      </Button>
    </>
  );
};

export default OnboardingWizard;

import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { PLEASE_SELECT } from "../../common/forms/sharedViews";

const MEDICATION = {
  name: "medication",
  label: "Medication",
  items: [
    {
      name: "name",
      label: "Name",
      type: "string",
      validator: null,
    },
    {
      name: "nickName",
      label: "Nickname",
      type: "string",
      validator: null,
    },
    {
      name: "dosageAmount",
      label: "Dosage",
      type: "string",
      validator: null,
    },
    {
      name: "dosageFrequency",
      label: "Frequency",
      type: "string",
      validator: null,
    },
    {
      name: "location",
      label: "Location",
      type: "string",
      validator: null,
    },
    {
      name: "importance",
      label: "Importance",
      type: "select",
      validator: null,
      options: [PLEASE_SELECT, "Critical", "High", "Medium", "Low"],
    },
    {
      name: "isPrescribed",
      label: "Prescribed?",
      type: "checkbox",
      // type: "select",
      // validator: null,
      // options: ["Yes", "No"],
    },
  ],
};

const PRESCRIPTION = {
  name: "prescription",
  label: "Prescription",
  items: [
    {
      name: "prescriberName",
      label: "Prescribed By",
      type: "string",
      validator: null,
    },
    {
      name: "prescriberPhone",
      label: "Phone",
      type: "string",
      validator: null,
    },
    {
      name: "prescriberEmail",
      label: "Email",
      type: "string",
      validator: null,
    },
    {
      name: "startDate",
      label: "Started",
      type: "string",
      validator: null,
    },
    {
      name: "endDate",
      label: "Will End",
      type: "string",
      validator: null,
    },
    {
      name: "isRefillable",
      label: "Refillable?",
      type: "checkbox",
      // type: "select",
      // validator: null,
      // options: ["Yes", "No"],
    },
    {
      name: "fulfilledLocation",
      label: "Where Filled",
      type: "string",
      validator: null,
    },
    {
      name: "fulfilledPhone",
      label: "Phone",
      type: "string",
      validator: null,
    },
  ],
};

const MedicationCard = (props) => {
  const left = [MEDICATION];
  const right = [PRESCRIPTION];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default MedicationCard;

/**
 * State Shape:
 *
 *    settings: {
    display: {
      showGuides: true,
      theme: "light"
    },
    billing: {
      autoRenewal: true,
      notifyRenewal: false
    },
    access: {
      MFA: true,
      MFAtype: "text",
      userName: "GeorgeW"
    },
    messages: {
      sortBy: "date",
      allowForward: false
    },
  }
 */

/************************ ACTIONS ***************************/

const UPDATE_SETTING = "settings/update";

/**
 * Accepts the setting to update.
 */
export const updateSetting = (category, name, value) => ({
  type: UPDATE_SETTING,
  payload: {
    category,
    name,
    value,
  },
});

/*************************** REDUCER *************************/

const defaultState = {};

/**
 * REDUCER: accepts the settings slice of the overall state and returns a new version of it.
 */
export function settingsReducer(state = defaultState, action) {
  return state;
}

// TODO(ian): when we ever use this rewrite it to use redux tools createSlice.

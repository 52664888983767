import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidV4 } from "uuid";

/**
 * State Shape:
  shares: {
    items: [
      {
        id: "share1",
        record: "12345",
        recipient: "person1",
        event: "event1"
      },
      {
        id: "share2",
        record: "12345",
        recipient: "person2",
        event: "event2"
      },
      {
        id: "share3",
        record: "12345",
        recipient: "person3",
        event: "event3"
      }
    ]
  },
 */

/************************ UTIL ***************************/
export function newShare(personId, recordId, eventId) {
  return {
    id: uuidV4(),
    recipient: personId,
    record: recordId,
    event: eventId
  }
}

// uses Immer internally to maintain state immutability
export const sharesSlice = createSlice({
  name: "shares",
  initialState: {
    items: []
  },
  reducers: {
    /**
     * payload: new share (see newShare function)
     */
    addShare: (state, action) => {
      state.items.push(action.payload);
    },
    /**
     * payload: share id
     */
    deleteShare: (state, action) => {
      state.items = state.items.filter((i) => i.id !== action.payload);
    },
  }
});

export const { addShare, deleteShare } = sharesSlice.actions;

export default sharesSlice.reducer;


/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:da47d9fb-8eef-49f8-a136-e98935fb746b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_VCXNFyMeb",
    "aws_user_pools_web_client_id": "i9dauj7ea04ujjeav4a4gl5bd",
    "oauth": {}
};


export default awsmobile;

import { cloneDeep } from "lodash";
import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { ADDRESS, CONTACT } from "../../common/forms/sharedViews";

const EMERGENCY_CONTACT_ADDRESS = cloneDeep(ADDRESS);
EMERGENCY_CONTACT_ADDRESS.name = "emergency_address";
EMERGENCY_CONTACT_ADDRESS.label = "Emergency Contact Address";

const EMERGENCY_CONTACT_CONTACT = cloneDeep(CONTACT);
EMERGENCY_CONTACT_CONTACT.name = "emergency_contact_name";
EMERGENCY_CONTACT_CONTACT.label = "Emergency Contact";

const EMERGENCY_CONTACT = {
  name: "emergency_contact",
  label: "Emergency Contact",
  items: [
    {
      name: "emergency_relation",
      label: "Relationship",
      toolTip: "Enter your relationship to this emergency contact.",
      type: "string",
      validator: null,
    },
    {
      name: "emergency_instructions",
      label: "Instructions",
      toolTip: "Enter instructions for this emergency contact.",
      type: "textarea",
      validator: null,
    },
  ],
};

const EmergencyContactCard = (props) => {
  const left = [EMERGENCY_CONTACT_CONTACT, EMERGENCY_CONTACT];
  const right = [EMERGENCY_CONTACT_ADDRESS];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default EmergencyContactCard;

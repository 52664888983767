import { createSlice } from '@reduxjs/toolkit'

/**
 * State Shape:
  events: {
    items: [
      {
        id: "event1",
        title: "My Incapacity",
      },
      {
        id: "event2",
        title: "Ray's Return",
      }
    ]
  },
 */

// uses Immer internally to maintain state immutability
export const eventsSlice = createSlice({
  name: 'events',
  initialState: {
    items: []
  },
  reducers: {
    /**
     * payload: new event
     */
    createEvent: (state, action) => {
      state.items = [...state.items, action.payload];
    },
    /**
     * payload: eventId
     */
    deleteEvent: (state, action) => {
      state.items = state.items.filter((i) => i.id !== action.payload);
    }
  }
});

export const { createEvent, deleteEvent } = eventsSlice.actions;

export default eventsSlice.reducer;

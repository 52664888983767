import product12 from "../../assets/img/products/12.jpg";
import product10 from "../../assets/img/products/10.jpg";
import product11 from "../../assets/img/products/11.jpg";
import product14 from "../../assets/img/products/14.jpg";
import product13 from "../../assets/img/products/13.jpg";

export default [
  {
    id: 1,
    img: product12,
    title: "Raven Pro",
    type: "Landing",
    unit: 19,
    price: 69,
    progress: 38,
    time: "12:50:00",
    color: "warning",
  },
  {
    id: 2,
    img: product10,
    title: "Boots4",
    type: "Portfolio",
    unit: 10,
    price: 86,
    progress: 79,
    time: "25:20:00",
    color: "info",
  },
  {
    id: 3,
    img: product11,
    title: "Falcon",
    type: "Admin",
    unit: 11,
    price: 49,
    progress: 90,
    time: "58:20:00",
    color: "primary",
  },
  {
    id: 4,
    img: product14,
    title: "Slick",
    type: "Builder",
    unit: 5,
    price: 49,
    progress: 40,
    time: "21:20:00",
    color: "danger",
  },
  {
    id: 5,
    img: product13,
    title: "Reign Pro",
    type: "Agency",
    unit: 6,
    price: 39,
    progress: 78,
    time: "31:50:00",
    color: "success",
  },
];

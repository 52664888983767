import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Col, Collapse, Row } from "reactstrap";
import { deleteShare } from "../../../redux/sharesSlice.js";
import ButtonIcon from "../../common/ButtonIcon.js";
import RecordDetailRow from "../common/forms/RecordDetailRow.js";
import Person from "../common/Person.js";
import SharingWizard from "../wizards/SharingWizard.js";

const Shares = (props) => {
  const { id, events, persons, readOnly, getShares, dispatch } = props;
  const [collapsed, setCollapsed] = useState(true);

  const shares = getShares(id);

  const doDelete = (shareId) => {
    dispatch(deleteShare(shareId));
  };

  // group recipients by event
  let rows = {};
  shares.forEach((share) => {
    const row = rows[share.event];
    if (row) {
      row.push(share);
    } else {
      rows[share.event] = [share];
    }
  });

  return (
    <div className="mb-3">
      <Row>
        <Col>
          <ButtonIcon
            color="falcon-default"
            size="sm"
            icon={collapsed ? "chevron-down" : "chevron-up"}
            className="ml-1"
            iconClassName="fs--2"
            onClick={() => setCollapsed(!collapsed)}
          >
            Shares ({shares.length})
          </ButtonIcon>
          {!readOnly && <SharingWizard recordId={id} standalone={false} readOnly={readOnly} />}
        </Col>
      </Row>
      <Collapse isOpen={!collapsed}>
        <Row>
          <Col>
            {Object.keys(rows).map((eventId, i) => {
              const title = events.find((e) => e.id === eventId).title;
              return (
                <RecordDetailRow
                  key={eventId}
                  title={title}
                  isLastItem={i === Object.keys(rows).length - 1}
                  centerLabel
                  className="mt-1"
                >
                  {rows[eventId].map((share) => {
                    const person = persons.find((p) => p.id === share.recipient);
                    return (
                      <span key={person.id} className="d-inline-flex mx-2 align-items-center">
                        <Person person={person} />
                        {!readOnly && (
                          <FontAwesomeIcon
                            color="falcon-danger"
                            size="sm"
                            icon="times"
                            className="fs--2 falcon-danger"
                            onClick={() => doDelete(share.id)}
                          />
                        )}
                      </span>
                    );
                  })}
                </RecordDetailRow>
              );
            })}
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};

const mapStateToProps = (state) => {
  const getShares = (id) => {
    return state.shares && state.shares.items ? state.shares.items.filter((i) => i.record === id) : [];
  };
  return {
    events: state.events && state.events.items ? state.events.items : [],
    persons: state.persons && state.persons.items ? state.persons.items : [],
    getShares,
  };
};

export default connect(mapStateToProps)(Shares);

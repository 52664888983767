import { createSlice } from '@reduxjs/toolkit'

/**
 * State Shape:
 *{
 *  items: [
 *    {
 *      id: "23456",
 *      type: "some/other/type",
 *      value: {
 *        key: "value",
 *        key2: "value2",
 *        sub_record: {
 *          more: "stuff",
 *        }
 *      }
 *    },
 *    ...
 *  ]
 *}
 */

// note that createSlice internally uses Immer to maintain immutability, so we're not really mutating here,
// although we can pretend that we are for simplicity's sake.
export const recordsSlice = createSlice({
  name: "records",
  initialState: {
    items: []
  },
  reducers: {
    /**
     * payload: a new full record
     */
    addRecord: (state, action) => {
      state.items = [action.payload, ...state.items];
    },
    /**
     * payload: {
     *   id,
     *   name,
     *   newSubvalue
     * }
     */
    updateRecordSubvalue: (state, action) => {
      const index = state.items.findIndex((i) => i.id === action.payload.id);
      if (index >= 0) {
        state.items[index].value[action.payload.name] = action.payload.newSubvalue;
      }
    },
    /**
     * payload: {
     *   id,
     *   name
     * }
     */
    clearRecordSubvalue: (state, action) => {
      const index = state.items.findIndex((i) => i.id === action.payload.id);
      if (index >= 0) {
        delete state.items[index].value[action.payload.name];
      }
    },
    /**
     * payload: id
     */
    deleteRecord: (state, action) => {
      state.items = state.items.filter((i) => i.id !== action.payload);
    }
  }
});

export const { addRecord, updateRecordSubvalue, clearRecordSubvalue, deleteRecord } = recordsSlice.actions;

export default recordsSlice.reducer;

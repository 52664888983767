import React from "react";

const SignIn = (props) => {
  const { setLoggedIn } = props;

  return (
    <>
      <div>Sign In (unauthenticated page)</div>
      <form>
        <label>
          <p>Username</p>
          <input type="text" />
        </label>
        <label>
          <p>Password</p>
          <input type="password" />
        </label>
        <div>
          <button type="submit" onClick={() => setLoggedIn(true)}>Submit</button>
        </div>
        <div>Just press Submit.  The form doesn't do anything.</div>
      </form>
    </>
  )
}

export default SignIn;

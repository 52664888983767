import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { PROVIDER, PLEASE_SELECT } from "../../common/forms/sharedViews";

const HEALTH_INSURANCE = {
  name: "health_insurance",
  label: "Health Insurance",
  items: [
    {
      name: "policy_type",
      label: "Type of Policy",
      toolTip: "What does this policy cover?",
      type: "select",
      validator: null,
      options: [PLEASE_SELECT, "Health Plan", "Dental", "Vision", "Drug", "Hospital Only", "Medical Only"],
    },
    {
      name: "policy_source",
      label: "Policy Source",
      toolTip: "Who is managing this policy?",
      type: "select",
      validator: null,
      options: [
        PLEASE_SELECT,
        "Private Health Plan",
        "Employee Health Plan",
        "Medicare Part A",
        "Medicare Part B",
        "Medicare Supplemental",
        "Medicaid",
        "Other",
      ],
    },
    {
      name: "insured_primary",
      label: "Primary Insured",
      toolTip: "Who is the Primary Insured account holder?",
      type: "string",
      validator: null,
    },
    {
      name: "insured_other",
      label: "Other Insured",
      toolTip: "Who else is insured under this policy?",
      type: "string",
      validator: null,
    },
    {
      name: "policy_number",
      label: "Policy #",
      toolTip: "What is the Policy, Account or Medicare number?",
      type: "string",
      validator: null,
    },
    {
      name: "group_number",
      label: "Group #",
      toolTip: "What is the Group number (if applicable)?",
      type: "string",
      validator: null,
    },
    {
      name: "policy_network",
      label: "Network",
      toolTip: "Is this a PPO, HMO, or other network policy?",
      type: "select",
      validator: null,
      options: [PLEASE_SELECT, "PPO", "HMO", "Other"],
    },
    {
      name: "policy_deductible",
      label: "Deductible",
      toolTip: "What is the deductible for this policy?",
      type: "string",
      validator: null,
    },
    {
      name: "policy_name",
      label: "Policy Name",
      toolTip: "What does the insurer call this policy?",
      type: "string",
      validator: null,
    },
    {
      name: "policy_status",
      label: "Policy Status",
      toolTip: "What is the active status of this policy?",
      type: "string",
      validator: null,
    },
  ],
};

const HealthInsuranceCard = (props) => {
  const left = [HEALTH_INSURANCE];
  const right = [PROVIDER];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default HealthInsuranceCard;

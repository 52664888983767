import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Collapse, Modal, ModalBody, Row } from "reactstrap";
import { addShare, newShare } from "../../../redux/sharesSlice.js";
import ButtonIcon from "../../common/ButtonIcon.js";
import FalconCardHeader from "../../common/FalconCardHeader.js";
import { CARDS, thisOrNone } from "../common/cards/cardDefs.js";
import Person from "../common/Person.js";
import AddNewPersonWizard from "./AddNewPersonWizard.js";

const WhoForm = (props) => {
  const { persons, onNext, onCancel, onClick, value } = props;

  const classNames = (id) => {
    if (id === value) {
      return "d-flex ml-3 border rounded-pill border-primary";
    } else {
      return "d-flex ml-3";
    }
  };

  const sortedPersons = persons.sort((a, b) => {
    const aName = a.name.first + " " + a.name.last;
    const bName = b.name.first + " " + b.name.last;
    return aName.localeCompare(bName);
  });

  return (
    <>
      <span className="font-weight-bold">Share with whom:</span>
      <hr />
      {sortedPersons.map((p) => (
        <Row key={p.id}>
          <span className={classNames(p.id)} onClick={() => onClick(p.id)}>
            <Person person={p} />
          </span>
        </Row>
      ))}
      <hr />
      <Row className="d-flex justify-content-end">
        <Col>
          <Button onClick={onCancel}>Cancel</Button>
        </Col>
        <Col className="col-auto">
          <AddNewPersonWizard />
          <Button color="primary" disabled={!value} className="mx-2" onClick={onNext}>
            Next
          </Button>
        </Col>
      </Row>
    </>
  );
};

const Record = (props) => {
  const { recordAndCategory, value, onClick } = props;

  const classNames = (id) => {
    if (id === value) {
      return "d-flex ml-3 p-1 border rounded-pill border-primary";
    } else {
      return "d-flex ml-3 p-1";
    }
  };

  return (
    <Row className={classNames(recordAndCategory.record.id)} onClick={() => onClick(recordAndCategory.record.id)}>
      <Col>
        {recordAndCategory.record.value.title} ({recordAndCategory.card.display})
      </Col>
    </Row>
  );
};

const RecordGroup = (props) => {
  const { value, onClick, group, title } = props;
  const [open, setOpen] = useState(false);

  return (
    <Card className="mb-3">
      <FalconCardHeader title={thisOrNone(title, "No Title")} className="ml-3">
        <ButtonIcon
          color="falcon-default"
          size="sm"
          icon={open ? "chevron-up" : "chevron-down"}
          className="ml-1"
          iconClassName="fs--2"
          onClick={() => setOpen(!open)}
        >
          {open ? "see less" : "see more"}
        </ButtonIcon>
      </FalconCardHeader>
      <Collapse isOpen={open}>
        <CardBody className="bg-light border-top">
          {group.map((rc) => (
            <Record key={rc.record.id} recordAndCategory={rc} value={value} onClick={onClick} />
          ))}
        </CardBody>
      </Collapse>
    </Card>
  );
};

const RecordForm = (props) => {
  const { records, onSubmit, onBack, onCancel, value, onClick } = props;

  const recordsAndCategories = records.flatMap((r) => {
    const cardKey = Object.keys(CARDS).find((c) => r.type === CARDS[c].type);
    const card = CARDS[cardKey];
    return card.categories.map((c) => ({
      category: c,
      record: r,
      card: card,
    }));
  });

  const sortedRecordsAndCategories = recordsAndCategories.sort((a, b) => {
    if (a.category !== b.category) {
      return a.category.localeCompare(b.category);
    } else {
      return a.record.value.title.localeCompare(b.record.value.title);
    }
  });

  const groupedSortedRecordsAndCategories = {};
  sortedRecordsAndCategories.forEach((rc) => {
    const group = groupedSortedRecordsAndCategories[rc.category];
    if (group) {
      group.push(rc);
    } else {
      groupedSortedRecordsAndCategories[rc.category] = [rc];
    }
  });

  return (
    <>
      <span className="font-weight-bold">Share What:</span>
      <hr />
      {Object.keys(groupedSortedRecordsAndCategories).map((group) => (
        <RecordGroup
          key={group}
          group={groupedSortedRecordsAndCategories[group]}
          title={group}
          value={value}
          onClick={onClick}
        />
      ))}
      <hr />
      <Row className="d-flex justify-content-end">
        <Col>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={onBack} className="ml-1">
            Back
          </Button>
        </Col>
        <Col className="col-auto">
          <Button color="primary" disabled={!value} className="mr-2" onClick={onSubmit}>
            Done
          </Button>
        </Col>
      </Row>
    </>
  );
};

const EventForm = (props) => {
  const { events, onNext, onSubmit, onBack, onCancel, lastStage, value, onClick } = props;

  const classNames = (id) => {
    if (id === value) {
      return "d-flex ml-3 p-1 border rounded-pill border-primary";
    } else {
      return "d-flex ml-3 p-1";
    }
  };

  const sortedEvents = events.sort((a, b) => {
    return a.title.localeCompare(b.title);
  });

  return (
    <>
      <span className="font-weight-bold">Share When:</span>
      <hr />
      {sortedEvents.map((e) => (
        <Row key={e.id}>
          <span className={classNames(e.id)} onClick={() => onClick(e.id)}>
            {e.title}
          </span>
        </Row>
      ))}
      <hr />
      <Row className="d-flex justify-content-end">
        <Col>
          <Button color="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button color="secondary" className="ml-1" onClick={onBack}>
            Back
          </Button>
        </Col>
        <Col className="col-auto">
          {!lastStage && (
            <Button color="primary" disabled={!value} className="mr-2" onClick={onNext}>
              Next
            </Button>
          )}
          {lastStage && (
            <Button color="primary" disabled={!value} className="mr-2" onClick={onSubmit}>
              Done
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

const SharingWizard = (props) => {
  const { persons, records, events, shares, dispatch, recordId: originalRecordId, standalone = true, readOnly } = props;

  const [stage, setStage] = useState("person");
  const [personId, setPersonId] = useState();
  const [eventId, setEventId] = useState();
  const [recordId, setRecordId] = useState(originalRecordId);
  const [open, setOpen] = useState();

  const onDone = () => {
    if (readOnly) {
      return;
    }
    // check if this share already exists.
    const match = shares.find((s) => s.recipient === personId && s.record === recordId && s.event === eventId);
    if (match) {
      alert("That share already exists!");
    } else {
      dispatch(addShare(newShare(personId, recordId, eventId)));
      setOpen(false);
    }
  };

  const cancel = () => {
    setOpen(false);
  };

  const init = () => {
    setPersonId(null);
    setEventId(null);
    setRecordId(originalRecordId);
    setStage("person");
  };

  // if we were given a record ID, because the user clicked to share a specific card,
  // then skip the "record" stage.  Stages order is person -> event -> record
  const nextStage = () => {
    if (stage === "person") {
      return "event";
    } else if (stage === "event") {
      if (originalRecordId) {
        return null;
      } else {
        return "record";
      }
    }
    return null;
  };

  const prevStage = () => {
    if (stage === "record") {
      return "event";
    } else if (stage === "event") {
      return "person";
    }
  };

  return (
    <>
      <Modal size={stage === "record" ? "lg" : "md"} isOpen={open}>
        <ModalBody>
          {stage === "person" && (
            <WhoForm
              persons={persons}
              value={personId}
              onClick={(selected) => setPersonId(selected)}
              onNext={() => setStage(nextStage())}
              onCancel={() => cancel()}
            />
          )}
          {stage === "event" && (
            <EventForm
              events={events}
              value={eventId}
              onClick={(selected) => setEventId(selected)}
              onNext={() => setStage(nextStage())}
              onBack={() => setStage(prevStage())}
              onCancel={() => cancel()}
              // If there's a record ID provided then we skip the final record stage, and need to show a Done button
              // instead of a "Next" button.
              lastStage={originalRecordId}
              onSubmit={() => onDone()}
            />
          )}
          {stage === "record" && (
            <RecordForm
              records={records}
              value={recordId}
              onClick={(selected) => setRecordId(selected)}
              onSubmit={() => onDone()}
              onBack={() => setStage(prevStage())}
              onCancel={() => cancel()}
            />
          )}
        </ModalBody>
      </Modal>
      {standalone && !readOnly && (
        <Button
          className={props.className}
          color="primary"
          onClick={() => {
            init();
            setOpen(true);
          }}
        >
          Add A Share
        </Button>
      )}
      {!standalone && !readOnly && (
        <ButtonIcon
          color="falcon-default"
          size="sm"
          icon="share-alt"
          className="ml-1"
          iconClassName="fs--2"
          onClick={() => {
            init();
            setOpen(true);
          }}
        >
          Share This
        </ButtonIcon>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    persons: state.persons && state.persons.items ? state.persons.items : [],
    records: state.records && state.records.items ? state.records.items : [],
    events: state.events && state.events.items ? [...state.events.items] : [],
    shares: state.shares && state.shares.items ? state.shares.items : [],
  };
};

export default connect(mapStateToProps)(SharingWizard);

/**
 * An array of IDs of cards that have been created this session.  Cards in this array should be rendered expanded
 * by default.
 */

import { createSlice } from '@reduxjs/toolkit'

// uses Immer internally to maintain state immutability
export const newCardsSlice = createSlice({
  name: "newCards",
  initialState: {
    items: []
  },
  reducers: {
    /**
     * payload: card id
     */
    registerNewCard: (state, action) => {
      state.items.push(action.payload);
    },
    /**
     * payload: event id
     */
    removeNewCard: (state, action) => {
      state.items = state.items.filter((i) => i !== action.payload);
    }
  }
});

export const { registerNewCard, removeNewCard } = newCardsSlice.actions;

export default newCardsSlice.reducer;

import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { ADDRESS, CONTACT, PLEASE_SELECT } from "../../common/forms/sharedViews";

const RETIREMENT_ACCT = {
  name: "retirement_acct",
  label: "Retirement Account",
  items: [
    {
      name: "acct_type",
      label: "Type",
      type: "select",
      validator: null,
      options: [PLEASE_SELECT, "Regular IRA", "Roth IRA", "Pension", "Annuity", "Other"],
    },
    {
      name: "institution",
      label: "Institution",
      type: "string",
      validator: null,
    },
  ],
};

const RetirementAccountCard = (props) => {
  const left = [RETIREMENT_ACCT];
  const right = [ADDRESS, CONTACT];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default RetirementAccountCard;

import React from "react";
import { Row, Col } from "reactstrap";
import classNames from "classnames";

const RecordDetailRow = ({ title, isLastItem, children, centerLabel, className }) => (
  <Row className={className}>
    <Col
      className={classNames("d-flex border-top justify-content-end", {
        "border-bottom": isLastItem,
      })}
      sm={3}
    >
      <span className={classNames("font-weight-semi-bold text-right my-1", { "align-self-center": centerLabel })}>
        {title}
      </span>
    </Col>
    <Col className="d-flex flex-wrap justify-content-start align-items-center bg-200">{children}</Col>
  </Row>
);

export default RecordDetailRow;

import React from "react";
import { cloneDeep } from "lodash";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { ADDRESS, CONTACT } from "../../common/forms/sharedViews";

const HOME_INSTRUCTION_CONTACT = cloneDeep(CONTACT);
HOME_INSTRUCTION_CONTACT.name = "contact_name";
HOME_INSTRUCTION_CONTACT.label = "Contact Information";

const HOME_INSTRUCTION = {
  name: "home_instructions",
  label: "Home Instructions",
  items: [
    {
      name: "home_contact_name",
      label: "Name",
      toolTip: "Enter the name of this home contact.",
      type: "string",
      validator: null,
    },
    {
      name: "home_contact_relation",
      label: "Relationship",
      toolTip: "Enter your relationship to this home contact.",
      type: "string",
      validator: null,
    },
    {
      name: "home_contact_instructions",
      label: "Instructions",
      toolTip: "Enter instructions for this home contact.",
      type: "textarea",
      validator: null,
    },
  ],
};

const HomeInstructionCard = (props) => {
  const left = [HOME_INSTRUCTION];
  const right = [HOME_INSTRUCTION_CONTACT, ADDRESS];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default HomeInstructionCard;

import { createSlice } from '@reduxjs/toolkit'

/**
 * State Shape:

  myVotes: {
    items: [
      {
        id: "vote_1",
        eventId: "others_event_penny_death",
        timestamp: 1608426000,  // Dec 19, 2020.  6 PM MST
      },
      ...
    ]
  }
*/

// uses Immer internally to maintain state immutability
export const myVotesSlice = createSlice({
  name: "myVotes",
  initialState: {
    items: []
  },
  reducers: {
    /**
     * payload: {
     *   id,
     *   timestamp,
     *   eventId
     * }
     */
    voteForEvent: (state, action) => {
      const alreadyVoted = state.items.find((v) => v.eventId === action.payload.eventId);
      if (!alreadyVoted) {
        state.items = [...state.items, action.payload];
      }
    },
    /**
     * payload: event id
     */
    deleteVoteForEvent: (state, action) => {
      state.items = state.items.filter((i) => i.eventId !== action.payload);
    }
  }
});

export const { voteForEvent, deleteVoteForEvent } = myVotesSlice.actions;

export default myVotesSlice.reducer;

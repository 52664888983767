import React from "react";
import { Link } from "react-router-dom";

const SandboxHome = (props) => {
  return (
    <>
      <div>Welcome to SandboxHome</div>
      <div><Link to="/sandbox/public/signup">Sign Up</Link></div>
      <div><Link to="/sandbox/authenticated">Secret Stuff</Link></div>
    </>
  )
}

export default SandboxHome;

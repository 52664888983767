import React from "react";
import { cloneDeep } from "lodash";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { CONTACT } from "../../common/forms/sharedViews";

const FAMILY_INSTRUCTION_CONTACT = cloneDeep(CONTACT);
FAMILY_INSTRUCTION_CONTACT.name = "contact_family";
FAMILY_INSTRUCTION_CONTACT.label = "Contact Information";

const FAMILY_INSTRUCTION = {
  name: "family_contact",
  label: "Family Member Instructions",
  items: [
    {
      name: "family_name",
      label: "Name",
      toolTip: "Enter the name of this family contact.",
      type: "string",
      validator: null,
    },
    {
      name: "family_relation",
      label: "Relationship",
      toolTip: "Enter your relationship to this family contact.",
      type: "string",
      validator: null,
    },
    {
      name: "family_instructions",
      label: "Instructions",
      toolTip: "Enter instructions for this family contact.",
      type: "textarea",
      validator: null,
    },
  ],
};

const FamilyInstructionCard = (props) => {
  const left = [FAMILY_INSTRUCTION];
  const right = [FAMILY_INSTRUCTION_CONTACT];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default FamilyInstructionCard;

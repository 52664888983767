export default [
  {
    name: "Regular",
    size: 895,
    color: "card-gradient",
  },
  {
    name: "System",
    size: 379,
    color: "info",
  },
  {
    name: "Shared",
    size: 192,
    color: "success",
  },
  {
    name: "Free",
    size: 576,
    color: "200",
  },
];

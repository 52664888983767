import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Modal, ModalBody, Row } from "reactstrap";
import { starsOrNone, thisOrNone } from "../cards/cardDefs";
import FormModal from "./FormModal";
import RecordDetailRow from "./RecordDetailRow";

/**
  fields: {
    name: "address",
    label: "Shipping Address"
    items: [
      {
        name: "street1",
        label: "Street Address",
        type: "string",  // see https://reactstrap.github.io/components/form/ for valid input types
                         // radio buttons and multiselects aren't supported yet.
        validator: function,
        options: ["a", "b", "c"],  // optional, for dropdowns and multiselects
      },
      ...
    ]
  }
 */

const renderValue = (fields, value) => {
  switch (fields.type) {
    case "string": // fall through
    case "select": // fall through
    case "textarea": // fall through
    case "checkbox": // fall through
    case "tel": // fall through
    case "email":
      return thisOrNone(value, fields.placeholder);
    case "password":
      return starsOrNone(value);
    default:
      return value;
  }
};

const FormView = (props) => {
  const { id, value = {}, title, fields, readOnly, onEdit } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {!readOnly && (
        <>
          <Modal isOpen={showModal} size="md">
            <ModalBody>
              <FormModal
                id={id}
                value={value}
                title={title}
                fields={fields}
                onSubmit={() => setShowModal(false)}
                setOpen={setShowModal}
                onEdit={onEdit}
              />
            </ModalBody>
          </Modal>
          <Row>
            <h6 className="font-weight-semi-bold ls ml-3 mt-3 mb-3 text-uppercase">{fields.label}</h6>
            <FontAwesomeIcon
              icon="pencil-alt"
              size="sm"
              className="ml-3 mt-3 cursor-pointer"
              onClick={() => setShowModal(true)}
            />
          </Row>
        </>
      )}
      {readOnly && (
        <Row>
          <h6 className="font-weight-semi-bold ls ml-3 mt-3 mb-3 text-uppercase">{fields.label}</h6>
        </Row>
      )}
      {fields.items.map((s, i) => (
        <RecordDetailRow key={s.name} title={s.label} isLastItem={i === fields.items.length - 1}>
          <span className="text-dark">{renderValue(s, value[s.name])}</span>
        </RecordDetailRow>
      ))}
    </>
  );
};

export default FormView;

import { createSlice } from '@reduxjs/toolkit'

/**
 * State Shape:
 *  {
      items: [
        {
          id: "important1",
          record: "12345",
          value: "This is very <em>important!</em>"
        },
        ...
      ]
    }
  }
 */

// uses Immer internally to maintain state immutability
export const importantToKnowSlice = createSlice({
  name: 'itk',
  initialState: {
    items: []
  },
  reducers: {
    /**
     * payload: {
     *   id,
     *   newValue
     * }
     */
    updateImportantToKnow: (state, action) => {
      const index = state.items.findIndex((i) => i.id === action.payload.id);
      state.items[index].value = action.payload.newValue;
    },
    /**
     * payload: {
     *   id,
     *   record,
     *   value
     * }
     */
    createImportantToKnow: (state, action) => {
      state.items = [...state.items, action.payload];
    }
  }
});

export const { updateImportantToKnow, createImportantToKnow } = importantToKnowSlice.actions;

export default importantToKnowSlice.reducer;

import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";

const LICENSE = {
  name: "license",
  label: "License",
  items: [
    {
      name: "license_type",
      label: "Name of License",
      toolTip: "What type of License is this?",
      type: "string",
      validator: null,
    },
    {
      name: "license_source",
      label: "License Source",
      toolTip: "Who issued this license?",
      type: "string",
      validator: null,
    },
    {
      name: "license_number",
      label: "License #",
      toolTip: "What is the License number?",
      type: "string",
      validator: null,
    },
    {
      name: "license_expiration",
      label: "Expiration",
      toolTip: "When does this License expire?",
      type: "date",
      validator: null,
    },
    {
      name: "license_name",
      label: "License Name",
      toolTip: "What name is on this license?",
      type: "string",
      validator: null,
    },
  ],
};

const LicenseCard = (props) => {
  const left = [LICENSE];
  const right = [];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default LicenseCard;

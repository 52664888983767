import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Col, Input, Row } from "reactstrap";
import ModalInputRow from "./ModalInputRow";
import { PLEASE_SELECT } from "./sharedViews";
import { isEmpty } from "lodash";
import { removeNewCard } from "../../../../redux/newCardsSlice.js";
import { clearRecordSubvalue, updateRecordSubvalue } from "../../../../redux/recordsSlice";

const renderForm = (type, value = "", onChange, options) => {
  switch (type) {
    case "string": // has no type on the Input element
      return <Input value={value} onChange={({ target }) => onChange(target.value)} />;
    case "password": // fall through
    case "email": // fall through
    case "textarea": // fall through
    case "tel": // fall through
      // case "file":
      return <Input type={type} value={value} onChange={({ target }) => onChange(target.value)} />;
    case "checkbox":
      return (
        <Input
          type="checkbox"
          checked={value}
          className="ml-0 mt-0"
          onChange={({ target }) => onChange(target.checked)}
        />
      );
    case "select":
      // by default select the first option
      if (!value) {
        onChange(options[0]);
      }
      return (
        <Input type="select" value={value} onChange={({ target }) => onChange(target.value)}>
          {options.map((o) => {
            return <option key={o}>{o}</option>;
          })}
        </Input>
      );
    default:
      return <div>Unknown form type {type}</div>;
  }
};

const FormModal = (props) => {
  const { id, value = {}, title, fields, onSubmit, dispatch, setOpen, newCards } = props;
  const [state, setState] = useState(value);

  const handleSubmit = () => {
    // If a select input has the placeholder value then clear it before submitting.
    const toSubmit = { ...state };
    fields.items.forEach((fs) => {
      if (fs.type === "select" && toSubmit[fs.name] === PLEASE_SELECT) {
        delete toSubmit[fs.name];
      }
    });

    // If the new subvalue has no attributes then it's empty, so drop it.
    if (isEmpty(toSubmit)) {
      dispatch(clearRecordSubvalue({
        id,
        name: fields.name
      }));
    } else {
      dispatch(updateRecordSubvalue({
        id,
        name: fields.name,
        newSubvalue: toSubmit
      }));
      if (newCards.items.includes(id)) {
        dispatch(removeNewCard(id));
      }
    }

    onSubmit();
  };

  const handleUpdate = (which, newValue) => {
    const newState = { ...state, [which]: newValue };
    // explicitly unset the attribute if it's empty (null, undefined, "")
    // allow the "false" value though, which should only come from checkboxes.
    if (!newValue && newValue !== false) {
      delete newState[which];
    }
    setState(newState);
  };

  return (
    <>
      <span className="font-weight-bold">
        {fields.label}: {title}
      </span>
      <hr />
      {fields.items.map((s) => {
        return (
          <ModalInputRow key={s.name} label={s.label}>
            {renderForm(
              s.type,
              state[s.name],
              (newValue) => {
                handleUpdate(s.name, newValue);
              },
              s.options
            )}
          </ModalInputRow>
        );
      })}
      <hr />
      <Row className="d-flex justify-content-end mt-3">
        <Col>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
        </Col>
        <Col className="col-auto">
          <Button
            onClick={() => {
              setOpen(false);
              handleSubmit();
            }}
            color="primary"
            className="mr-2"
          >
            Done
          </Button>
        </Col>
      </Row>
    </>
  );
};

function mapFormStateToProps(state) {
  return {
    newCards: state.newCards,
  };
}

export default connect(mapFormStateToProps)(FormModal);

import React from "react";
import { cloneDeep } from "lodash";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { CONTACT } from "../../common/forms/sharedViews";

const HEALTH_INSTRUCTION_CONTACT = cloneDeep(CONTACT);
HEALTH_INSTRUCTION_CONTACT.name = "health_contact_name";
HEALTH_INSTRUCTION_CONTACT.label = "Contact Information";

const HEALTH_INSTRUCTION = {
  name: "health_instruction",
  label: "Health Contact Instructions",
  items: [
    {
      name: "health_name",
      label: "Name",
      toolTip: "Enter the name of this contact.",
      type: "string",
      validator: null,
    },
    {
      name: "health_relation",
      label: "Relationship",
      toolTip: "Enter your relationship to this contact.",
      type: "string",
      validator: null,
    },
    {
      name: "health_instructions",
      label: "Instructions",
      toolTip: "Enter instructions for this contact.",
      type: "textarea",
      validator: null,
    },
  ],
};

const HealthInstructionCard = (props) => {
  const left = [HEALTH_INSTRUCTION];
  const right = [HEALTH_INSTRUCTION_CONTACT];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default HealthInstructionCard;

import React from "react";
import { Col, Row } from "reactstrap";
import Shares from "../../sharing/Shares";
import ImportantToKnowView from "../forms/ImportantToKnowView";
import NotesView from "../forms/NotesView";
import CardAttachments from "./CardAttachments";

const CardFooter = (props) => {
  const { id, value = {}, readOnly, title, attachments = [] } = props;

  return (
    <>
      <ImportantToKnowView recordId={id} title={title} readOnly={readOnly} />
      <hr />
      <NotesView id={id} value={value.notes} name="notes" title={title} readOnly={readOnly} />
      <hr />
      <Row>
        <Col className="col-sm-6">
          <CardAttachments items={attachments} readOnly={readOnly} />
        </Col>
        <Col className="col-sm-6">{!readOnly && <Shares id={id} readOnly={readOnly} />}</Col>
      </Row>
    </>
  );
};

export default CardFooter;

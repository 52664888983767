import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { ADDRESS, CONTACT } from "../../common/forms/sharedViews";

const MessageCard = (props) => {
  const left = [CONTACT];
  const right = [ADDRESS];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default MessageCard;

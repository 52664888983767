import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./redux/oeConfigureStore.js";
import App from "./App.js";
import Main from "./Main.js";
import "./helpers/initFA.js";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports.js";
import { initMirage } from "./mirage.js";

// mirage mock api initialization
initMirage();

Amplify.configure(awsExports);

ReactDOM.render(
  <Provider store={store}>
    <Main>
      <App />
    </Main>
  </Provider>,
  document.getElementById("main")
);

import React, { useState } from "react";
import SignIn from "./SignIn";

const WithOeAuth = (props) => {
  const { children } = props;
  // TODO(ian): switch to redux
  const [loggedIn, setLoggedIn] = useState(false);

  return (
    <>
      {loggedIn && children}
      {!loggedIn && <SignIn setLoggedIn={setLoggedIn}/>}
    </>
  )
}

export default WithOeAuth;

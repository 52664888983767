import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { CONTACT, PLEASE_SELECT } from "../../common/forms/sharedViews";
import { cloneDeep } from "lodash";

const WILL_ADVISOR_CONTACT = cloneDeep(CONTACT);
WILL_ADVISOR_CONTACT.name = "will_advisor";
WILL_ADVISOR_CONTACT.label = "Will Advisor Contact";

const WILL = {
  name: "will",
  label: "will Information",
  items: [
    {
      name: "will_name",
      label: "Will Title",
      toolTip: "Enter the legal name of this will.",
      type: "string",
      validator: null,
    },
    {
      name: "will_type",
      label: "Will Type",
      toolTip: "Select the type of will.",
      type: "select",
      validator: null,
      options: [PLEASE_SELECT, "Traditional", "Spilover", "Other"],
    },
    {
      name: "will_signer",
      label: "Signed By",
      toolTip: "Enter name of person signing this will",
      type: "string",
      validator: null,
    },
    {
      name: "will_date",
      label: "Will Date",
      toolTip: "Enter date this will was signed.",
      type: "string",
      validator: null,
    },
    {
      name: "will_executor",
      label: "Executor",
      toolTip: "Enter the name of the executor for this will.",
      type: "string",
      validator: null,
    },
    {
      name: "will_alt_executor",
      label: "Alternate Executor",
      toolTip: "Enter the name of the alternate executor of this will.",
      type: "string",
      validator: null,
    },
  ],
};

const WillCard = (props) => {
  const left = [WILL];
  const right = [WILL_ADVISOR_CONTACT];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default WillCard;

/**
 * A record is blank if it has no values other than the title.
 * @param {} recordValue
 */
export function isRecordBlank(recordValue) {
  const keys = Object.keys(recordValue);
  return keys.length === 1 && keys[0] === "title";
}

/**
 * Builds a nav section for a user sharing things with me
 */
export function sharingNavSection(userName, userId) {
  return {
    name: userName,
    children: [
      { to: `/user/${userId}/important`, name: "Important" },
      { to: `/user/${userId}/cards`, name: "Cards" },
    ],
  };
}

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./layouts/Layout";

import "react-toastify/dist/ReactToastify.min.css";
import "react-datetime/css/react-datetime.css";
import "react-image-lightbox/style.css";

import { withAuthenticator } from "@aws-amplify/ui-react";

const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Layout />
    </Router>
  );
};

export default withAuthenticator(App, {
  includeGreetings: true,
  signUpConfig: {
    hiddenDefaults: ["phone_number"],
  },
});

// export default App;

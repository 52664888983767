import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Modal, ModalBody, Row } from "reactstrap";
import { thisOrNone } from "../cards/cardDefs";
import CustomFieldsModal from "./CustomFieldsModal";
import RecordDetailRow from "./RecordDetailRow";

const renderValue = (customField) => {
  switch (customField.type) {
    case "string":
      return thisOrNone(customField.value, null);
    default:
      return customField.value;
  }
};

const CustomFieldsView = (props) => {
  const { id, value = {}, title, readOnly } = props;
  const [showModal, setShowModal] = useState(false);

  const customFields = value.customFields ? value.customFields : [];

  return (
    <>
      {!readOnly && (
        <>
          <Modal isOpen={showModal} size="md">
            <ModalBody>
              <CustomFieldsModal
                id={id}
                value={value}
                title={title}
                onSubmit={() => setShowModal(false)}
                setOpen={setShowModal}
              />
            </ModalBody>
          </Modal>
          <Row>
            <h6 className="font-weight-semi-bold ls ml-3 mt-3 mb-3 text-uppercase">Custom Fields</h6>
            <FontAwesomeIcon
              icon="pencil-alt"
              size="sm"
              className="ml-3 mt-3 cursor-pointer"
              onClick={() => setShowModal(true)}
            />
          </Row>
        </>
      )}
      {readOnly && (
        <Row>
          <h6 className="font-weight-semi-bold ls ml-3 mt-3 mb-3 text-uppercase">Custom Fields</h6>
        </Row>
      )}
      {customFields.map((cf, i) => {
        return (
          <RecordDetailRow key={cf.id} title={cf.label} isLastItem={i === customFields.length - 1}>
            <span className="text-dark">{renderValue(cf)}</span>
          </RecordDetailRow>
        );
      })}
    </>
  );
};

export default CustomFieldsView;

import React from "react";
import { cloneDeep } from "lodash";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { CONTACT } from "../../common/forms/sharedViews";

const FINANCE_INSTRUCTION_CONTACT = cloneDeep(CONTACT);
FINANCE_INSTRUCTION_CONTACT.name = "finance_contact_name";
FINANCE_INSTRUCTION_CONTACT.label = "Contact Information";

const FINANCE_INSTRUCTION = {
  name: "finance_contact",
  label: "Finance Instructions",
  items: [
    {
      name: "finance_name",
      label: "Name",
      toolTip: "Enter the name of this finance contact.",
      type: "string",
      validator: null,
    },
    {
      name: "finance_relation",
      label: "Relationship",
      toolTip: "Enter your relationship to this finance contact.",
      type: "string",
      validator: null,
    },
    {
      name: "finance_instructions",
      label: "Instructions",
      toolTip: "Enter instructions for this finance contact.",
      type: "textarea",
      validator: null,
    },
  ],
};

const FinanceInstructionCard = (props) => {
  const left = [FINANCE_INSTRUCTION];
  const right = [FINANCE_INSTRUCTION_CONTACT];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default FinanceInstructionCard;

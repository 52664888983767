import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { ADDRESS, PLEASE_SELECT, PROVIDER } from "../../common/forms/sharedViews";
import { cloneDeep } from "lodash";

const VEHICLE_LOCATION_ADDRESS = cloneDeep(ADDRESS);
VEHICLE_LOCATION_ADDRESS.name = "address_service";
VEHICLE_LOCATION_ADDRESS.label = "Vehicle Location Address";

const VEHICLE_MAINTENANCE_CONTACT = cloneDeep(PROVIDER);
VEHICLE_MAINTENANCE_CONTACT.name = "contact_maintenance";
VEHICLE_MAINTENANCE_CONTACT.label = "Vehicle Maintenance Contact";

const VEHICLE_INSURANCE_CONTACT = cloneDeep(PROVIDER);
VEHICLE_INSURANCE_CONTACT.name = "contact_insurance";
VEHICLE_INSURANCE_CONTACT.label = "Vehicle Insurance Contact";

const VEHICLE = {
  name: "vehicle",
  label: "Vehicle Information",
  items: [
    {
      name: "vehicle_type",
      label: "Vehicle Type",
      toolTip: "Select the type of service this vehicle provides.",
      type: "string",
      validator: null,
    },
    {
      name: "ID_number",
      label: "ID Number",
      toolTip: "Enter the ID number for this vehicle (VIN or Serial #).",
      type: "string",
      validator: null,
    },
    {
      name: "vehicle_year",
      label: "Vehicle Year",
      toolTip: "Enter the year this vehicle was made.",
      type: "string",
      validator: null,
    },
    {
      name: "vehicle_color",
      label: "Vehicle Color",
      toolTip: "Enter the color of this vehicle.",
      type: "string",
      validator: null,
    },
    {
      name: "vehicle_registration",
      label: "Vehicle Registration",
      toolTip: "Enter the registration # for this vehicle.",
      type: "string",
      validator: null,
    },
    {
      name: "vehicle_registration_location",
      label: "Registration Location",
      toolTip: "Enter the where you keep the registration for this vehicle.",
      type: "string",
      validator: null,
    },
    {
      name: "vehicle_title",
      label: "Ownership Proof",
      toolTip: "Enter information about the proof of ownership for this vehicle.",
      type: "string",
      validator: null,
    },
    {
      name: "vehicle_title_location",
      label: "Proof Location",
      toolTip: "Enter where you keep proof of ownership for this vehicle.",
      type: "string",
      validator: null,
    },
    {
      name: "vehicle_location",
      label: "Vehicle Location",
      toolTip: "Enter where you keep this vehicle.",
      type: "textarea",
      validator: null,
    },
    {
      name: "vehicle_repair",
      label: "Repair Service",
      toolTip: "Enter where this vehicle is serviced.",
      type: "string",
      validator: null,
    },
    {
      name: "vehicle_payment_how",
      label: "Vehicle Payment",
      toolTip: "Select how you pay for this vehicle.",
      type: "select",
      validator: null,
      options: [PLEASE_SELECT, "Paid Off", "AutoPay", "Mail", "Online", "Phone", "Other"],
    },
    {
      name: "vehicle_payment",
      label: "How Pay?",
      toolTip: "Enter when and how you pay for this vehicle (if applicable).",
      type: "string",
      validator: null,
    },
    {
      name: "due_date",
      label: "Payment Due On",
      toolTip: "Enter the bill due day for this vehicle.",
      type: "string",
      validator: null,
    },
  ],
};

const VehicleCard = (props) => {
  const left = [VEHICLE];
  const right = [VEHICLE_MAINTENANCE_CONTACT, VEHICLE_INSURANCE_CONTACT];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default VehicleCard;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Col, Collapse, Row } from "reactstrap";
import ButtonIcon from "../../../common/ButtonIcon";
import { deleteAttachment } from "../../../../redux/attachmentsSlice";
import EmailAttachment from "../../../email/inbox/EmailAttachment";

const CardAttachments = (props) => {
  const { items = [], readOnly, dispatch } = props;
  const [collapsed, setCollapsed] = useState(true);

  const doDelete = (attachmentId) => {
    dispatch(deleteAttachment(attachmentId));
  };

  return (
    <div className="mb-3">
      <Row>
        <Col>
          <ButtonIcon
            color="falcon-default"
            size="sm"
            icon={collapsed ? "chevron-down" : "chevron-up"}
            className="ml-1"
            iconClassName="fs--2"
            onClick={() => setCollapsed(!collapsed)}
          >
            Attachments ({items.length})
          </ButtonIcon>
          {!readOnly && (
            <ButtonIcon
              color="falcon-default"
              size="sm"
              icon="paperclip"
              className="ml-1"
              iconClassName="fs--2"
              onClick={() => alert("I don't work yet!")}
            >
              Add Attachment
            </ButtonIcon>
          )}
        </Col>
      </Row>
      <Collapse isOpen={!collapsed}>
        <Row>
          <Col>
            {items.map((item) => (
              <span key={item.id} className="d-inline-flex mx-2 align-items-center pl-1">
                <EmailAttachment {...item} key={item.id} />
                {!readOnly && (
                  <FontAwesomeIcon
                    color="falcon-danger"
                    size="sm"
                    icon="times"
                    className="fs--2 falcon-danger mt-2"
                    onClick={() => doDelete(item.id)}
                  />
                )}
              </span>
            ))}
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};

export default connect()(CardAttachments);

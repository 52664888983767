import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import attachmentsReducer from "./attachmentsSlice.js";
import defaultState from "./defaultState";
import eventsReducer from "./eventsSlice.js";
import importantToKnowReducer from "./importantToKnowSlice.js";
import logger from "./middleware/logger";
import myVotesReducer from "./myVotesSlice.js";
import newCardsReducer from "./newCardsSlice.js";
import notificationsReducer from "./notificationsSlice.js";
import othersEventsReducer from "./othersEventsSlice.js";
import othersRecordsReducer from "./othersRecordsSlice.js";
import personsReducer from "./personsSlice.js";
import recordsReducer from "./recordsSlice.js";
import { settingsReducer } from "./settingsSlice.js";
import sharesReducer from "./sharesSlice.js";
import { videosReducer } from "./videosSlice.js";
import { oeApi } from "../services/oeApi.js";

export const store = configureStore({
  reducer: {
    attachments: attachmentsReducer,
    events: eventsReducer,
    importantToKnow: importantToKnowReducer,
    myVotes: myVotesReducer,
    newCards: newCardsReducer,
    notifications: notificationsReducer,
    othersEvents: othersEventsReducer,
    othersRecords: othersRecordsReducer,
    persons: personsReducer,
    [oeApi.reducerPath]: oeApi.reducer,
    records: recordsReducer,
    settings: settingsReducer,
    shares: sharesReducer,
    videos: videosReducer,
  },
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware();
    middleware.push(oeApi.middleware);
    if (process.env.NODE_ENV === "development") {
      console.log("including dev middleware");
      middleware.push(logger);
    }
    return middleware;
  },
  preloadedState: defaultState
});

// for RTK Query refetchOnFocus/refetchOnReconnect
setupListeners(store.dispatch);

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import ListGroup from "reactstrap/es/ListGroup.js";
import ListGroupItem from "reactstrap/es/ListGroupItem.js";
import { isIterableArray } from "../../helpers/utils.js";
import { markAllNotificationsRead } from "../../redux/notificationsSlice.js";
import FalconCardHeader from "../common/FalconCardHeader.js";
import Notification from "../notification/Notification.js";

const NotificationDropdown = (props) => {
  const { notifications = [], persons = [], dispatch } = props;
  // State
  const [isOpen, setIsOpen] = useState(false);
  const hasUnread = notifications.some((n) => n.unread);

  // resolve avatar information for notifications
  const notificationsAndAvatars = notifications.map((n) => {
    const person = persons.find((p) => p.id === n.personId);
    return {
      ...n,
      avatar: {
        name: `${person.name.first} ${person.name.last}`,
        size: "2xl",
      },
    };
  });

  // Handler
  const handleToggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const markAsRead = (e) => {
    e.preventDefault();
    dispatch(markAllNotificationsRead());
  };

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={isOpen}
      toggle={handleToggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setIsOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setIsOpen(false);
      }}
    >
      <DropdownToggle
        nav
        className={classNames("px-0", {
          "notification-indicator notification-indicator-primary": hasUnread,
        })}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <Card className="card-notification shadow-none" style={{ maxWidth: "20rem" }}>
          <FalconCardHeader className="card-header" title="Notifications" titleTag="h6" light={false}>
            <Link className="card-link font-weight-normal" to="#!" onClick={markAsRead}>
              Mark all as read
            </Link>
          </FalconCardHeader>
          <ListGroup flush className="font-weight-normal fs--1">
            <div className="list-group-title">UNREAD</div>
            {isIterableArray(notificationsAndAvatars) &&
              notificationsAndAvatars.map((notification, index) => (
                <ListGroupItem key={index} onClick={handleToggle}>
                  <Notification {...notification} flush />
                </ListGroupItem>
              ))}
          </ListGroup>
          <div className="card-footer text-center border-top-0" onClick={handleToggle}>
            <Link className="card-link d-block" to="/pages/notifications">
              View all
            </Link>
          </div>
        </Card>
      </DropdownMenu>
    </Dropdown>
  );
};

function mapStateToProps(state) {
  return {
    persons: state && state.persons && state.persons.items ? state.persons.items : [],
    notifications: state && state.notifications && state.notifications.items ? state.notifications.items : [],
  };
}

export default connect(mapStateToProps)(NotificationDropdown);

import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { CONTACT } from "../../common/forms/sharedViews";

const LIFE_INSURANCE = {
  name: "life_insurance",
  label: "Life Insurance",
  items: [
    {
      name: "insurer",
      label: "Insurer",
      type: "string",
      validator: null,
    },
    {
      name: "policy_type",
      label: "Policy Type",
      type: "select",
      validator: null,
      options: ["Term", "Whole", "Scam"],
    },
    {
      name: "broker",
      label: "Broker",
      type: "string",
      validator: null,
    },
    {
      name: "policy_number",
      label: "Policy Number",
      type: "string",
      validator: null,
    },
  ],
};

const LifeInsuranceCard = (props) => {
  const left = [LIFE_INSURANCE];
  const right = [CONTACT];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default LifeInsuranceCard;

import React from "react";
import { cloneDeep } from "lodash";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { ADDRESS, PAYMENT_TYPES, PLEASE_SELECT, PROVIDER } from "../../common/forms/sharedViews";

const BILL_SERVICE_ADDRESS = cloneDeep(ADDRESS);
BILL_SERVICE_ADDRESS.name = "address_service";
BILL_SERVICE_ADDRESS.label = "Service Address";

const BILL_PAYEE_ADDRESS = cloneDeep(ADDRESS);
BILL_PAYEE_ADDRESS.name = "address_payee";
BILL_PAYEE_ADDRESS.label = "Payee Address";

const BILL = {
  name: "bill",
  label: "Bill",
  items: [
    {
      name: "payee",
      label: "Payee",
      toolTip: "Enter the name of the payee for this bill.",
      type: "string",
      validator: null,
    },
    {
      name: "acct_number",
      label: "Account Number",
      toolTip: "Enter the account number for this bill.",
      type: "string",
      validator: null,
    },
    {
      name: "due_date",
      label: "When Due?",
      toolTip: "Enter the due day for this bill.",
      type: "string",
      validator: null,
    },
    {
      name: "payment_how",
      label: "How Paid",
      toolTip: "Select how you pay this bill.",
      type: "select",
      validator: null,
      options: PAYMENT_TYPES,
    },
    {
      name: "payment_frequency",
      label: "Pay How Often?",
      toolTip: "Select how often you pay this bill.",
      type: "select",
      validator: null,
      options: [PLEASE_SELECT, "Monthly", "Quarterly", "Annually", "Other"],
    },
  ],
};

const BillCard = (props) => {
  const left = [BILL, BILL_SERVICE_ADDRESS];
  const right = [BILL_PAYEE_ADDRESS, PROVIDER];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default BillCard;

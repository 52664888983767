import { cloneDeep } from "lodash";
import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { ADDRESS, PAYMENT_TYPES, PROVIDER } from "../../common/forms/sharedViews";

const ITEM_LOCATION_ADDRESS = cloneDeep(ADDRESS);
ITEM_LOCATION_ADDRESS.name = "address_service";
ITEM_LOCATION_ADDRESS.label = "Location Address";

const ITEM_INSURANCE_CONTACT = cloneDeep(PROVIDER);
ITEM_INSURANCE_CONTACT.name = "contact_insurance";
ITEM_INSURANCE_CONTACT.label = "Insurance Contact";

const ITEM = {
  name: "item",
  label: "Item Information",
  items: [
    {
      name: "item_name",
      label: "Item Name",
      toolTip: "What do you call this item?",
      type: "string",
      validator: null,
    },
    {
      name: "item_description",
      label: "Description",
      toolTip: "Enter a description of this item.",
      type: "string",
      validator: null,
    },
    {
      name: "item_isValuable",
      label: "Valuable",
      toolTip: "Check if you consider this item valuable.",
      type: "checkbox",
      validator: null,
    },
    {
      name: "item_isSentimental",
      label: "Sentimental",
      toolTip: "Check if you consider this item sentimental.",
      type: "checkbox",
      validator: null,
    },
    {
      name: "item_isHeirloom",
      label: "Heirloom",
      toolTip: "Check if you consider this item to be an heirloom.",
      type: "checkbox",
      validator: null,
    },
    {
      name: "item_owned_by",
      label: "Owned By",
      toolTip: "Enter ownership information for this item.",
      type: "string",
      validator: null,
    },
    {
      name: "item_value",
      label: "Estimated Value",
      toolTip: "Enter the estimated value of this item.",
      type: "string",
      validator: null,
    },
    {
      name: "item_title",
      label: "Ownership Proof",
      toolTip: "Describe the proof of ownership for this item.",
      type: "string",
      validator: null,
    },
    {
      name: "item_title_location",
      label: "Proof Location",
      toolTip: "Enter where you keep proof of ownership for this item.",
      type: "string",
      validator: null,
    },
    {
      name: "item_location",
      label: "Item Location",
      toolTip: "Enter a description of where you keep this item.",
      type: "string",
      validator: null,
    },
    {
      name: "item_wish",
      label: "Wish for Item",
      toolTip: "Enter what you would like to see happen with this item.",
      type: "textarea",
      validator: null,
    },
    {
      name: "item_payment_amount",
      label: "Payment Amount",
      toolTip: "Enter how much you pay for this item (if applicable).",
      type: "string",
      validator: null,
    },
    {
      name: "item_payment_how",
      label: "Payment Method",
      toolTip: "Select how you pay for this item.",
      type: "select",
      validator: null,
      options: PAYMENT_TYPES,
    },
    {
      name: "due_date",
      label: "Payment Due On",
      toolTip: "Enter the payment due day for this item.",
      type: "string",
      validator: null,
    },
  ],
};

const ItemCard = (props) => {
  const left = [ITEM];
  const right = [ITEM_LOCATION_ADDRESS, ITEM_INSURANCE_CONTACT];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default ItemCard;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
import { Button, Col, Label, Modal, ModalBody, Row } from "reactstrap";
import { removeNewCard } from "../../../../redux/newCardsSlice.js";
import { updateRecordSubvalue } from "../../../../redux/recordsSlice.js";

const NotesForm = (props) => {
  const { hasLabel, value = {}, title, onSubmit, id, name, newCards, dispatch } = props;
  // State
  const [notes, setNotes] = useState(value.value ? value.value : "");

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    const newSubvalue = {
      value: notes,
    };
    dispatch(updateRecordSubvalue({
      id,
      name,
      newSubvalue
    }));
    if (newCards.items.includes(id)) {
      dispatch(removeNewCard(id));
    }
    onSubmit();
  };

  return (
    <>
      {hasLabel && <Label>Notes: {title}</Label>}
      <div>
        <textarea value={notes} style={{ height: 400, width: "100%" }}
            placeholder="Enter notes here.  Supports Markdown formatting."
            onChange={(e) => setNotes(e.target.value)} />
      </div>
      <Button color="primary" block className="mt-7" onClick={handleSubmit}>
        Done
      </Button>
    </>
  );
};

NotesForm.propTypes = {
  hasLabel: PropTypes.bool,
  value: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

NotesForm.defaultProps = {
  hasLabel: true,
  value: {},
};

function mapFormStateToProps(state) {
  return {
    newCards: state.newCards,
  };
}

const ConnectedForm = connect(mapFormStateToProps)(NotesForm);

/********************************************* VIEW ********************************/

const NotesView = (props) => {
  const { id, value = {}, readOnly, name, title } = props;
  const { value: content = "" } = value;

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Modal isOpen={showModal}>
        <ModalBody>
          <ConnectedForm id={id} value={value} name={name} title={title} onSubmit={() => setShowModal(false)} />
        </ModalBody>
      </Modal>
      <Row className="mt-1">
        <Col lg className="col-xxl-5">
          <Row>
            <h6 className="font-weight-semi-bold ls ml-3 mb-0 text-uppercase">Notes</h6>
            {!readOnly && (
              <FontAwesomeIcon
                icon="pencil-alt"
                size="sm"
                className="ml-3 mb-0 cursor-pointer"
                onClick={() => setShowModal(true)}
              />
            )}
          </Row>
        </Col>
      </Row>
      <div className="mt-1" />
      {content ? (
        <ReactMarkdown source={content ? content : ""} />
      ) : (
        <ReactMarkdown source={"*None*"} />
      )}
    </>
  );
};

export default NotesView;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody } from "reactstrap";
import { removeNewCard } from "../../../../redux/newCardsSlice.js";
import { deleteRecord, updateRecordSubvalue } from "../../../../redux/recordsSlice";
import ButtonIcon from "../../../common/ButtonIcon";
import FalconCardHeader from "../../../common/FalconCardHeader";
import { thisOrNone } from "./cardDefs";
import classNames from "classnames";

const RecordTitleForm = (props) => {
  const { hasLabel, title = "", onSubmit, id, name, newCards, dispatch } = props;
  // State
  const [updated, setUpdated] = useState(title);

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateRecordSubvalue({
      id,
      name,
      newSubvalue: updated
    }));
    if (newCards.items.includes(id)) {
      dispatch(removeNewCard(id));
    }
    onSubmit();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        {hasLabel && <Label>New Title</Label>}
        <Input
          placeholder={!hasLabel ? "title" : ""}
          value={updated}
          onChange={({ target }) => setUpdated(target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Button color="primary" block className="mt-3">
          Done
        </Button>
      </FormGroup>
    </Form>
  );
};

RecordTitleForm.propTypes = {
  hasLabel: PropTypes.bool,
  value: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

RecordTitleForm.defaultProps = {
  hasLabel: true,
};

function mapFormStateToProps(state) {
  return {
    newCards: state.newCards,
  };
}

const ConnectedForm = connect(mapFormStateToProps)(RecordTitleForm);

/********************************************* VIEW ********************************/
const CardTitle = (props) => {
  const { id, name, title, isOpen, onShowMore, isBlank, readOnly, card, dispatch, getShares } = props;

  const [showModal, setShowModal] = useState(false);

  const shares = getShares(id);

  const titleEditButton = () => {
    if (readOnly) {
      return null;
    } else {
      return (
        <FontAwesomeIcon
          icon="pencil-alt"
          size="sm"
          className="ml-3 mt-1 cursor-pointer"
          onClick={() => setShowModal(true)}
        />
      );
    }
  };

  const doDelete = () => {
    if (shares.length === 0) {
      dispatch(deleteRecord(id));
    } else {
      alert("You can't delete a card that has been shared.  Delete the shares first.");
    }
  };

  const headerClass = classNames({ "blank-card": isBlank });
  const cardDisplay = card && card.display ? card.display + ": " : "";

  return (
    <>
      <Modal isOpen={showModal}>
        <ModalBody>
          <ConnectedForm id={id} name={name} title={title} onSubmit={() => setShowModal(false)} />
        </ModalBody>
      </Modal>
      <FalconCardHeader
        title={thisOrNone(`${cardDisplay}${title}`, "No Title")}
        titleClass="ml-3"
        className={headerClass}
        light={!isBlank}
        decorations={titleEditButton()}
      >
        {!readOnly && (
          <ButtonIcon color="falcon-danger" size="sm" icon="times" iconClassName="fs--2" onClick={() => doDelete()}>
            Delete
          </ButtonIcon>
        )}
        <ButtonIcon
          color="falcon-default"
          size="sm"
          icon={isOpen ? "chevron-up" : "chevron-down"}
          className="ml-1"
          iconClassName="fs--2"
          onClick={() => onShowMore()}
        >
          {isOpen ? "see less" : "see more"}
        </ButtonIcon>
      </FalconCardHeader>
    </>
  );
};

const mapStateToProps = (state) => {
  const getShares = (id) => {
    return state.shares && state.shares.items ? state.shares.items.filter((i) => i.record === id) : [];
  };
  return {
    getShares,
  }
}

export default connect(mapStateToProps)(CardTitle);

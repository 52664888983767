import { createSlice } from "@reduxjs/toolkit";

/**
 * State Shape:

  othersEvents: {
    items: [
      {
        id: "others_event_melissa_incap",
        personId: "person001",
        title: "My Incapacity",
        type: "vote",
        status: "inactive", // no vote is taking place and the event hasn't been triggered yet
        description: "If I'm unable to competently make my own decisions.",
        votesRemaining: 2,
      },
      ...
    ]
  }
*/

// uses Immer internally to maintain state immutability
export const othersEventsSlice = createSlice({
  name: "othersEvents",
  initialState: {
    items: []
  },
  reducers: {
    /**
     * payload: event id
     */
    decrementRemainingVotes: (state, action) => {
      const index = state.items.find((i) => i.id === action.payload);
      if (index >= 0) {
        if (state.items[index].votesRemaining === 1) {
          state.items[index].active = true;
        }
        state.items[index].votesRemaining -= 1;
      }
    },
  }
});

export const { decrementRemainingVotes } = othersEventsSlice.actions;

export default othersEventsSlice.reducer;


import { cloneDeep } from "lodash";
import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { ADDRESS, CONTACT } from "../../common/forms/sharedViews";

const PETCARE_SERVICE_ADDRESS = cloneDeep(ADDRESS);
PETCARE_SERVICE_ADDRESS.name = "address_pet";
PETCARE_SERVICE_ADDRESS.label = "Pet Location";

const PETCARE_PROVIDER_CONTACT = cloneDeep(CONTACT);
PETCARE_PROVIDER_CONTACT.name = "contact_veterinarian";
PETCARE_PROVIDER_CONTACT.label = "Veterinarian";

const PETCARE_SITTER_CONTACT = cloneDeep(CONTACT);
PETCARE_SITTER_CONTACT.name = "contact_petsitter";
PETCARE_SITTER_CONTACT.label = "Pet Sitter";

const PETCARE = {
  name: "petcare",
  label: "Pet Care Information",
  items: [
    {
      name: "pet_name",
      label: "Pet Name",
      toolTip: "Enter the name of this pet.",
      type: "string",
      validator: null,
    },
    {
      name: "pet_type",
      label: "Description",
      toolTip: "Enter a description of this pet.",
      type: "textarea",
      validator: null,
    },
    {
      name: "pet_instructions",
      label: "Instructions",
      toolTip: "Enter instructions for caring for this pet.",
      type: "textarea",
      validator: null,
    },
  ],
};

const PetCareCard = (props) => {
  const left = [PETCARE, PETCARE_SERVICE_ADDRESS];
  const right = [PETCARE_PROVIDER_CONTACT, PETCARE_SITTER_CONTACT];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default PetCareCard;

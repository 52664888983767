import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidV4 } from "uuid";

/*************************** UTIL ****************************/
export function buildNotification(personId, age, message) {
  return {
    id: uuidV4(),
    personId: personId,
    children: message,
    time: age,
    className: "rounded-0 border-x-0 border-300 border-bottom-0",
    to: "#!",
    unread: true,
  };
}

// uses Immer internally to maintain state immutability
export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    items: []
  },
  reducers: {
    /**
     * payload: none
     */
    markAllNotificationsRead: (state) => {
      state.items = state.items.map((i) => ({...i, unread: false}));
    },
    /**
     * payload: new notification (see buildNotification)
     */
    addNotification: (state, action) => {
      state.items = [...state.items, action.payload];
    }
  }
});

export const { markAllNotificationsRead, addNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;

import React from "react";
import { Col, CustomInput, Form, Label, Row } from "reactstrap";

const FactsForm = (props) => {
  return (
    <Form className="ml-2">
      <Row>
        <h3>Please select all facts that apply to you.</h3>
      </Row>
      <Row>Your answers here inspire recommendations for information cards that may be useful to you.</Row>
      <Row>
        <Col className="xs-6">
          <hr className="mb-0" />
          <Label className="mb-0 mt-0" for="exampleCheckbox">
            <em>My Primary Residence</em>
          </Label>
          <div className="d-flex flex-column">
            <CustomInput
              type="radio"
              id="facts_residence1"
              name="facts_residenceRadio"
              label="I own my primary residence"
              defaultChecked
              inline
            />
            <CustomInput type="radio" id="facts_residence2" name="facts_residenceRadio" label="Rent or lease" inline />
            <CustomInput
              type="radio"
              id="facts_residence3"
              name="facts_residenceRadio"
              label="I live with family or friends"
              inline
            />
            <CustomInput
              type="radio"
              id="facts_residence4"
              name="facts_residenceRadio"
              label="I live at a facility"
              inline
            />
            <CustomInput type="radio" id="facts_residence5" name="facts_residenceRadio" label="Other" inline />
          </div>
        </Col>
        <Col className="xs-6">
          <hr className="mb-0" />
          <Label className="mb-0 mt-1" for="exampleCheckbox">
            <em>My Relationship</em>
          </Label>
          <div className="d-flex flex-column">
            <CustomInput type="radio" id="facts_relationship1" name="facts_relationshipRadio" label="Single" inline />
            <CustomInput
              type="radio"
              id="facts_relationship2"
              name="facts_relationshipRadio"
              label="Married"
              defaultChecked
              inline
            />
            <CustomInput
              type="radio"
              id="facts_relationship3"
              name="facts_relationshipRadio"
              label="With Partner"
              inline
            />
            <CustomInput
              type="radio"
              id="facts_relationship4"
              name="facts_relationshipRadio"
              label="Separated"
              inline
            />
            <CustomInput type="radio" id="facts_relationship5" name="facts_relationshipRadio" label="Divorced" inline />
            <CustomInput type="radio" id="facts_relationship6" name="facts_relationshipRadio" label="Other" inline />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="xs-6">
          <hr className="mb-0" />
          <Label className="mb-0 mt-1" for="factsDependents">
            <em>I have the following Dependents</em>
          </Label>
          <div className="d-flex flex-column">
            <CustomInput type="checkbox" id="facts_minorDependent" label="Dependents UNDER 18" inline />
            <CustomInput type="checkbox" id="facts_adultDependents" label="Dependents OVER 18" defaultChecked inline />
            <CustomInput type="checkbox" id="facts_pets" label="Pets" inline />
          </div>
        </Col>
        <Col className="xs-6">
          <hr className="mb-0" />
          <Label className="mb-0 mt-0" for="facts_job">
            <em>Employment</em>
          </Label>
          <div className="d-flex flex-column">
            <CustomInput type="checkbox" id="facts_isEmployed" label="I am Currently Employed" inline />
            <CustomInput type="checkbox" id="facts_isBizOwner" label="I own a business" defaultChecked inline />
            <CustomInput type="checkbox" id="facts_isRetired" label="I am Retired" inline />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="xs-6">
          <hr className="mb-0" />
          <Label className="mb-0 mt-0" for="facts_insurance">
            <em>I have the following Insurance</em>
          </Label>
          <div className="d-flex flex-column">
            <CustomInput type="checkbox" id="facts_healthInsurance" label="Health Insurance" defaultChecked inline />
            <CustomInput type="checkbox" id="facts_lifeInsurance" label="Life Insurance" defaultChecked inline />
            <CustomInput type="checkbox" id="facts_disabilityInsurance" label="Disability Insurance" inline />
            <CustomInput type="checkbox" id="facts_longTermInsurance" label="Long Term Care Insurance" inline />
            <CustomInput type="checkbox" id="facts_propertyInsurance" label="Property Insurance" defaultChecked inline />
          </div>
        </Col>
        <Col className="xs-6">
          <hr className="mb-0" />
          <Label className="mb-0 mt-0" for="facts_legal">
            <em>I have signed the following Legal Documents</em>
          </Label>
          <div className="d-flex flex-column">
            <CustomInput type="checkbox" id="facts_will" label="A Will" defaultChecked inline />
            <CustomInput type="checkbox" id="facts_trust" label="A Trust" defaultChecked inline />
            <CustomInput type="checkbox" id="facts_financePOA" label="Durable Power of Attorney" defaultChecked inline />
            <CustomInput type="checkbox" id="facts_healthPOA" label="Health Power of Attorney" inline />
            <CustomInput type="checkbox" id="facts_AdvanceDirective" label="Living Will or Advance Directive" defaultChecked inline />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="xs-6">
          <hr className="mb-0" />
          <Label className="mb-0 mt-0" for="facts_professionals">
            <em>I use the following Professionals</em>
          </Label>
          <div className="d-flex flex-column">
            <CustomInput type="checkbox" id="facts_primaryPhysician" label="Primary Care Physician" defaultChecked inline />
            <CustomInput type="checkbox" id="facts_attorney" label="Attorney" defaultChecked inline />
            <CustomInput type="checkbox" id="facts_accountant" label="Accountant" inline />
            <CustomInput type="checkbox" id="facts_financialPlanner" label="Financial Planner" inline />
            <CustomInput type="checkbox" id="facts_taxAdvisor" label="Tax Advisor" defaultChecked inline />
          </div>
        </Col>
        <Col className="xs-6">
          <hr className="mb-0" />
          <Label className="mb-0 mt-0" for="facts_digital">
            <em>I use the following to access my Digital Devices</em>
          </Label>
          <div className="d-flex flex-column">
            <CustomInput type="checkbox" id="facts_isComputerPassword" label="Password for Computer" defaultChecked inline />
            <CustomInput type="checkbox" id="facts_isPhoneUnlock" label="Code to Unlock Phone" defaultChecked inline />
            <CustomInput type="checkbox" id="facts_hasPasswordManager" label="A Password Manager" defaultChecked inline />
            <CustomInput type="checkbox" id="facts_hasAuthDevice" label="Authentication Device" inline />
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default FactsForm;

import { cloneDeep } from "lodash";
import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { ADDRESS } from "../../common/forms/sharedViews";

const HOME_LOCATION_ADDRESS = cloneDeep(ADDRESS);
HOME_LOCATION_ADDRESS.name = "home_address";
HOME_LOCATION_ADDRESS.label = "Home Address";

const LOCATION = {
  name: "home_location",
  label: "Home Location",
  items: [
    {
      name: "home_phone",
      label: "Home Phone #",
      toolTip: "Enter the phone number for your home.",
      type: "tel",
      validator: null,
    },
    {
      name: "home_county",
      label: "County",
      toolTip: "Enter the county your home is in.",
      type: "string",
      validator: null,
    },
    {
      name: "home_key_location",
      label: "Key Location",
      toolTip: "Enter where to find the key to this home.",
      type: "textarea",
      validator: null,
    },
  ],
};

const LocationCard = (props) => {
  const left = [HOME_LOCATION_ADDRESS];
  const right = [LOCATION];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default LocationCard;

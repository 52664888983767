import { cloneDeep } from "lodash";
import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { ADDRESS, PAYMENT_TYPES, PLEASE_SELECT, PROVIDER } from "../../common/forms/sharedViews";

const ALARM_SERVICE_ADDRESS = cloneDeep(ADDRESS);
ALARM_SERVICE_ADDRESS.name = "service_address";
ALARM_SERVICE_ADDRESS.label = "Alarm Service Address";

const ALARM_PROVIDER = cloneDeep(PROVIDER);
ALARM_PROVIDER.name = "alarm_provider";
ALARM_PROVIDER.label = "Alarm Company";

const ALARM = {
  name: "alarm",
  label: "Alarm Information",
  items: [
    {
      name: "alarm_arm",
      label: "Turn ON Alarm",
      toolTip: "Enter the way to turn on the alarm.",
      type: "textarea",
      validator: null,
    },
    {
      name: "alarm_disarm",
      label: "Turn OFF Alarm",
      toolTip: "Enter the way to turn off the alarm.",
      type: "textarea",
      validator: null,
    },
    {
      name: "acct_number",
      label: "Account Number",
      toolTip: "Enter the account number at this service.",
      type: "string",
      validator: null,
    },
    {
      name: "due_day",
      label: "Pay on the",
      toolTip: "Enter the bill due day for this service.",
      type: "string",
      validator: null,
    },
    {
      name: "due_freq",
      label: "Of Every",
      toolTip: "Enter the bill due frequency for this service.",
      type: "select",
      validator: null,
      options: [PLEASE_SELECT, "Month", "Quarter", "Year", "Other"],
    },
    {
      name: "payment_how",
      label: "How Paid",
      toolTip: "Select how you pay this service.",
      type: "select",
      validator: null,
      options: PAYMENT_TYPES,
    },
  ],
};

const AlarmCard = (props) => {
  const left = [ALARM, ALARM_SERVICE_ADDRESS];
  const right = [ALARM_PROVIDER];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default AlarmCard;

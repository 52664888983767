import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { PLEASE_SELECT } from "../../common/forms/sharedViews";

const ID = {
  name: "id",
  label: "Identification Card",
  items: [
    {
      name: "id_type",
      label: "Type of ID",
      toolTip: "What type of ID is this?",
      type: "select",
      validator: null,
      options: [PLEASE_SELECT, "Drivers License", "Passport", "School", "Birth Certificate", "Other"],
    },
    {
      name: "id_source",
      label: "ID Source",
      toolTip: "Who issued this id?",
      type: "string",
      validator: null,
    },
    {
      name: "id_number",
      label: "ID #",
      toolTip: "What is the ID number?",
      type: "string",
      validator: null,
    },
    {
      name: "id_expiration",
      label: "Expiration",
      toolTip: "When does this ID expire?",
      type: "string",
      validator: null,
    },
    {
      name: "id_name",
      label: "ID Name",
      toolTip: "What name is on this id?",
      type: "string",
      validator: null,
    },
  ],
};

const IDCard = (props) => {
  const left = [ID];
  const right = [];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default IDCard;

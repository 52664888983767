import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { ADDRESS, CONTACT } from "../../common/forms/sharedViews";

const BANK_ACCT = {
  name: "bank_acct",
  label: "Bank Accounts",
  items: [
    {
      name: "bank",
      label: "Bank",
      type: "string",
      validator: null,
    },
    {
      name: "branch",
      label: "Branch",
      type: "string",
      validator: null,
    },
    {
      name: "last_four",
      label: "(Optional) Account Number Last 4 Digits",
      type: "string",
      validator: null,
    },
    {
      name: "personal_business",
      label: "Personal or Business",
      type: "select",
      validator: null,
      options: ["Personal", "Business", "Trust"],
    },
    {
      name: "acct_type",
      label: "Account Type",
      type: "select",
      validator: null,
      options: ["Checking", "Savings", "CD"],
    },
  ],
};

const BankAccountCard = (props) => {
  const left = [BANK_ACCT];
  const right = [ADDRESS, CONTACT];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default BankAccountCard;

import React from "react";

// health
import LivingWillCard from "../../navs/health/LivingWillCard";
import AllergyCard from "../../navs/health/AllergyCard";
import HealthInsuranceCard from "../../navs/insurance/HealthInsuranceCard";
import PhysicalCard from "../../navs/health/PhysicalCard";
import HealthItemCard from "../../navs/health/HealthItemCard";
import HealthProfessionalCard from "../../navs/health/HealthProfessionalCard";
import HealthInstructionCard from "../../navs/health/HealthInstructionCard";
import MedicationCard from "../../navs/health/MedicationCard";

// legal
import WillCard from "../../navs/legal/WillCard";
import TrustCard from "../../navs/legal/TrustCard";
import PoaCard from "../../navs/legal/PoaCard";
import LegalOtherCard from "../../navs/legal/LegalOtherCard";
import LegalProfessionalCard from "../../navs/legal/LegalProfessionalCard";
import LegalInstructionCard from "../../navs/legal/LegalInstructionCard";

// financial
import BankAccountCard from "../../navs/finance/BankAccountCard";
import RetirementAccountCard from "../../navs/finance/RetirementAccountCard";
import InvestmentCard from "../../navs/finance/PortfolioAccountCard";
import RealEstateCard from "../../navs/finance/RealEstateCard";
import OtherInvestmentCard from "../../navs/finance/OtherInvestmentCard";
import BillCard from "../../navs/finance/BillCard";
import FinanceInstructionCard from "../../navs/finance/FinanceInstructionCard";

// insurance
import LifeInsuranceCard from "../../navs/insurance/LifeInsuranceCard";
import HomeInsuranceCard from "../../navs/insurance/HomeInsuranceCard";

// possessions
import ItemCard from "../../navs/possessions/ItemCard";
import VehicleCard from "../../navs/possessions/VehicleCard";

// personal
import IDCard from "../../navs/personal/IDCard";
import LicenseCard from "../../navs/personal/LicenseCard";

// spiritual

// family
import EmergencyContactCard from "../../navs/family/EmergencyContactCard";
import FamilyInstructionCard from "../../navs/family/FamilyInstructionCard";

//home
import RentCard from "../../navs/home/LandlordCard";
import MortgageCard from "../../navs/home/MortgageCard";
import UtilityCard from "../../navs/home/UtilityCard";
import PetCareCard from "../../navs/home/PetCareCard";
import ServiceCard from "../../navs/home/ServiceCard";
import AlarmCard from "../../navs/home/AlarmCard";
import LocationCard from "../../navs/home/LocationCard";
import HomeInstructionCard from "../../navs/home/HomeInstructionCard";

// digital
import DeviceCard from "../../navs/digital/DeviceCard";

// thoughts
import MessageCard from "../../navs/thoughts/MessageCard";
import LegacyInstructionCard from "../../navs/thoughts/LegacyInstructionCard";

// recommendations
import FactsCard from "../../navs/recommend/FactsCard";
import FactsForm from "../../navs/recommend/FactsForm";

const CATEGORIES = {
  HEALTH: "Health",
  LEGAL: "Legal",
  FINANCIAL: "Financial",
  INSURANCE: "Insurance",
  CAREER: "Career",
  POSSESSIONS: "Possessions",
  PERSONAL: "Personal",
  SPIRITUAL: "Spiritual Beliefs",
  FAMILY: "Family",
  HOME: "Home",
  COMMUNITY: "Community",
  DIGITAL_WORLD: "Digital World",
  INTERESTS: "Interests",
  THOUGHTS: "Thoughts and Messages",
  RECOMMENDATIONS: "Recommendations",
};

/** Card type metadata */
export const CARDS = {
  // ******************************************* health
  LIVING_WILL: {
    type: "health:directive:living_will",
    display: "Living Will",
    categories: [CATEGORIES.HEALTH],
    blurb: "",
    image: "",
  },
  FB_LIVING_WILL: {
    type: "fbhealth:directive:living_will",
    display: "Living Will",
    categories: [CATEGORIES.HEALTH],
    blurb: "",
    image: "",
  },
  FB_HEALTH_POA: {
    type: "fbhealth:directive:health_poa",
    display: "Health Power of Attorney",
    categories: [CATEGORIES.HEALTH],
    blurb: "",
    image: "",
  },
  MEDICATION: {
    type: "health:medication:general",
    display: "Medication",
    categories: [CATEGORIES.HEALTH],
    blurb: "",
    image: "",
  },
  MEDICATION_ALLERGY: {
    type: "health:allergy:medication",
    display: "Medication Allergy",
    categories: [CATEGORIES.HEALTH],
    blurb: "",
    image: "",
  },
  PHYSICAL_CONDITION: {
    type: "health:physical:condition",
    display: "Physical Condition",
    categories: [CATEGORIES.HEALTH],
    blurb: "",
    image: "",
  },
  HEALTH_ITEM: {
    type: "health:item:general",
    display: "Important Health Item",
    categories: [CATEGORIES.HEALTH],
    blurb: "",
    image: "",
  },
  HEALTH_PRO: {
    type: "health:professional:doctor",
    display: "Health Professional",
    categories: [CATEGORIES.HEALTH],
    blurb: "",
    image: "",
  },
  FB_HEALTH_PRO: {
    type: "fbhealth:professional:doctor",
    display: "Health Professional",
    categories: [CATEGORIES.HEALTH],
    blurb: "",
    image: "",
  },
  HEALTH_INSTRUCTION: {
    type: "health:instruction:general",
    display: "Health Instruction",
    categories: [CATEGORIES.HEALTH],
    blurb: "",
    image: "",
  },

  // ******************************************* legal
  LEGAL_WILL: {
    type: "legal:will:general",
    display: "Will",
    categories: [CATEGORIES.LEGAL],
    blurb: "",
    image: "",
  },
  LEGAL_TRUST: {
    type: "legal:trust:general",
    display: "Trust",
    categories: [CATEGORIES.LEGAL],
    blurb: "",
    image: "",
  },
  FB_LEGAL_TRUST: {
    type: "fblegal:trust:general",
    display: "Trust",
    categories: [CATEGORIES.LEGAL],
    blurb: "",
    image: "",
  },
  LEGAL_POA: {
    type: "legal:poa:general",
    display: "Power Of Attorney",
    categories: [CATEGORIES.LEGAL],
    blurb: "",
    image: "",
  },
  FB_LEGAL_POA: {
    type: "fblegal:poa:general",
    display: "Power Of Attorney",
    categories: [CATEGORIES.LEGAL],
    blurb: "",
    image: "",
  },
  LEGAL_OTHER: {
    type: "legal:other:general",
    display: "Legal Document",
    categories: [CATEGORIES.LEGAL],
    blurb: "",
    image: "",
  },
  LEGAL_PRO: {
    type: "legal:professional:general",
    display: "Legal Professional",
    categories: [CATEGORIES.LEGAL],
    blurb: "",
    image: "",
  },
  LEGAL_INSTRUCTION: {
    type: "legal:instruction:general",
    display: "Legal Instruction",
    categories: [CATEGORIES.LEGAL],
    blurb: "",
    image: "",
  },

  // ******************************************* financial
  BANK_ACCOUNT: {
    type: "financial:asset:bank_account",
    display: "Bank Account",
    categories: [CATEGORIES.FINANCIAL],
    blurb: "",
    image: "",
  },
  PORTFOLIO_ACCOUNT: {
    type: "financial:investment:portfolio",
    display: "Portfolio Account",
    categories: [CATEGORIES.FINANCIAL],
    blurb: "",
    image: "",
  },
  REAL_ESTATE: {
    type: "financial:investment:real_estate",
    display: "Investment Real Estate",
    categories: [CATEGORIES.FINANCIAL],
    blurb: "",
    image: "",
  },
  OTHER_INVESTMENT: {
    type: "financial:investment:other",
    display: "Other Investment",
    categories: [CATEGORIES.FINANCIAL],
    blurb: "",
    image: "",
  },
  RETIREMENT_ACCOUNT: {
    type: "financial:asset:retirement_account",
    display: "Retirement Account",
    categories: [CATEGORIES.FINANCIAL],
    blurb: "",
    image: "",
  },
  BILL: {
    type: "financial:bill:general",
    display: "Bill",
    categories: [CATEGORIES.FINANCIAL],
    blurb: "",
    image: "",
  },
  FB_BILL: {
    type: "fbfinancial:bill:general",
    display: "Bill",
    categories: [CATEGORIES.FINANCIAL],
    blurb: "",
    image: "",
  },
  FINANCE_INSTRUCTION: {
    type: "finance:instruction:general",
    display: "Finance Instruction",
    categories: [CATEGORIES.FINANCIAL],
    blurb: "",
    image: "",
  },
  // ******************************************* insurance
  HEALTH_INSURANCE: {
    type: "insurance:health:general",
    display: "Health Insurance Policy",
    categories: [CATEGORIES.INSURANCE, CATEGORIES.HEALTH],
    blurb: "",
    image: "",
  },
  LIFE_INSURANCE: {
    type: "insurance:life:general",
    display: "Life Insurance Policy",
    categories: [CATEGORIES.INSURANCE],
    blurb: "",
    image: "",
  },
  HOME_INSURANCE: {
    type: "insurance:home:general",
    display: "Home Insurance Policy",
    categories: [CATEGORIES.INSURANCE, CATEGORIES.HOME],
    blurb: "",
    image: "",
  },

  // ******************************************* possessions
  ITEM: {
    type: "possessions:item:general",
    display: "Item",
    categories: [CATEGORIES.POSSESSIONS],
    blurb: "",
    image: "",
  },
  VEHICLE: {
    type: "possessions:item:vehicle",
    display: "Vehicle",
    categories: [CATEGORIES.POSSESSIONS],
    blurb: "",
    image: "",
  },

  // ******************************************* personal
  ID: {
    type: "personal:id:general",
    display: "ID",
    categories: [CATEGORIES.PERSONAL],
    blurb: "",
    image: "",
  },
  LICENSE: {
    type: "personal:license:general",
    display: "License",
    categories: [CATEGORIES.PERSONAL],
    blurb: "",
    image: "",
  },

  // ******************************************* spiritual

  // ******************************************* family
  EMERGENCY: {
    type: "family:emergency:general",
    display: "Emergency Contact",
    categories: [CATEGORIES.FAMILY],
    blurb: "",
    image: "",
  },
  FAMILY_INSTRUCTION: {
    type: "family:instruction:general",
    display: "Family Instruction",
    categories: [CATEGORIES.FAMILY],
    blurb: "",
    image: "",
  },

  // ******************************************* home
  HOME_LANDLORD: {
    type: "home:payment:rent",
    display: "Rent",
    categories: [CATEGORIES.HOME],
    blurb: "",
    image: "",
  },
  HOME_MORTGAGE: {
    type: "home:payment:mortgage",
    display: "Mortgage",
    categories: [CATEGORIES.HOME],
    blurb: "",
    image: "",
  },
  HOME_UTILITY: {
    type: "home:payment:utility",
    display: "Utility",
    categories: [CATEGORIES.HOME],
    blurb: "",
    image: "",
  },
  PETCARE: {
    type: "family:pets:petcare",
    display: "Pet Care",
    categories: [CATEGORIES.HOME, CATEGORIES.FAMILY],
    blurb: "",
    image: "",
  },
  HOME_SERVICE: {
    type: "home:payment:service",
    display: "Service",
    categories: [CATEGORIES.HOME],
    blurb: "",
    image: "",
  },
  HOME_LOCATION: {
    type: "home:location:general",
    display: "Location",
    categories: [CATEGORIES.HOME],
    blurb: "",
    image: "",
  },
  HOME_ALARM: {
    type: "home:location:alarm",
    display: "Security Alarm",
    categories: [CATEGORIES.HOME],
    blurb: "",
    image: "",
  },
  HOME_INSTRUCTION: {
    type: "home:instruction:general",
    display: "Home Instructions",
    categories: [CATEGORIES.HOME],
    blurb: "",
    image: "",
  },
  // ******************************************* digital
  DIGITAL_DEVICE: {
    type: "digital:device:general",
    display: "Digital Device",
    categories: [CATEGORIES.DIGITAL_WORLD],
    blurb: "",
    image: "",
  },

  // ******************************************* thoughts
  THOUGHTS_MESSAGE: {
    type: "thoughts:message:general",
    display: "Message",
    categories: [CATEGORIES.THOUGHTS],
    blurb: "",
    image: "",
  },
  THOUGHTS_INSTRUCTION: {
    type: "thoughts:legacy:instruction",
    display: "Legacy Instruction",
    categories: [CATEGORIES.THOUGHTS],
    blurb: "",
    image: "",
  },

  // ******************************************* recommendations
  FACTS: {
    type: "recommend:facts:card",
    display: "Life Facts",
    categories: [CATEGORIES.RECOMMENDATIONS],
    blurb: "",
    image: "",
  },
  FACTS_FORM: {
    type: "recommend:facts:form",
    display: "Life Facts",
    categories: [CATEGORIES.RECOMMENDATIONS],
    blurb: "",
    image: "",
  },
};

export function thisOrNone(value, placeholder) {
  if (value === true) {
    return "Yes";
  } else if (value === false) {
    return "No";
  }
  return value ? value : <p className="font-italic text-400 mb-1">{placeholder ? placeholder : "None"}</p>;
}

export function starsOrNone(value, placeholder) {
  const stars = value ? "*".repeat(value.length) : null;
  return thisOrNone(stars, placeholder);
}

/**
 * Returns true if the string starts with one of the prefixes.
 *
 * @param {*} type - string card type
 * @param {*} types - array of card type definitions (see above)
 */
export function cardTypeMatches(type, types) {
  return type && types && types.some((card) => card && card.type && type.startsWith(card.type));
}

/**
 * Takes an array of records and renders them into cards. Usually.
 * @param {*} records
 */
export function cards(records = [], attachments = [], readOnly = false) {
  return records.map((r) => {
    const recordAttachments = attachments.filter((a) => a.record === r.id);
    const props = { key: r.id, id: r.id, record: r, attachments: recordAttachments, readOnly: readOnly };
    switch (r.type) {
      // health
      case CARDS.LIVING_WILL.type:
        return <LivingWillCard {...props} />;
      case CARDS.FB_LIVING_WILL.type:
        return <LivingWillCard {...props} />;
      case CARDS.FB_HEALTH_POA.type:
        return <PoaCard {...props} />;
      case CARDS.MEDICATION_ALLERGY.type:
        return <AllergyCard {...props} />;
      case CARDS.MEDICATION.type:
        return <MedicationCard {...props} />;
      case CARDS.PHYSICAL_CONDITION.type:
        return <PhysicalCard {...props} />;
      case CARDS.HEALTH_ITEM.type:
        return <HealthItemCard {...props} />;
      case CARDS.HEALTH_PRO.type:
        return <HealthProfessionalCard {...props} />;
      case CARDS.FB_HEALTH_PRO.type:
        return <HealthProfessionalCard {...props} />;
      case CARDS.HEALTH_INSTRUCTION.type:
        return <HealthInstructionCard {...props} />;

      // legal
      case CARDS.LEGAL_WILL.type:
        return <WillCard {...props} />;
      case CARDS.LEGAL_TRUST.type:
        return <TrustCard {...props} />;
      case CARDS.FB_LEGAL_TRUST.type:
        return <TrustCard {...props} />;
      case CARDS.LEGAL_POA.type:
        return <PoaCard {...props} />;
      case CARDS.FB_LEGAL_POA.type:
        return <PoaCard {...props} />;
      case CARDS.LEGAL_OTHER.type:
        return <LegalOtherCard {...props} />;
      case CARDS.LEGAL_PRO.type:
        return <LegalProfessionalCard {...props} />;
      case CARDS.LEGAL_INSTRUCTION.type:
        return <LegalInstructionCard {...props} />;

      // financial
      case CARDS.PORTFOLIO_ACCOUNT.type:
        return <InvestmentCard {...props} />;
      case CARDS.REAL_ESTATE.type:
        return <RealEstateCard {...props} />;
      case CARDS.OTHER_INVESTMENT.type:
        return <OtherInvestmentCard {...props} />;
      case CARDS.BANK_ACCOUNT.type:
        return <BankAccountCard {...props} />;
      case CARDS.RETIREMENT_ACCOUNT.type:
        return <RetirementAccountCard {...props} />;
      case CARDS.BILL.type:
        return <BillCard {...props} />;
      case CARDS.FB_BILL.type:
        return <BillCard {...props} />;
      case CARDS.FINANCE_INSTRUCTION.type:
        return <FinanceInstructionCard {...props} />;

      // insurance
      case CARDS.LIFE_INSURANCE.type:
        return <LifeInsuranceCard {...props} />;
      case CARDS.HEALTH_INSURANCE.type:
        return <HealthInsuranceCard {...props} />;
      case CARDS.HOME_INSURANCE.type:
        return <HomeInsuranceCard {...props} />;

      // possessions
      case CARDS.ITEM.type:
        return <ItemCard {...props} />;
      case CARDS.VEHICLE.type:
        return <VehicleCard {...props} />;

      // personal
      case CARDS.ID.type:
        return <IDCard {...props} />;
      case CARDS.LICENSE.type:
        return <LicenseCard {...props} />;

      // spritual

      // family
      case CARDS.EMERGENCY.type:
        return <EmergencyContactCard {...props} />;
      case CARDS.FAMILY_INSTRUCTION.type:
        return <FamilyInstructionCard {...props} />;

      // digital
      case CARDS.DIGITAL_DEVICE.type:
        return <DeviceCard {...props} />;

      // thoughts
      case CARDS.THOUGHTS_MESSAGE.type:
        return <MessageCard {...props} />;
      case CARDS.THOUGHTS_INSTRUCTION.type:
        return <LegacyInstructionCard {...props} />;

      // home
      case CARDS.HOME_LANDLORD.type:
        return <RentCard {...props} />;
      case CARDS.HOME_MORTGAGE.type:
        return <MortgageCard {...props} />;
      case CARDS.HOME_UTILITY.type:
        return <UtilityCard {...props} />;
      case CARDS.PETCARE.type:
        return <PetCareCard {...props} />;
      case CARDS.HOME_SERVICE.type:
        return <ServiceCard {...props} />;
      case CARDS.HOME_LOCATION.type:
        return <LocationCard {...props} />;
      case CARDS.HOME_ALARM.type:
        return <AlarmCard {...props} />;
      case CARDS.HOME_INSTRUCTION.type:
        return <HomeInstructionCard {...props} />;

      // recommendations
      case CARDS.FACTS.type:
        return <FactsCard {...props} />;
      case CARDS.FACTS_FORM.type:
        return <FactsForm {...props} />;

      default:
        return <div>unknown record type</div>;
    }
  });
}

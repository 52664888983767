import React from "react";
import { Col, Row } from "reactstrap";

export const LABEL_TOP = "align-items-start";
export const LABEL_CENTER = "align-items-center";

const ModalInputRow = (props) => {
  const { children, label, labelAlign = LABEL_CENTER } = props;

  return (
    <>
      <Row className="mb-2">
        <Col className={"d-inline-flex justify-content-end " + labelAlign} xs={3}>
          <span className="mr-2 text-right">{label}</span>
        </Col>
        <Col className="d-inline-flex align-items-center">{children}</Col>
      </Row>
    </>
  );
};

export default ModalInputRow;

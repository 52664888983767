import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";

const HealthItemCard = (props) => {
  const left = null;
  const right = null;

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default HealthItemCard;

import { cloneDeep } from "lodash";
import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { ADDRESS, PAYMENT_TYPES, PLEASE_SELECT, PROVIDER } from "../../common/forms/sharedViews";

const UTILITY_SERVICE_ADDRESS = cloneDeep(ADDRESS);
UTILITY_SERVICE_ADDRESS.name = "address_service";
UTILITY_SERVICE_ADDRESS.label = "Service Address";

const UTILITY_PROVIDER_ADDRESS = cloneDeep(ADDRESS);
UTILITY_PROVIDER_ADDRESS.name = "address_payee";
UTILITY_PROVIDER_ADDRESS.label = "Payee Address";

const UTILITY = {
  name: "utility",
  label: "Utility Information",
  items: [
    {
      name: "utility_type",
      label: "Type of service",
      toolTip: "Select the type of service this utility provides.",
      type: "select",
      validator: null,
      options: [PLEASE_SELECT, "Water", "Electric", "Gas", "Trash", "Internet", "Cable", "Alarm", "Other"],
    },
    {
      name: "acct_number",
      label: "Account Number",
      toolTip: "Enter the account number for this utility.",
      type: "string",
      validator: null,
    },
    {
      name: "shutoff",
      label: "Shutoff Location",
      toolTip: "Enter where and how you shut off this utility.",
      type: "textarea",
      validator: null,
    },
    {
      name: "due_date",
      label: "When Due?",
      toolTip: "Enter the bill due day for this utility.",
      type: "string",
      validator: null,
    },
    {
      name: "payment_how",
      label: "How Paid",
      toolTip: "Select how you pay this utility.",
      type: "select",
      validator: null,
      options: PAYMENT_TYPES,
    },
  ],
};

const UtilityCard = (props) => {
  const left = [UTILITY, UTILITY_SERVICE_ADDRESS];
  const right = [PROVIDER, UTILITY_PROVIDER_ADDRESS];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default UtilityCard;

import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { PLEASE_SELECT } from "../../common/forms/sharedViews";

const FACTS_HOME = {
  name: "facts_home",
  label: "Home and Family",
  items: [
    {
      name: "facts_homeType",
      label: "Type of Home",
      toolTip: "What type of residence do you live in?",
      type: "select",
      validator: null,
      options: [
        PLEASE_SELECT,
        "I own my primary residence",
        "I rent my primary residence",
        "I live at an organizational facility",
        "Other",
      ],
    },
    {
      name: "facts_maritalStatus",
      label: "Marital Status",
      toolTip: "What is your marital status?",
      type: "select",
      validator: null,
      options: [PLEASE_SELECT, "Single", "Married", "With Partner", "Divorced", "Other"],
    },
    {
      name: "facts_minorDependent",
      label: "I have Children under 18",
      toolTip: "Do you currently have children under 18?",
      type: "checkbox",
    },
    {
      name: "facts_adultDependents",
      label: "I have Adult Dependents",
      toolTip: "Do you currently have dependents over 18?",
      type: "checkbox",
    },
    {
      name: "facts_Pet",
      label: "I have pets",
      toolTip: "Do you currently own pets?",
      type: "checkbox",
    },
  ],
};
const FACTS_JOB = {
  name: "facts_job",
  label: "Employment",
  items: [
    {
      name: "facts_isEmployed",
      label: "I am Currently Employed",
      toolTip: "Are you currently working at a job?",
      type: "checkbox",
    },
    {
      name: "facts_isBizOwner",
      label: "I own a business",
      toolTip: "Do you currently own a business?",
      type: "checkbox",
    },
    {
      name: "facts_isRetired",
      label: "I am Retired",
      toolTip: "Are you currently retired?",
      type: "checkbox",
    },
  ],
};
const FACTS_INSURANCE = {
  name: "facts_insurance",
  label: "Do you have",
  items: [
    {
      name: "facts_healthInsurance",
      label: "Health Insurance",
      toolTip: "Do you have Health Insurance?",
      type: "checkbox",
    },
    {
      name: "facts_lifeInsurance",
      label: "Life Insurance",
      toolTip: "Do you have Life Insurance?",
      type: "checkbox",
    },
    {
      name: "facts_disabilityInsurance",
      label: "Disability Insurance",
      toolTip: "Do you have Disability Insurance?",
      type: "checkbox",
    },
    {
      name: "facts_longTermInsurance",
      label: "Long Term Care Insurance",
      toolTip: "Do you have Long Term Care  Insurance?",
      type: "checkbox",
    },
    {
      name: "facts_propertyInsurance",
      label: "Property Insurance",
      toolTip: "Do you have Property Insurance?",
      type: "checkbox",
    },
  ],
};
const FACTS_LEGAL = {
  name: "facts_legal",
  label: "Do you have",
  items: [
    {
      name: "facts_will",
      label: "A Will",
      toolTip: "Do you have a signed, notarized will in place?",
      type: "checkbox",
    },
    {
      name: "facts_trust",
      label: "Any Trusts",
      toolTip: "Do you have any signed Trusts in place?",
      type: "checkbox",
    },
    {
      name: "facts_financePOA",
      label: "Financial Power of Attorney",
      toolTip: "Do you have a signed legal document appointing someone to manage your finances if you can't?",
      type: "checkbox",
    },
    {
      name: "facts_healthPOA",
      label: "Health Power of Attorney",
      toolTip:
        "Do you have a signed Health Power of Attorney appointing someone to make your health decisions if you can't?",
      type: "checkbox",
    },
    {
      name: "facts_AdvanceDirective",
      label: "Living Will or Advance Directive",
      toolTip: "Do you have a signed advance directive specifying your health preferences if you can't?",
      type: "checkbox",
    },
  ],
};
const FACTS_PROFESSIONALS = {
  name: "facts_professionals",
  label: "Do you have",
  items: [
    {
      name: "facts_primaryPhysician",
      label: "A Primary Care Physician",
      toolTip: "Do you have a primary care physician?",
      type: "checkbox",
    },
    {
      name: "facts_attorney",
      label: "An Attorney",
      toolTip: "Do you have an attorney?",
      type: "checkbox",
    },
    {
      name: "facts_accountant",
      label: "An Accountant",
      toolTip: "Do you have an accountant?",
      type: "checkbox",
    },
    {
      name: "facts_financialPlanner",
      label: "A Financial Planner",
      toolTip: "Do you have a financial planner?",
      type: "checkbox",
    },
    {
      name: "facts_taxAdvisor",
      label: "A Tax Advisor",
      toolTip: "Do you have a tax advisor?",
      type: "checkbox",
    },
    {
      name: "facts_trustedContact",
      label: "A Trusted Contact",
      toolTip: "Do you have a trusted contact?",
      type: "checkbox",
    },
  ],
};
const FACTS_DIGITAL = {
  name: "facts_digital",
  label: "Do your Digital Devices use",
  items: [
    {
      name: "facts_isComputerPassword",
      label: "Password for Computer",
      toolTip: "Does your computer have a password to access it?",
      type: "checkbox",
    },
    {
      name: "facts_isPhoneUnlock",
      label: "Code to Unlock Phone",
      toolTip: "Does your phone have a code to unlock it?",
      type: "checkbox",
    },
    {
      name: "facts_hasPasswordManager",
      label: "A Password Manager",
      toolTip: "Do you manage your passwords from a central password list?",
      type: "checkbox",
    },
    {
      name: "facts_hasAuthDevice",
      label: "An Authentication Program",
      toolTip: "Do you use an authentication program?",
      type: "checkbox",
    },
  ],
};

const FactsCard = (props) => {
  const left = [FACTS_HOME, FACTS_JOB, FACTS_PROFESSIONALS];
  const right = [FACTS_INSURANCE, FACTS_LEGAL, FACTS_DIGITAL];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default FactsCard;

import React from "react";
import { Row, Col } from "reactstrap";
import CardSummary from "../../dashboard/CardSummary";
import WidgetsSectionTitle from "../../widgets/WidgetsSectionTitle";
import TopProducts from "../../dashboard-alt/TopProducts";
import topProducts, { productColors } from "../../../data/dashboard/topProducts";

const OEDashboard = (props) => {
  return (
    <>
      <WidgetsSectionTitle
        title="Information Organization Status"
        // subtitle="What is done and what remains to organize your information."
        subtitle=""
        className="mb-2 align-items-center"
        icon="percentage"
      />

      {/* Here is the bar chart */}
      <Row noGutters>
        <Col xl={12}>
          <TopProducts data={topProducts} colors={productColors} className="mb-3" />
        </Col>
      </Row>
      <WidgetsSectionTitle
        title="Information Cards Overview"
        // subtitle="Information Shared by Others with You"
        subtitle=""
        className="mb-2 align-items-center"
        icon="percentage"
      />
      <Row className="card-deck">
        <CardSummary title="Life Facts" color="danger" icon="house-user" linkText="Update Life Attributes">
          <span>Last updated 12/24/19</span>
        </CardSummary>
        <CardSummary title="Completed Cards" color="success" icon="file" linkText="See More">
          <span>Information Cards on file: 18</span>
        </CardSummary>
        <CardSummary title="Recommended Cards" color="info" icon="file" linkText="See Recommended Cards">
          <span>Cards to complete: 12</span>
        </CardSummary>
      </Row>

      <WidgetsSectionTitle
        title="Your Contacts"
        // subtitle="An overview of the contacts you have specified."
        subtitle=""
        className="mb-2 align-items-center"
        icon="percentage"
      />
      <Row className="card-deck">
        <CardSummary title="Messages" color="danger" icon="bell" linkText="See All Messages">
          <span>Unread messages: 3</span>
        </CardSummary>
        <CardSummary title="Contacts" color="success" icon="people-arrows" linkText="See Your Contacts">
          <span>Contacts on file: 28</span>
        </CardSummary>
        <CardSummary title="Invites To Others" color="info" icon="user-plus" linkText="See Pending Invites">
          <span>Pending invites from you: 3</span>
        </CardSummary>

        {/* REJECTED INVITES CARD? move Messages panel to another group?*/}
      </Row>

      <WidgetsSectionTitle
        title="How You are Sharing Information with Others"
        // subtitle="Your shared cards will be shared to specific share recipients according to your specified " +
        //     "events."
        subtitle=""
        className="mb-2 align-items-center"
        icon="percentage"
      />
      <Row className="card-deck">
        <CardSummary
          title="Your Shared Cards"
          color="success"
          icon="share-alt"
          linkText="See Your Shares"
          to="/sharing/overview"
        >
          <span>Cards set to share: 26</span>
        </CardSummary>
        <CardSummary
          title="Your Share Recipients"
          color="success"
          icon="share-alt"
          linkText="See Who You are Sharing With"
          to="/sharing/overview"
        >
          <span>Recipients set to share to: 3</span>
        </CardSummary>
        <CardSummary
          title="Active Shares"
          color="success"
          icon="share-alt"
          linkText="See Active Shares"
          to="/sharing/overview"
        >
          <span>Currently Sharing 0 Cards</span>
        </CardSummary>
      </Row>

      <WidgetsSectionTitle
        title="Events and Verifications for Sharing"
        // subtitle="When your specified events are verified, your shared information will be shown to the " +
        //     "specified share recipients."
        subtitle=""
        className="mb-2 align-items-center"
        icon="percentage"
      />
      <Row className="card-deck">
        <CardSummary
          title="Your Sharing Events"
          color="success"
          icon="calendar-alt"
          linkText="See Your Events"
          to="/sharing/events"
        >
          <span>Your Events for sharing: 4</span>
        </CardSummary>
        <CardSummary
          title="Your Active Events"
          color="success"
          icon="calendar-alt"
          linkText="See Active Events"
          to="/sharing/events"
        >
          <span>Your started Events: 0</span>
        </CardSummary>
        <CardSummary
          title="Verifications of Your Events"
          color="danger"
          icon="person-booth"
          linkText="See Others' Verifications of Your Events"
          to="/sharing/events"
        >
          <span>Events being verified: 1</span>
        </CardSummary>
      </Row>

      <WidgetsSectionTitle
        title="Others Sharing Information with You"
        // subtitle="Information Shared by Others with You"
        subtitle=""
        className="mb-2 align-items-center"
        icon="percentage"
      />
      <Row className="card-deck">
        <CardSummary
          title="Others' Shares"
          color="success"
          icon="share"
          linkText="See What is Shared to You"
          to="/sharing/overview"
        >
          <span>2 Others sharing 6 Cards</span>
        </CardSummary>
        <CardSummary
          title="Verifications of Others' Events"
          color="danger"
          icon="vote-yea"
          linkText="Verify Others' Events"
        >
          <span>Verifications needing attention: 2</span>
        </CardSummary>
        <CardSummary title="Pending Invites" color="danger" icon="user-plus" linkText="See Pending Invites">
          <span>Pending invites to you: 1{/* REJECTED INVITES CARD */}</span>
        </CardSummary>
      </Row>
    </>
  );
};

export default OEDashboard;

import { CARDS } from "../components/oe/common/cards/cardDefs";

// this defines default records

export default {
  // this is cached server state
  attachments: {
    items: [
      {
        id: "attachment1",
        record: "perslice001",
        type: "img",
        icon: "image",
        fileName: "Winter",
        src:
          "https://i.pinimg.com/236x/89/f0/9b/89f09b9f0600113d4e20f1b161c231d6--cute-puppies-images-cute-puppy-pictures.jpg",
      },
      {
        id: "attachment3",
        record: "healadva001",
        type: "pdf",
        icon: "file-pdf",
        fileName: "Living Will - Adam Becker.pdf",
        src: "https://oeserv.s3.amazonaws.com/202104501FF00001/Living+Will+and+Health+PoA-+Adam+Becker.pdf",
      },
      {
        id: "attachment5",
        record: "fb_living_will_adam_becker",
        type: "pdf",
        icon: "file-pdf",
        fileName: "Living Will - Adam Becker.pdf",
        src: "https://oeserv.s3.amazonaws.com/202104501FF00001/Living+Will+and+Health+PoA-+Adam+Becker.pdf",
      },
      {
        id: "attachmenTrust",
        record: "legatrus003",
        type: "pdf",
        icon: "file-pdf",
        fileName: "Adam Becker Revocable Living Trust",
        src: "https://oeserv.s3.amazonaws.com/202104501FF00001/Trust+-+Adam+Becker.pdf",
      },
      {
        id: "attachmentWill",
        record: "legawill001",
        type: "pdf",
        icon: "file-pdf",
        fileName: "Legal Will - Adam Becker",
        src: "https://oeserv.s3.amazonaws.com/202104501FF00001/Will+-+Adam+Becker.pdf",
      },
      {
        id: "attachmentPoA",
        record: "legapowe001",
        type: "pdf",
        icon: "file-pdf",
        fileName: "Durable Power of Attorney - Adam Becker",
        src: "https://oeserv.s3.amazonaws.com/202104501FF00001/Durable+PoA+-+Adam+Becker.pdf",
      },
      {
        id: "attachment2",
        record: "healadva002",
        type: "img",
        icon: "image",
        fileName: "Coffee",
        src:
          "https://media.apnarm.net.au/media/images/2017/05/05/cof290317dog01-3h91uajxg604gzrn6o2_fct2911x2183x424.0x70.0_t1880.JPG",
      },
      {
        id: "Message to Becky",
        record: "thoumess001",
        type: "vid",
        icon: "play",
        fileName: "sax",
        src: "https://oeserv.s3.amazonaws.com/202104501FF00001/sax.MP4",
      },
      {
        id: "Message to Penny",
        record: "thoumess002",
        type: "vid",
        icon: "play",
        fileName: "sax",
        src: "https://oeserv.s3.amazonaws.com/202104501FF00001/sax.MP4",
      },
      {
        id: "Message to Tom",
        record: "thoumess003",
        type: "vid",
        icon: "play",
        fileName: "sax",
        src: "https://oeserv.s3.amazonaws.com/202104501FF00001/sax.MP4",
      },
      {
        id: "attachment4",
        record: "finabank001",
        type: "pdf",
        icon: "file-pdf",
        fileName: "Menu",
        src: "https://oeserv.s3.amazonaws.com/Chef+King.pdf",
      },
    ],
  },
  // this is cached server state
  events: {
    items: [
      {
        id: "event001",
        title: "My Incapacity",
        type: "vote",
        votes: 2,
        voters: ["person002", "person003"], // needs to be indexed so we can query events for which I'm a voter
        description: "If I'm unable to competently make my own decisions.",
      },
      {
        id: "event002",
        title: "My Passing",
        type: "vote",
        votes: 1,
        voters: ["person001", "person003"],
        description: "When I pass away.",
      },
      {
        id: "event003",
        title: "Melissa's Marriage",
        type: "vote",
        votes: 1,
        voters: ["person001", "person002", "person003", "person004", "person005"],
        description: "Upon the legal marriage of my daughter, Melissa.",
      },
      {
        id: "event004",
        title: "Tom's Graduation",
        type: "vote",
        votes: 1,
        voters: ["person001", "person002", "person003", "person004"],
        description: "When Tom finally graduates from grad school.",
      },
      {
        id: "event005",
        title: "Mom's Incapacity",
        type: "vote",
        votes: 1,
        voters: ["person001", "person003", "person004"],
        description: "When Mom is not able to speak for herself",
      },
      {
        id: "event006",
        title: "Vacation to Norway",
        type: "date",
        votes: 1,
        voters: ["person001", "person003"],
        description: "Before we leave on vacation to Norway in June.",
      },
    ],
  },
  // includes ITK for my records as well as records shared with me.
  // this is cached server state
  importantToKnow: {
    items: [
      {
        id: "itk_001",
        record: "healadva001",
        value: "Please, no intubation! Also, I have a DNR with Doctor Roth.",
      },
      {
        id: "itk_002",
        record: "finabank001",
        value: "This account is co-signed with my ex-wife Meghan",
      },
      {
        id: "itk_003",
        record: "finabank002",
        value:
          "This is a business checking account for Becker Enterprises.  Transfer it with ownership of " +
          "the business.",
      },
      {
        id: "itk_004",
        record: "finareti001",
        value:
          "This is a Roth IRA and non-spouse beneficiaries will be required to take minimum required " +
          "distributions over 10 years, unless they are assigned to the custodial trust.",
      },
      {
        id: "itk_005",
        record: "healadva002",
        value: "Very important: Mom does not want to be kept on life support.",
      },
      {
        id: "itk_006",
        record: "healmedi001",
        value: "Wake me up to take this pill unless being given intravenously.",
      },
      {
        id: "itk_007",
        record: "healmedi002",
        value: "Make sure the Lipitor does not conflict with other drugs!",
      },
      {
        id: "itk_008",
        record: "healitem001",
        value: "If I cannot use this, please tell the doctors that I need something like it.",
      },
      {
        id: "itk_009",
        record: "healprof002",
        value: "Please tell Dr. Roth immediately if I can't take the Lipitor.",
      },
      {
        id: "itk_010",
        record: "legawill001",
        value: "The latest signed version of my will is in the fireproof box in my office closet.",
      },
      {
        id: "itk_011",
        record: "legatrus003",
        value: "Make sure this trust is registered as a '650' estate asset with the IRS.",
      },
      {
        id: "itk_012",
        record: "legainst001",
        value: "Original signed documents are in the fireproof box behind the basement freezer.",
      },
      {
        id: "itk_013",
        record: "finainve003",
        value: "Make sure to include this note in estate assets. It is assigned to the revocable trust.",
      },
      {
        id: "itk_014",
        record: "insuheal001",
        value: "This is an HSA policy. Make sure to make HSA deposits for me under Power of Attorney.",
      },
      {
        id: "itk_015",
        record: "digidevi001",
        value: "The access password is RgU46@!C. User name is AGBecker.",
      },
      // ********************************************************************** SHARED ITK
      {
        id: "itk_rebecca_health_insurance",
        ownerId: "person006", // ITKs that are shared with me have an owner ID.  My own ITKs don't.
        record: "rebecca_health_insurance",
        value:
          "This is an employer health plan, so if I'm forced to take leave from work you'll have to convert to " +
          "COBRA and make payments.",
      },
    ],
  },
  // this is cached server state
  notifications: {
    items: [
      {
        id: "notification_penny_becker_accepted",
        personId: "person002",
        children: "<strong>Penny Becker</strong> accepted your invitation.",
        time: "2d",
        className: "rounded-0 border-x-0 border-300 border-bottom-0",
        to: "/sharing/overview",
        unread: true,
      },
      {
        id: "notification_rebecca_becker_voting",
        personId: "person006",
        children: "Verify <strong>Melissa Becker</strong>'s event \"Susan's graduation\".",
        time: "9h",
        className: "rounded-0 border-x-0 border-300 border-bottom-0",
        to: "/sharing/verifications",
        unread: true,
      },
      {
        id: "notification_gloria_becker_birthday",
        personId: "person005",
        children: "Reminder: <strong>Susan Becker</strong>'s birthday is in a week.",
        time: "4d",
        className: "rounded-0 border-x-0 border-300 border-bottom-0",
        to: "#!",
      },
    ],
  },
  // this is cached server state
  records: {
    items: [
      // -------------- HEALTH ----------------
      {
        id: "fb_living_will_adam_becker",
        type: CARDS.FB_LIVING_WILL.type,
        value: {
          title: "Adam Becker Living Will",
          customFields: [
            {
              id: "custom_field_1",
              label: "Date Updated",
              type: "string",
              value: "Dec 12, 2020",
            },
            {
              id: "custom_field_2",
              label: "Updated by",
              type: "string",
              value: "Adam Becker",
            },
          ],
        },
      },
      {
        id: "fb_living_will_penny_becker",
        type: CARDS.FB_LIVING_WILL.type,
        value: {
          title: "Penny Becker Living Will",
          customFields: [
            {
              id: "custom_field_1",
              label: "Date Updated",
              type: "string",
              value: "March 30, 2017",
            },
            {
              id: "custom_field_2",
              label: "Updated by",
              type: "string",
              value: "Penny Becker",
            },
          ],
        },
      },
      {
        id: "fb_healpowe001",
        type: CARDS.FB_HEALTH_POA.type,
        value: {
          title: "Adam Becker Health Power Of Attorney",
        },
      },
      {
        id: "healadva001",
        type: CARDS.LIVING_WILL.type,
        value: {
          title: "My Living Will",
          customFields: [
            {
              id: "custom_field_1",
              label: "Date Updated",
              type: "string",
              value: "March 30, 2017",
            },
            {
              id: "custom_field_2",
              label: "Updated by",
              type: "string",
              value: "Adam Becker",
            },
          ],
        },
      },
      {
        id: "healadva002",
        type: CARDS.LIVING_WILL.type,
        value: {
          title: "Mom's Living Will",
        },
      },
      {
        id: "healalle001",
        type: CARDS.MEDICATION_ALLERGY.type,
        value: {
          title: "Penicillin Allergy",
        },
      },
      {
        id: "healmedi001",
        type: CARDS.MEDICATION.type,
        value: {
          title: "Dexamethasone",
          medication: {
            name: "Dexamethasone",
            nickName: "The Dexy",
            dosageAmount: "10 mg",
            dosageFrequency: "every 4 hours",
            location: "Night Table",
            importance: "High",
            isPrescribed: true,
          },
          prescription: {
            prescriberName: "Dr. Martens",
            prescriberPhone: "303-555-1212",
            prescriberEmail: "JonMartens@cuhealthcenter.com",
            startDate: "03/01/2020",
            endDate: "11/31/2020",
            isRefillable: true,
            fulfilledLocation: "Walgreens",
            fulfilledPhone: "720-555-1212",
          },
        },
      },
      {
        id: "healmedi002",
        type: CARDS.MEDICATION.type,
        value: {
          title: "Lipitor",
          medication: {
            name: "Lipitor",
            nickName: "Lipitor or 'the one in the green bottle'",
            dosageAmount: "15 mg",
            dosageFrequency: "every day",
            location: "Medicine Cabinet",
            importance: "High",
            isPrescribed: true,
          },
          prescription: {
            prescriberName: "Dr. Feinberg",
            prescriberPhone: "303-989-1476",
            prescriberEmail: "BFeinberg@coloradoclinics.com",
            startDate: "03/01/2018",
            endDate: "12/31/2022",
            isRefillable: true,
            fulfilledLocation: "Walgreens",
            fulfilledPhone: "720-555-1212",
          },
        },
      },
      {
        id: "healphys001",
        type: CARDS.PHYSICAL_CONDITION.type,
        value: {
          title: "Asthma",
        },
      },
      {
        id: "healitem001",
        type: CARDS.HEALTH_ITEM.type,
        value: {
          title: "Asthma Inhaler",
        },
      },
      {
        id: "healprof001",
        type: CARDS.HEALTH_PRO.type,
        value: {
          title: "Asthma Specialist",
        },
      },
      {
        id: "healprof002",
        type: CARDS.HEALTH_PRO.type,
        value: {
          title: "Cardiologist",
        },
      },
      {
        id: "healprof003",
        type: CARDS.HEALTH_PRO.type,
        value: {
          title: "Primary Physician",
        },
      },
      {
        id: "fb_healprof004",
        type: CARDS.FB_HEALTH_PRO.type,
        value: {
          title: "Adam Becker Primary Physician",
        },
      },
      {
        id: "fb_healprof005",
        type: CARDS.FB_HEALTH_PRO.type,
        value: {
          title: "Penny Becker Primary Physician",
        },
      },
      {
        id: "healinst001",
        type: CARDS.HEALTH_INSTRUCTION.type,
        value: {
          title: "Bring inhaler",
        },
      },
      // -------------- LEGAL ----------------
      {
        id: "legawill001",
        type: CARDS.LEGAL_WILL.type,
        value: {
          title: "Adam Becker Last Will and Testament",
          will: {
            will_name: "Will of Adam Becker",
            will_type: "Pour-Over",
            will_signer: "Adam Becker",
            will_date: "May 15, 2020",
            will_executor: "Antonio Falcono",
            will_alt_executor: "Philip Reese",
          },
          will_advisor: {
            first: "Antonio",
            middle: "",
            last: "Falcono",
            email: "AFalcono314@SmithFirm.com",
            phone: "317-865-3434",
          },
        },
      },
      {
        id: "legawill002",
        type: CARDS.LEGAL_WILL.type,
        value: {
          title: "Mom's Will",
          will: {
            will_name: "Will of Gloria Becker",
            will_type: "Pour-Over",
            will_signer: "Gloria Becker",
            will_date: "September 25, 2020",
            will_executor: "Adam Becker",
            will_alt_executor: "Philip Reese",
          },
          will_advisor: {
            first: "Adam",
            middle: "",
            last: "Becker",
            email: "ABecker@BeckerEnt.com",
            phone: "303-456-7890",
          },
        },
      },
      {
        id: "legatrus001",
        type: CARDS.LEGAL_TRUST.type,
        value: {
          title: "Kids' Trust",
        },
      },
      {
        id: "legatrus002",
        type: CARDS.LEGAL_TRUST.type,
        value: {
          title: "Spousal Trust",
        },
      },
      {
        id: "legatrus003",
        type: CARDS.LEGAL_TRUST.type,
        value: {
          title: "Revocable Living Trust",
        },
      },
      {
        id: "fb_legatrus003",
        type: CARDS.FB_LEGAL_TRUST.type,
        value: {
          title: "Adam Becker Revocable Living Trust",
        },
      },
      {
        id: "legapowe001",
        type: CARDS.LEGAL_POA.type,
        value: {
          title: "Durable Power Of Attorney",
        },
      },
      {
        id: "fb_legapowe001",
        type: CARDS.FB_LEGAL_POA.type,
        value: {
          title: "Adam Becker Durable Power Of Attorney",
        },
      },
      {
        id: "legaprof001",
        type: CARDS.LEGAL_PRO.type,
        value: {
          title: "My Estate Lawyer",
        },
      },
      {
        id: "legainst001",
        type: CARDS.LEGAL_INSTRUCTION.type,
        value: {
          title: "Signed copies of documents",
        },
      },
      // -------------- FINANCIAL ----------------
      {
        id: "finabank001",
        type: CARDS.BANK_ACCOUNT.type,
        value: {
          title: "Flatirons Bank Checking",
          address: {
            street1: "444 West Canyon",
            street2: undefined,
            city: "Boulder",
            state: "CO",
            zip: "80301",
          },
          contact: {
            first: "Jim",
            middle: "J",
            last: "Jackson",
            email: "Jim_Jackson@fibank.com",
            phone: "303-476-5678",
          },
          notes: {
            value: "signed undated blank checks are located in my socks drawer",
          },
        },
      },
      {
        id: "finabank002",
        type: CARDS.BANK_ACCOUNT.type,
        value: {
          title: "Wells Fargo",
          address: {
            street1: "123 Pearl Street",
            street2: undefined,
            city: "Boulder",
            state: "CO",
            zip: "80301",
          },
          contact: {
            first: "Jerry",
            middle: "",
            last: "Rothbern",
            email: "BusinessBanking_437@wells.com",
            phone: "303-738-3854",
          },
          notes: {
            value: "Operating account for Becker Enterprises",
          },
        },
      },
      {
        id: "finareti001",
        type: CARDS.RETIREMENT_ACCOUNT.type,
        value: {
          title: "Vanguard Roth IRA",
          retirement_acct: {
            acct_type: "Roth IRA",
            institution: "Vanguard",
          },
          address: {
            street1: "4920 East Vanguard Ave.",
            street2: undefined,
            city: "Scranton",
            state: "PA",
            zip: "10222",
          },
          contact: {
            first: "Fred",
            middle: "",
            last: "Goldberg",
            email: "FredGoldberg@vanguard.com",
            phone: "314-546-5787",
          },
        },
      },
      {
        id: "finaasse001",
        type: CARDS.LIFE_INSURANCE.type,
        value: {
          title: "Life Insurance Policy",
          life_insurance: {
            insurer: "Prudential",
            broker: "Janice Johnson",
            policy_type: "Term",
            policy_number: "264465-674736-O",
          },
        },
      },
      {
        id: "finainve001",
        type: CARDS.PORTFOLIO_ACCOUNT.type,
        value: {
          title: "Mutual Fund Account",
        },
      },
      {
        id: "finainve002",
        type: CARDS.REAL_ESTATE.type,
        value: {
          title: "Vacation Rental",
        },
      },
      {
        id: "finainve003",
        type: CARDS.OTHER_INVESTMENT.type,
        value: {
          title: "Convertible Note in Consular Enterprises for $50k",
        },
      },
      {
        id: "finabill001",
        type: CARDS.BILL.type,
        value: {
          title: "Storage Rent: $150 due on the 5th of the month",
        },
      },
      {
        id: "finabill002",
        type: CARDS.BILL.type,
        value: {
          title: "Mortgage: $1989.12 due on the 1st of the month",
        },
      },
      {
        id: "fb_finabill002",
        type: CARDS.FB_BILL.type,
        value: {
          title: "Mortgage: $1989.12 due on the 1st of the month",
        },
      },
      {
        id: "finabill003",
        type: CARDS.BILL.type,
        value: {
          title: "Xfinity autopays from checking",
        },
      },
      {
        id: "finabill004",
        type: CARDS.BILL.type,
        value: {
          title: "City utilties are paid online on the 1st",
        },
      },
      {
        id: "fb_finabill004",
        type: CARDS.FB_BILL.type,
        value: {
          title: "City utilties are paid online on the 1st",
        },
      },
      {
        id: "finabill005",
        type: CARDS.BILL.type,
        value: {
          title: "Xcel autopays via debit",
        },
      },
      // -------------- INSURANCE ----------------
      {
        id: "insuheal001",
        type: CARDS.HEALTH_INSURANCE.type,
        value: {
          title: "Kaiser Permanente Health Insurance",
          health_insurance: {
            policy_type: "Health Plan",
            policy_source: "Employee Health Plan",
            insured_primary: "Adam Becker",
            insured_other: "Penny Becker, Melissa Becker, Tom Becker",
            policy_number: "264465-674736-O",
            group_number: "1XA-45R-155T",
            policy_network: "HMO",
            policy_deductible: "$3500",
            policy_name: "KP Catastrophic Bronze HSA",
            policy_status: "active",
          },
          provider: {
            provider_company: "Kaiser Permanente",
            provider_contact: "Cavendish Fahrquahr",
            provider_email: "Cave_Fahr@kpermanente.com",
            provider_phone: "601-245-792  x 451985",
            provider_url: "www.kaiserpermanente.com",
          },
        },
      },
      {
        id: "insuhome001",
        type: CARDS.HOME_INSURANCE.type,
        value: {
          title: "State Farm",
        },
      },
      // -------------- PERSONAL ----------------
      {
        id: "perslice001",
        type: CARDS.LICENSE.type,
        value: {
          title: "Driver's License",
          license: {
            license_type: "Driver's License",
            license_source: "Colorado",
            license_number: "191-145-3667",
            license_expiration: "03/15/2022",
            license_name: "Adam Becker",
          },
        },
      },
      {
        id: "persfact001",
        type: CARDS.FACTS.type,
        value: {
          title: "Facts about my life.",
          facts_home: {
            facts_homeType: "I rent my primary residence",
            facts_maritalStatus: "Married",
            facts_minorDependent: true,
            facts_adultDependents: false,
            facts_hasPet: true,
          },
        },
      },
      {
        id: "persfactform001",
        type: CARDS.FACTS_FORM.type,
        value: {
          title: "Facts about my life.",
          facts_home: {
            facts_homeType: "I rent my primary residence",
            facts_maritalStatus: "Married",
            facts_minorDependent: true,
            facts_adultDependents: false,
            facts_hasPet: true,
          },
        },
      },

      // -------------- HOME ----------------
      {
        id: "homeutil001",
        type: CARDS.HOME_UTILITY.type,
        value: {
          title: "Xfinity Internet",
        },
      },
      {
        id: "homeutil002",
        type: CARDS.HOME_UTILITY.type,
        value: {
          title: "City Water, Sewer, and Trash",
        },
      },
      {
        id: "homeutil003",
        type: CARDS.HOME_UTILITY.type,
        value: {
          title: "Xcel Electricity and Gas",
        },
      },
      {
        id: "homeinst001",
        type: CARDS.PETCARE.type,
        value: {
          title: "Charger's Medication",
          petcare: {
            pet_name: "Charger",
            pet_type: "Chocolate Lab",
            pet_instructions: "Keep water bowl full at all times. Just give 2 cups of kibble per day.",
          },
          address_pet: {
            street1: "8989 South Street",
            street2: undefined,
            city: "Boulder",
            state: "CO",
            zip: "80309",
          },
          contact_veterinarian: {
            first: "Ben",
            middle: "",
            last: "Fuller",
            email: "DrFuller@coanimalcare.com",
            phone: "303-992-3465",
          },
          contact_petsitter: {
            first: "Carrie",
            middle: "",
            last: "Kruger",
            email: "Carrie041383@gmail.com",
            phone: "303-722-8229",
          },
          notes: {
            value: "Charger's pills are in the coffee cupboard.",
          },
        },
      },
      {
        id: "homeinst002",
        type: CARDS.HOME_INSTRUCTION.type,
        value: {
          title: "648 Iron Drive - House Sitting Instructions",
          home_instructions: {
            home_contact_name: "Carrie Kruger",
            home_contact_relation: "Pet Sitter",
            home_contact_instructions: "Set light timers on first floor to change every two days. Keep plants watered.",
          },
          address: {
            street1: "8989 South Street",
            street2: undefined,
            city: "Boulder",
            state: "CO",
            zip: "80309",
          },
          contact_name: {
            first: "Carrie",
            middle: "",
            last: "Kruger",
            email: "Carrie041383@gmail.com",
            phone: "303-722-8229",
          },
          notes: {
            value: "Trash is picked up on Tuesdays.",
          },
        },
      },
      // -------------- DIGITAL ----------------
      {
        id: "digidevi001",
        type: CARDS.DIGITAL_DEVICE.type,
        value: {
          title: "iPad",
        },
      },
      // -------------- THOUGHTS ----------------
      {
        id: "thoumess001",
        type: CARDS.THOUGHTS_MESSAGE.type,
        value: {
          title: "Thoughts for Becky",
          contact: {
            first: "Rebecca",
            middle: "",
            last: "Becker",
            email: "Beckbeck@gmail.com",
            phone: "314-546-5787",
          },
          address: {
            street1: "1632 Flower Street",
            street2: undefined,
            city: "Los Angeles",
            state: "CA",
            zip: "90222",
          },
        },
      },
      {
        id: "thoumess002",
        type: CARDS.THOUGHTS_MESSAGE.type,
        value: {
          title: "Thoughts for Penny",
          contact: {
            first: "Penny",
            middle: "",
            last: "Becker",
            email: "PennyBecker@gmail.com",
            phone: "720-967-4433",
          },
          address: {
            street1: "648 Iron Drive",
            street2: undefined,
            city: "Denver",
            state: "CO",
            zip: "80433",
          },
        },
      },
      {
        id: "thoumess003",
        type: CARDS.THOUGHTS_MESSAGE.type,
        value: {
          title: "For Tom",
          contact: {
            first: "Tom",
            middle: "",
            last: "Becker",
            email: "TPBecker@cu.edu",
            phone: "720-277-7388",
          },
          address: {
            street1: "4459 Highview Ave",
            street2: undefined,
            city: "Boulder",
            state: "CO",
            zip: "80306",
          },
        },
      },
      {
        id: "thouinst001",
        type: CARDS.THOUGHTS_INSTRUCTION.type,
        value: {
          title: "Roses on Mother's Day",
        },
      },
    ],
  },
  // this is cached server state
  persons: {
    items: [
      {
        id: "person001",
        status: "pending", // invited but not joined
        name: {
          first: "Melissa",
          middle: "",
          last: "Becker",
        },
        email: "mbecker@hotmail.com",
      },
      {
        id: "person002",
        status: "active", // a regular user
        name: {
          first: "Penny",
          middle: "",
          last: "Becker",
        },
        email: "Penny_Becker@mutual.com",
      },
      {
        id: "person003",
        status: "inactive",
        name: {
          first: "Philip",
          middle: "",
          last: "Reese",
        },
        email: "PReese012@gmail.com",
      },
      {
        id: "person004",
        status: "active",
        name: {
          first: "Tom",
          middle: "",
          last: "Becker",
        },
        email: "tgbecker@gmail.com",
      },
      {
        id: "person005",
        status: "active", // a regular user
        name: {
          first: "Gloria",
          middle: "",
          last: "Becker",
        },
        email: "gloria.becker@gmail.com",
      },
      {
        id: "person006",
        status: "active",
        name: {
          first: "Rebecca",
          middle: "",
          last: "Becker",
        },
        email: "Beckybecker@gmail.com",
      },
      {
        id: "person007",
        status: "active",
        name: {
          first: "Carrie",
          middle: "",
          last: "Kruger",
        },
        email: "Carrie041383@gmail.com",
      },
      {
        id: "person008",
        status: "active",
        name: {
          first: "Antonio",
          middle: "",
          last: "Falcono",
        },
        email: "AFalcono@jenkinsfirm.com",
      },
    ],
  },
  // this is cached server state
  shares: {
    items: [
      {
        id: "share001",
        record: "healadva001",
        recipient: "person001",
        event: "event001",
      },
      {
        id: "share002",
        record: "healadva001",
        recipient: "person002",
        event: "event001",
      },
      {
        id: "share003",
        record: "healadva001",
        recipient: "person003",
        event: "event001",
      },
      {
        id: "share004",
        record: "healadva001",
        recipient: "person004",
        event: "event001",
      },
      {
        id: "share005",
        record: "healadva002",
        recipient: "person003",
        event: "event005",
      },
      {
        id: "share006",
        record: "homeinst001",
        recipient: "person007",
        event: "event006",
      },
      {
        id: "share007",
        record: "homeinst002",
        recipient: "person007",
        event: "event006",
      },
      {
        id: "share009",
        record: "healmedi001",
        recipient: "person001",
        event: "event001",
      },
      {
        id: "share010",
        record: "healmedi001",
        recipient: "person002",
        event: "event001",
      },
      {
        id: "share011",
        record: "healmedi001",
        recipient: "person005",
        event: "event001",
      },
      {
        id: "share012",
        record: "healmedi001",
        recipient: "person006",
        event: "event001",
      },
      {
        id: "share013",
        record: "homeinst001",
        recipient: "person003",
        event: "event006",
      },
      {
        id: "share014",
        record: "homeinst002",
        recipient: "person003",
        event: "event006",
      },
      {
        id: "share015",
        record: "healitem001",
        recipient: "person001",
        event: "event001",
      },
      {
        id: "share016",
        record: "healitem001",
        recipient: "person002",
        event: "event001",
      },
      {
        id: "share017",
        record: "healadva002",
        recipient: "person001",
        event: "event005",
      },
      {
        id: "share018",
        record: "healadva002",
        recipient: "person002",
        event: "event005",
      },
      {
        id: "share019",
        record: "healadva002",
        recipient: "person004",
        event: "event005",
      },
      {
        id: "share020",
        record: "healalle001",
        recipient: "person001",
        event: "event001",
      },
      {
        id: "share021",
        record: "healalle001",
        recipient: "person004",
        event: "event001",
      },
      {
        id: "share022",
        record: "healmedi002",
        recipient: "person001",
        event: "event001",
      },
      {
        id: "share023",
        record: "healmedi002",
        recipient: "person002",
        event: "event001",
      },
      {
        id: "share024",
        record: "healprof003",
        recipient: "person001",
        event: "event001",
      },
      {
        id: "share025",
        record: "healinst001",
        recipient: "person002",
        event: "event001",
      },
      {
        id: "share026",
        record: "legawill001",
        recipient: "person002",
        event: "event002",
      },
      {
        id: "share027",
        record: "legawill001",
        recipient: "person004",
        event: "event002",
      },
      {
        id: "share028",
        record: "legatrus001",
        recipient: "person002",
        event: "event002",
      },
      {
        id: "share029",
        record: "legatrus001",
        recipient: "person003",
        event: "event002",
      },
      {
        id: "share030",
        record: "thouinst001",
        recipient: "person004",
        event: "event002",
      },
      {
        id: "share031",
        record: "legatrus002",
        recipient: "person002",
        event: "event002",
      },
      {
        id: "share032",
        record: "legatrus003",
        recipient: "person002",
        event: "event002",
      },
      {
        id: "share033",
        record: "legatrus001",
        recipient: "person002",
        event: "event004",
      },
      {
        id: "share034",
        record: "legatrus001",
        recipient: "person004",
        event: "event004",
      },
      {
        id: "share035",
        record: "legatrus001",
        recipient: "person001",
        event: "event003",
      },
      {
        id: "share036",
        record: "legatrus001",
        recipient: "person002",
        event: "event003",
      },
      {
        id: "share037",
        record: "perslice001",
        recipient: "person002",
        event: "event001",
      },
      {
        id: "share038",
        record: "perslice001",
        recipient: "person002",
        event: "event002",
      },
      {
        id: "share040",
        record: "thoumess001",
        recipient: "person006",
        event: "event002",
      },
      {
        id: "share041",
        record: "thoumess002",
        recipient: "person002",
        event: "event002",
      },
    ],
  },
  // this is cached server state
  videos: {
    items: [
      {
        id: "video001",
        type: "youtube",
        videoId: "VRwD9JL2sO0",
        blurb: "Caravan Palace",
      },
    ],
  },
  // **************************************************************** VOTES AND OTHERS' EVENTS
  // this is cached server state
  othersEvents: {
    items: [
      {
        id: "others_event_rebecca_incap",
        personId: "person006",
        title: "My Incapacity",
        type: "vote",
        status: "open", // voting is taking place (user has not voted yet)
        description: "If I'm unable to competently make my own decisions.",
        votesRemaining: 1,
      },
      {
        id: "others_event_melissa_daughter_graduation",
        personId: "person001",
        title: "Susan graduation",
        type: "vote",
        status: "open", // voting is taking place (user has already voted)
        description: "When Susan graduates from college.",
        votesRemaining: 1,
      },
      {
        id: "others_event_penny_death",
        personId: "person002",
        title: "Upon my passing.",
        type: "vote",
        status: "inactive", // no vote is taking place and the event hasn't been triggered yet
        description: "After I have passed away.",
        votesRemaining: 2,
      },
      {
        id: "others_event_tom_incap",
        personId: "person004",
        title: "My Marriage",
        type: "vote",
        status: "active", // voting has concluded and the event is triggered (user voted for this event)
        description: "When I have gotten married.",
        votesRemaining: 0,
      },
    ],
  },
  // this is cached server state
  myVotes: {
    items: [
      {
        id: "vote_1",
        eventId: "others_event_melissa_daughter_graduation",
        timestamp: 1589565600000, // May 15th, 2020 12:00 PM
      },
      {
        id: "vote_2",
        eventId: "others_event_tom_incap",
        timestamp: 1605834000000, // Nov 19, 2020.  6 PM MST
      },
    ],
  },
  // **************************************************************** OTHERS' RECORDS
  // TODO(ian): consider merging this into regular records
  // this is cached server state
  othersRecords: {
    items: [
      {
        id: "rebecca_med_remicade",
        owner: "person006",
        eventId: "others_event_rebecca_incap", // temporary, for demo only.
        hidden: true, // temporary, for demo only.
        type: CARDS.MEDICATION.type,
        value: {
          title: "Remicade",
          medication: {
            name: "Remicade",
            nickName: "Arthritis infusion",
            dosageAmount: "",
            dosageFrequency: "every two months",
            location: "Performed at the hospital.",
            importance: "High",
            isPrescribed: true,
          },
          prescription: {
            prescriberName: "Dr. Wali Ansari",
            prescriberPhone: "303-689-1482",
            prescriberEmail: "W.Ansari@sclhealth.com",
            startDate: "03/01/2018",
            endDate: "12/31/2022",
            isRefillable: true,
            fulfilledLocation: "Good Samaritan Medical Center",
            fulfilledPhone: "303-689-4000",
          },
        },
      },
      {
        id: "rebecca_health_insurance",
        owner: "person006",
        eventId: "others_event_rebecca_incap", // temporary, for demo only.
        hidden: true, // temporary, for demo only.
        type: CARDS.HEALTH_INSURANCE.type,
        value: {
          title: "Blue Cross Health Insurance",
          health_insurance: {
            policy_type: "Health Plan",
            policy_source: "Employee Health Plan",
            insured_primary: "Rebecca Becker",
            insured_other: "Susan Becker",
            policy_number: "187382-285037-O",
            group_number: "38A-42B-133T",
            policy_network: "PPO",
            policy_deductible: "$3500",
            policy_name: "Blue Cross PPO 3500",
            policy_status: "active",
          },
          provider: {
            provider_company: "Anthem Blue Cross",
            provider_contact: "Allison Jeffries",
            provider_email: "ajeffries@cobluecross.com",
            provider_phone: "303-820-3800 x 3816",
            provider_url: "www.anthem.com",
          },
          customFields: [
            {
              id: "custom_field_1",
              label: "Card Location",
              type: "string",
              value: "The insurance card is in my purse in the right flap, with the car insurance card.",
            },
          ],
        },
      },


      {
        id: "gloria_med_aricept",
        owner: "person005",
        eventId: "others_event_gloria_incap", // temporary, for demo only.
        hidden: false, // temporary, for demo only.
        type: CARDS.MEDICATION.type,
        value: {
          title: "Aricept",
          medication: {
            name: "Aricept - Donepezil",
            nickName: "Alzheimer pills",
            dosageAmount: "",
            dosageFrequency: "one per day",
            location: "in medicine cabinet",
            importance: "High",
            isPrescribed: true,
          },
          prescription: {
            prescriberName: "Dr. Jeff Bouger",
            prescriberPhone: "303-484-5600",
            prescriberEmail: "JBouger@westhealth.com",
            startDate: "05/01/2016",
            endDate: "12/31/2025",
            isRefillable: true,
            fulfilledLocation: "Walmart",
            fulfilledPhone: "303-565-2000",
          },
        },
      },
      {
        id: "gloria_health_insurance",
        owner: "person005",
        eventId: "others_event_gloria_incap", // temporary, for demo only.
        hidden: false, // temporary, for demo only.
        type: CARDS.HEALTH_INSURANCE.type,
        value: {
          title: "Medicare",
          health_insurance: {
            policy_type: "Health Insurance",
            policy_source: "Medicare",
            insured_primary: "Gloria Becker",
            insured_other: "",
            policy_number: "1WE-94FTL5-6013",
            group_number: "",
            policy_network: "",
            policy_deductible: "$0",
            policy_name: "Medicare",
            policy_status: "active",
          },
          provider: {
            provider_company: "US Goverment HHS",
            provider_contact: "Blanche Wilson",
            provider_email: "BWilson_45933@medicare.gov",
            provider_phone: "303-375-6659 x 6573899",
            provider_url: "www.medicare.gov",
          },
          customFields: [
            {
              id: "custom_field_1",
              label: "Card Location",
              type: "string",
              value: "My Medicare card is in my bottom right desk drawer",
            },
          ],
        },
      },
    ],
  },
  // this is cached server state
  settings: {
    display: {
      showGuides: false,
      theme: "light",
    },
    billing: {
      autoRenewal: true,
      notifyRenewal: false,
    },
    access: {
      MFA: true,
      MFAtype: "text",
      userName: "JamesB",
    },
    messages: {
      sortBy: "date",
      allowForward: false,
    },
  },
};

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";
import { Button, Col, Label, Modal, ModalBody, Row } from "reactstrap";
import { createImportantToKnow, updateImportantToKnow } from "../../../../redux/importantToKnowSlice.js";
import { removeNewCard } from "../../../../redux/newCardsSlice.js";
import { v4 as uuidV4 } from "uuid";

const ImportantToKnowForm = (props) => {
  const { title = "", value, onSubmit, id, recordId, dispatch, newCards } = props;
  // State
  const [itkContent, setItkContent] = useState(value ? value : "");

  // Handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (value || value === "") {
      dispatch(updateImportantToKnow({
        id,
        newValue: itkContent
      }));
    } else {
      dispatch(createImportantToKnow({
        id: uuidV4(),
        record: recordId,
        value: itkContent
      }));
    }
    if (newCards.items.includes(recordId)) {
      dispatch(removeNewCard(recordId));
    }
    onSubmit();
  };

  return (
    <>
      <Label>Important To Know: {title}</Label>
      <div>
        <textarea value={itkContent} style={{ height: 400, width: "100%" }}
            placeholder="Enter important information here.  Supports Markdown formatting."
            onChange={(e) => setItkContent(e.target.value)} />
      </div>
      <Button color="primary" block className="mt-2" onClick={handleSubmit}>
        Done
      </Button>
    </>
  );
};

ImportantToKnowForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

function mapFormStateToProps(state) {
  return {
    newCards: state.newCards,
  };
}

const ConnectedForm = connect(mapFormStateToProps)(ImportantToKnowForm);

/********************************************* VIEW ********************************/

const ImportantToKnowView = (props) => {
  const { recordId, getImportantToKnow, title, readOnly } = props;
  const [showModal, setShowModal] = useState(false);
  const importantToKnow = getImportantToKnow(recordId);
  const itkContent = importantToKnow.value;
  const importantToKnowId = importantToKnow.id;

  return (
    <>
      <Modal isOpen={showModal}>
        <ModalBody>
          <ConnectedForm
            id={importantToKnowId}
            recordId={recordId}
            value={itkContent}
            title={title}
            onSubmit={() => setShowModal(false)}
          />
        </ModalBody>
      </Modal>
      <Row>
        <Col lg className="col-xxl-5">
          <Row>
            <h6 className="font-weight-semi-bold ls ml-3 mb-0 text-uppercase">Important To Know</h6>
            {!readOnly && (
              <FontAwesomeIcon
                icon="pencil-alt"
                size="sm"
                className="ml-3 mb-0 cursor-pointer"
                onClick={() => setShowModal(true)}
              />
            )}
          </Row>
        </Col>
      </Row>
      <div className="mt-1" />
      {itkContent ? (
        <ReactMarkdown source={itkContent ? itkContent : ""} />
      ) : (
        <ReactMarkdown source={"*None*"} />
      )}
    </>
  );
};

const mapStateToProps = (state = {}) => {
  const items = state.importantToKnow && state.importantToKnow.items ? state.importantToKnow.items : [];
  const getImportantToKnow = (recordId) => {
    const item = items.find((i) => i.record === recordId);
    return item ? item : {};
  };
  return {
    getImportantToKnow,
  };
};

export default connect(mapStateToProps)(ImportantToKnowView);

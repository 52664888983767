import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cloneDeep } from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Col, Input, Modal, ModalBody, Row } from "reactstrap";
import { v4 as uuidV4 } from "uuid";
import { removeNewCard } from "../../../../redux/newCardsSlice.js";
import { clearRecordSubvalue, updateRecordSubvalue } from "../../../../redux/recordsSlice.js";
import ModalInputRow from "./ModalInputRow.js";

const NewCustomFieldModal = (props) => {
  const { onSubmit, onCancel, onTitleChange, title = "" } = props;

  return (
    <>
      <span className="font-weight-bold">Add a new field:</span>
      <hr />
      <ModalInputRow label="Field Name">
        <Input value={title} onChange={onTitleChange} />
      </ModalInputRow>
      <hr />
      <Row className="d-flex justify-content-end">
        <Col>
          <Button color="secondary" className="ml-1" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
        <Col className="col-auto">
          <Button color="primary" disabled={!title} className="mr-2" onClick={onSubmit}>
            Done
          </Button>
        </Col>
      </Row>
    </>
  );
};

const renderForm = (type, value = "", onChange) => {
  switch (type) {
    case "string": // has no type on the Input element
      return <Input value={value} onChange={({ target }) => onChange(target.value)} />;
    default:
      return <div>Unknown form type {type}</div>;
  }
};

const CustomFieldsModal = (props) => {
  const { id, value = {}, title, onSubmit, setOpen, dispatch, newCards } = props;
  const [state, setState] = useState(value.customFields ? value.customFields : []);
  const [showModal, setShowModal] = useState(false);
  const [newFieldTitle, setNewFieldTitle] = useState();

  const handleNewField = () => {
    const newField = {
      id: uuidV4(),
      label: newFieldTitle,
      type: "string",
    };
    const newState = [...state, newField];
    setShowModal(false);
    setState(newState);
  };

  const handleSubmit = () => {
    // If the user has removed all custom fields then drop the whole customFields subvalue.
    if (state.length === 0) {
      dispatch(clearRecordSubvalue({
        id,
        name: "customFields"
      }));
    } else {
      dispatch(updateRecordSubvalue({
        id,
        name: "customFields",
        newSubvalue: state
      }));
      if (newCards.items.includes(id)) {
        dispatch(removeNewCard(id));
      }
    }

    onSubmit();
  };

  const handleUpdate = (customFieldId, newValue) => {
    const newState = cloneDeep(state);
    const index = newState.findIndex((cf) => cf.id === customFieldId);
    const updatedCustomField = newState[index];
    updatedCustomField.value = newValue;
    newState.splice(index, 1, updatedCustomField);
    // explicitly unset the value if it's empty (null, undefined, "")
    // allow the "false" value though, which should only come from checkboxes.
    if (!newValue && newValue !== false) {
      delete updatedCustomField.value;
    }
    setState(newState);
  };

  const handleDelete = (id) => {
    const newState = state.filter((cf) => cf.id !== id);
    setState(newState);
  };

  return (
    <>
      <Modal isOpen={showModal} size="md">
        <ModalBody>
          <NewCustomFieldModal
            onSubmit={() => handleNewField()}
            onCancel={() => {
              setShowModal(false);
              setNewFieldTitle("");
            }}
            onTitleChange={({ target }) => setNewFieldTitle(target.value)}
            title={newFieldTitle}
          />
        </ModalBody>
      </Modal>
      <span className="font-weight-bold">Custom Fields: {title}</span>
      <hr />
      {state.map((cf) => {
        return (
          <ModalInputRow key={cf.id} label={cf.label}>
            <FontAwesomeIcon
              color="falcon-danger"
              size="sm"
              icon="times"
              className="fs--2 falcon-danger mr-1"
              onClick={() => handleDelete(cf.id)}
            />
            {renderForm(cf.type, cf.value, (newValue) => handleUpdate(cf.id, newValue))}
          </ModalInputRow>
        );
      })}
      {state.length === 0 && <span>No custom fields</span>}
      <hr />
      <Row className="d-flex justify-content-end mt-3">
        <Col>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
        </Col>
        <Col className="col-auto">
          <Button
            onClick={() => {
              setShowModal(!showModal);
            }}
            color="primary"
            className="mr-2"
          >
            Add New
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              handleSubmit();
            }}
            color="primary"
            className="mr-2"
          >
            Done
          </Button>
        </Col>
      </Row>
    </>
  );
};

function mapFormStateToProps(state) {
  return {
    newCards: state.newCards,
  };
}

export default connect(mapFormStateToProps)(CustomFieldsModal);

import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { ADDRESS, CONTACT } from "../../common/forms/sharedViews";
import { cloneDeep } from "lodash";

const HEALTH_AGENT_CONTACT_01 = cloneDeep(CONTACT);
HEALTH_AGENT_CONTACT_01.name = "health_agent_contact_01";
HEALTH_AGENT_CONTACT_01.label = "#1 Health Agent: Contact";

const HEALTH_AGENT_CONTACT_02 = cloneDeep(CONTACT);
HEALTH_AGENT_CONTACT_02.name = "health_agent_contact_02";
HEALTH_AGENT_CONTACT_02.label = "#2 Health Agent Alternate: Contact";

// const HEALTH_AGENT_CONTACT_03 = cloneDeep(CONTACT);
// HEALTH_AGENT_CONTACT_03.name = "health_agent_contact_03";
// HEALTH_AGENT_CONTACT_03.label = "#3 Health Agent Alternate: Contact";

const PHYSICIAN_PRIMARY_CONTACT_01 = cloneDeep(CONTACT);
PHYSICIAN_PRIMARY_CONTACT_01.name = "physician_primary_contact_01";
PHYSICIAN_PRIMARY_CONTACT_01.label = "#1 Primary Physician: Contact";

const PHYSICIAN_PRIMARY_CONTACT_02 = cloneDeep(CONTACT);
PHYSICIAN_PRIMARY_CONTACT_02.name = "physician_primary_contact_02";
PHYSICIAN_PRIMARY_CONTACT_02.label = "#2 Primary Physician Alternate: Contact";

// const PHYSICIAN_PRIMARY_CONTACT_03 = cloneDeep(CONTACT);
// PHYSICIAN_PRIMARY_CONTACT_03.name = "physician_primary_contact_03";
// PHYSICIAN_PRIMARY_CONTACT_03.label = "#3 Primary Physician Alternate: Contact";

const HEALTH_AGENT_ADDRESS_01 = cloneDeep(ADDRESS);
HEALTH_AGENT_ADDRESS_01.name = "health_agent_address_01";
HEALTH_AGENT_ADDRESS_01.label = "#1 Health Agent: Address";

const HEALTH_AGENT_ADDRESS_02 = cloneDeep(ADDRESS);
HEALTH_AGENT_ADDRESS_02.name = "health_agent_address_02";
HEALTH_AGENT_ADDRESS_02.label = "#2 Health Agent Alternate: Address";

// const HEALTH_AGENT_ADDRESS_03 = cloneDeep(ADDRESS);
// HEALTH_AGENT_ADDRESS_03.name = "health_agent_address_03";
// HEALTH_AGENT_ADDRESS_03.label = "#3 Health Agent Alternate: Address";

const PHYSICIAN_PRIMARY_ADDRESS_01 = cloneDeep(ADDRESS);
PHYSICIAN_PRIMARY_ADDRESS_01.name = "physician_primary_address_01";
PHYSICIAN_PRIMARY_ADDRESS_01.label = "#1 Primary Physician: Address";

const PHYSICIAN_PRIMARY_ADDRESS_02 = cloneDeep(ADDRESS);
PHYSICIAN_PRIMARY_ADDRESS_02.name = "physician_primary_address_02";
PHYSICIAN_PRIMARY_ADDRESS_02.label = "#2 Primary Physician Alternate: Address";

// const PHYSICIAN_PRIMARY_ADDRESS_03 = cloneDeep(ADDRESS);
// PHYSICIAN_PRIMARY_ADDRESS_03.name = "physician_primary_address_03";
// PHYSICIAN_PRIMARY_ADDRESS_03.label = "#3 Primary Physician Alternate: Address";

const ATTORNEY_LIVING_WILL_CONTACT = cloneDeep(CONTACT);
ATTORNEY_LIVING_WILL_CONTACT.name = "attorney_living_will_contact";
ATTORNEY_LIVING_WILL_CONTACT.label = "Attorney for Living Will: Contact";

const ATTORNEY_LIVING_WILL_ADDRESS = cloneDeep(ADDRESS);
ATTORNEY_LIVING_WILL_ADDRESS.name = "attorney_living_will_address";
ATTORNEY_LIVING_WILL_ADDRESS.label = "Attorney for Living Will: Address";

const LivingWillCard = (props) => {
  const left = [
    HEALTH_AGENT_CONTACT_01,
    HEALTH_AGENT_CONTACT_02,
    PHYSICIAN_PRIMARY_CONTACT_01,
    PHYSICIAN_PRIMARY_CONTACT_02,
    ATTORNEY_LIVING_WILL_CONTACT,
  ];
  const right = [
    HEALTH_AGENT_ADDRESS_01,
    HEALTH_AGENT_ADDRESS_02,
    PHYSICIAN_PRIMARY_ADDRESS_01,
    PHYSICIAN_PRIMARY_ADDRESS_02,
    ATTORNEY_LIVING_WILL_ADDRESS,
  ];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default LivingWillCard;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { Badge, Button, ButtonGroup, CustomInput, Label, Modal, ModalHeader } from "reactstrap";
import card from "../../assets/img/generic/card.png";
import transparent from "../../assets/img/generic/default.png";
import darkModeImg from "../../assets/img/generic/falcon-mode-dark.jpg";
import defaultModeImg from "../../assets/img/generic/falcon-mode-default.jpg";
import invertedImg from "../../assets/img/generic/inverted.png";
import vibrant from "../../assets/img/generic/vibrant.png";
import settings from "../../assets/img/illustrations/settings.png";
import AppContext from "../../context/Context";
import { createCookie, getCookieValue } from "../../helpers/utils";
import ScrollBarCustom from "../common/ScrollBarCustom";
import VerticalNavRadioBtn from "./VerticalNavStyleRadioBtn";

const SidePanelModal = ({ autoShow, showOnce, autoShowDelay, cookieExpireTime }) => {
  const { isOpenSidePanel, toggleModal, isDark, setIsDark, setIsOpenSidePanel } = useContext(AppContext);

  useEffect(() => {
    let modalStatus = getCookieValue("modalClose");

    if (modalStatus === null && autoShow) {
      setTimeout(() => {
        setIsOpenSidePanel((prev) => !prev);
        showOnce && createCookie("modalClose", false, cookieExpireTime);
      }, autoShowDelay);
    }
  }, [autoShow, showOnce, setIsOpenSidePanel, autoShowDelay, cookieExpireTime]);

  return (
    <Modal
      isOpen={isOpenSidePanel}
      toggle={toggleModal}
      modalClassName="overflow-hidden modal-fixed-right modal-theme"
      className="modal-dialog-vertical"
      contentClassName="vh-100 border-0"
    >
      <ModalHeader tag="div" toggle={toggleModal} className="modal-header-settings">
        <div className="py-1 flex-grow-1">
          <h5 className="text-white">
            <FontAwesomeIcon icon="palette" className="mr-2 fs-0" />
            Settings
          </h5>
          <p className="mb-0 fs--1 text-white opacity-75">Set your own customized style</p>
        </div>
      </ModalHeader>
      <ScrollBarCustom
        className="modal-body"
        contentProps={{
          // eslint-disable-next-line react/display-name
          renderer: ({ elementRef, ...restProps }) => (
            <span
              {...restProps}
              ref={elementRef}
              className={classNames("p-card position-absolute", { "border-left": isDark })}
            />
          ),
        }}
      >
        <h5 className="fs-0">Color Scheme</h5>
        <p className="fs--1">Choose the perfect color mode for your app.</p>
        <ButtonGroup className="btn-group-toggle btn-block">
          <Button color="theme-default" className={classNames("custom-radio-success", { active: !isDark })}>
            <Label for="theme-mode-default" className="cursor-pointer hover-overlay">
              <img className="w-100" src={defaultModeImg} alt="" />
            </Label>
            <CustomInput
              type="radio"
              id="theme-mode-default"
              label="Light"
              checked={!isDark}
              onChange={({ target }) => setIsDark(!target.checked)}
            />
          </Button>
          <Button color="theme-dark" className={classNames("custom-radio-success", { active: isDark })}>
            <Label for="theme-mode-dark" className="cursor-pointer hover-overlay">
              <img className="w-100" src={darkModeImg} alt="" />
            </Label>
            <CustomInput
              type="radio"
              id="theme-mode-dark"
              label="Dark"
              checked={isDark}
              onChange={({ target }) => setIsDark(target.checked)}
            />
          </Button>
        </ButtonGroup>
        <hr />
        <h5 className="fs-0 d-flex align-items-center">
          Vertical Navbar Style{" "}
          <Badge pill color="soft-success" className="fs--2 ml-2">
            new
          </Badge>
        </h5>
        <p className="fs--1">Switch between styles for your vertical navbar</p>
        <div className="btn-group-toggle btn-block btn-group-navbar-style">
          <ButtonGroup className="btn-block">
            <VerticalNavRadioBtn img={transparent} btnName={"transparent"} />
            <VerticalNavRadioBtn img={invertedImg} btnName={"inverted"} />
          </ButtonGroup>
          <ButtonGroup className="btn-block mt-3">
            <VerticalNavRadioBtn img={card} btnName={"card"} />
            <VerticalNavRadioBtn img={vibrant} btnName={"vibrant"} />
          </ButtonGroup>
        </div>
        <hr />
        <div className="text-center mt-5">
          <img src={settings} alt="settings" width={120} className="mb-4" />
          <h5>Refer a friend!</h5>
          <p className="fs--1">Refer a friend and get discounts on both of your accounts.</p>
          <Button
            color="primary"
            // href="https://themes.getbootstrap.com/product/falcon-admin-dashboard-webapp-template-react/"
            //FL - will put link to invite page here
          >
            Invite a Friend
          </Button>
        </div>
      </ScrollBarCustom>
    </Modal>
  );
};

SidePanelModal.propTypes = {
  autoShow: PropTypes.bool,
  showOnce: PropTypes.bool,
  autoShowDelay: PropTypes.number,
  cookieExpireTime: PropTypes.number,
};

SidePanelModal.defaultProps = {
  autoShow: true,
  showOnce: true,
  autoShowDelay: 3000,
  cookieExpireTime: 7200000,
};

export default SidePanelModal;

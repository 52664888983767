import { Button, Col, Row } from "reactstrap";
import React, { Fragment, useState } from "react";
import {
  usePostReminderMutation,
  useDeleteReminderMutation,
  useGetRemindersForListQuery,
  useGetListsQuery
} from "../../../services/oeApi.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Reminder = (props) => {
  const { reminder } = props;

  const [ deleteReminder, { isLoading, error} ] = useDeleteReminderMutation();

  const doDelete = (id) => {
    deleteReminder(id);
  }

  return (
    <Row>
      <Col>{reminder.id}: {reminder.text}</Col>
      <Col>
        <FontAwesomeIcon
            color="falcon-danger"
            size="sm"
            icon="times"
            className="fs--2 falcon-danger mt-2"
            onClick={() => doDelete(reminder.id)}
            disabled={isLoading}
        />
      </Col>
      {error && <Col>There was an error deleting this reminder.  Please try again.</Col>}
    </Row>
  )
}

const ReminderList = (props) => {
  const { list } = props;

  const [ newReminderText, setNewReminderText ] = useState("");
  const { data, error, isLoading } = useGetRemindersForListQuery(list.id);

  const [
    postReminder, // This is the mutation trigger
    { isLoading: isPosting }, // This is the destructured mutation result
  ] = usePostReminderMutation();

  const doSubmit = () => {
    if (newReminderText) {
      postReminder({
        listId: list.id,
        text: newReminderText
      });
      setNewReminderText("");
    }
  }

  if (isLoading) {
    return <div>Loading...</div>
  } else if (error) {
    return <div>Something went wrong.  Please try again later.</div>
  } else if (data) {
    const reminders = data.reminders;
    return (
      <div>
        {list.name} Reminders:
        {reminders.length > 0 && (reminders.map((r) => {
          return (
            <Reminder key={r.id} reminder={r} />
          )
        }))}

        {reminders.length === 0 && (
          <div>All done!</div>
        )}
        <form>
          <input type="text" aria-label="Reminder text" placeholder="Reminder text"
            value={newReminderText}
            onChange={(e) => setNewReminderText(e.target.value)}
          />
          <Button disabled={isPosting} aria-label="Add Reminder" onClick={() => doSubmit()} >Add Reminder</Button>
        </form>

      </div>
    )
  } else {
    return null;
  }

}

const Authenticated = (props) => {
  const { data, error, isLoading } = useGetListsQuery();

  if (isLoading) {
    return <div>Loading...</div>
  } else if (error) {
    return <div>Something went wrong.  Please try again later.</div>
  } else if (data) {
    const lists = data.lists;
    return (
      <div>
        Your Reminder Lists:
        {lists.length > 0 && (lists.map((l) => {
          return (
            <Fragment key={l.id}>
              <hr />
              <ReminderList key={l.id} list={l} />
            </Fragment>
          )
        }))}

        {lists.length === 0 && (
          <div>You have no lists.</div>
        )}
      </div>
    )
  } else {
    return null;
  }
}

export default Authenticated;

import { createSlice } from '@reduxjs/toolkit'

/**
 * State Shape:
 *  {
      items: [
        {
          id: "attachment1",
          recordId: "12345",
          type: 'img',
          icon: 'image',
          fileName: 'Winter',
          src: "https://i.pinimg.com/236x/89/f0/9b/89f09b9f0600113d4e20f1b161c231d6--cute-puppies-images-cute-puppy-pictures.jpg"
        },
        {
          id: "attachment2",
          recordId: "12345",
          type: 'img',
          icon: 'image',
          fileName: 'Coffee',
          src: "https://media.apnarm.net.au/media/images/2017/05/05/cof290317dog01-3h91uajxg604gzrn6o2_fct2911x2183x424.0x70.0_t1880.JPG"
        }
      ]
    }

    See EmailAttachment for allowed types, icons.
 */


// uses Immer internally to maintain state immutability
export const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState: {
    items: []
  },
  reducers: {
    deleteAttachment: (state, action) => {
      state.items = state.items.filter((i) => i.id !== action.payload);
    }
  }
})

export const { deleteAttachment } = attachmentsSlice.actions

export default attachmentsSlice.reducer

import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Col, Input, Modal, ModalBody, Row } from "reactstrap";
import { addPerson, newPerson } from "../../../redux/personsSlice";
import ModalInputRow from "../common/forms/ModalInputRow";

const NewPersonForm = (props) => {
  const { onSubmit, onCancel, isDone, name, onNameUpdate, email, onEmailUpdate } = props;

  return (
    <>
      <span className="font-weight-bold">Invite A Person:</span>
      <hr />
      <ModalInputRow label="First Name">
        <Input value={name.first ? name.first : ""} onChange={({ target }) => onNameUpdate("first", target.value)} />
      </ModalInputRow>
      <ModalInputRow label="Middle Name">
        <Input value={name.middle ? name.middle : ""} onChange={({ target }) => onNameUpdate("middle", target.value)} />
      </ModalInputRow>
      <ModalInputRow label="Last Name">
        <Input value={name.last ? name.last : ""} onChange={({ target }) => onNameUpdate("last", target.value)} />
      </ModalInputRow>
      <ModalInputRow label="Email Address">
        <Input value={email ? email : ""} onChange={({ target }) => onEmailUpdate(target.value)} />
      </ModalInputRow>
      <span>
        When you're done an invite will be sent to the email address provided, and the person will appear in your list
        of people. You can add shares and make this person a verifier for verification based events, but those will not
        take effect until the person accepts your invitation.
      </span>
      <hr />
      <Row className="d-flex justify-content-end">
        <Col>
          <Button color="secondary" className="ml-1" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
        <Col className="col-auto">
          <Button color="primary" disabled={!isDone()} className="mr-2" onClick={onSubmit}>
            Done
          </Button>
        </Col>
      </Row>
    </>
  );
};

const AddNewPersonWizard = (props) => {
  const { dispatch } = props;
  const [name, setName] = useState({});
  const [email, setEmail] = useState();
  const [open, setOpen] = useState();

  const handleNameUpdate = (which, value) => {
    const newName = { ...name, [which]: value };
    setName(newName);
  };

  const handleEmailUpdate = (value) => {
    setEmail(value);
  };

  const isDone = () => {
    return name && email && (name.first || name.last);
  };

  const onDone = () => {
    const person = newPerson(name.first, name.middle, name.last, email);
    dispatch(addPerson(person));
    setOpen(false);
  };

  const init = () => {
    setEmail(undefined);
    setName({});
  };

  return (
    <>
      <Modal size="md" isOpen={open}>
        <ModalBody>
          <NewPersonForm
            onSubmit={onDone}
            onCancel={() => setOpen(false)}
            isDone={isDone}
            name={name}
            onNameUpdate={handleNameUpdate}
            email={email}
            onEmailUpdate={handleEmailUpdate}
          />
        </ModalBody>
      </Modal>
      <Button
        className={props.className}
        color="primary"
        onClick={() => {
          init();
          setOpen(true);
        }}
      >
        Add A New Person
      </Button>
    </>
  );
};

export default connect()(AddNewPersonWizard);

import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidV4 } from "uuid";

/**
 * State Shape:
  persons: {
    items: [
      {
        id: "person1",
        status: "pending", // invited but not joined
        name: {
          first: "Elvis",
          middle: "",
          last: "Presley",
        },
        email: "elvis@example.com",
      },
      {
        id: "person2",
        status: "active", // a regular user
        name: {
          first: "Ray",
          middle: "",
          last: "Charles",
        },
        email: "ray@example.com",
      },
      {
        id: "person3",
        status: "inactive", // a person who is no longer active (deleted account, severed relationship)
      },
    ]
  },
 */
/************************ UTIL ******************************/
export function newPerson(first, middle, last, email) {
  return {
    id: uuidV4(),
    status: "pending",
    name: {
      first,
      middle,
      last
    },
    email
  }
}

// uses Immer internally to maintain state immutability
export const personsSlice = createSlice({
  name: "persons",
  initialState: {
    items: []
  },
  reducers: {
    /**
     * payload: a new person (see the newPerson function)
     */
    addPerson: (state, action) => {
      state.items.push(action.payload);
    },
  }
});

export const { addPerson } = personsSlice.actions;

export default personsSlice.reducer;

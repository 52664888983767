import { cloneDeep } from "lodash";
import React from "react";
import TwoColumnCard from "../../common/cards/TwoColumnCard";
import { ADDRESS, PLEASE_SELECT, PROVIDER } from "../../common/forms/sharedViews";

const SERVICE_SERVICE_ADDRESS = cloneDeep(ADDRESS);
SERVICE_SERVICE_ADDRESS.name = "address_service";
SERVICE_SERVICE_ADDRESS.label = "Service Address";

const SERVICE_PROVIDER_ADDRESS = cloneDeep(ADDRESS);
SERVICE_PROVIDER_ADDRESS.name = "address_payee";
SERVICE_PROVIDER_ADDRESS.label = "Payee Address";

const SERVICE = {
  name: "service",
  label: "Service Information",
  items: [
    {
      name: "service_type",
      label: "Type of service",
      toolTip: "Enter the type of service provided.",
      type: "string",
      validator: null,
    },
    {
      name: "acct_number",
      label: "Account Number",
      toolTip: "Enter the account number at this service.",
      type: "string",
      validator: null,
    },
    {
      name: "service_when",
      label: "When provided",
      toolTip: "Enter where and how this service is performed.",
      type: "textarea",
      validator: null,
    },
    {
      name: "due_date",
      label: "When Paid?",
      toolTip: "Enter the bill due day for this service.",
      type: "string",
      validator: null,
    },
    {
      name: "payment_how",
      label: "How Paid",
      toolTip: "Select how you pay this service.",
      type: "select",
      validator: null,
      options: [PLEASE_SELECT, "AutoPay", "Check", "Mail", "Online", "Phone", "At time of service", "Other"],
    },
  ],
};

const ServiceCard = (props) => {
  const left = [SERVICE, SERVICE_SERVICE_ADDRESS];
  const right = [PROVIDER, SERVICE_PROVIDER_ADDRESS];

  return <TwoColumnCard left={left} right={right} {...props} />;
};

export default ServiceCard;

import { belongsTo, createServer, hasMany, Model } from "miragejs";

export const initMirage = () => {
  createServer({

    models: {
      list: Model.extend({
        reminders: hasMany(),
      }),

      reminder: Model.extend({
        list: belongsTo(),
      }),
    },

    seeds(server) {
      const homeList = server.create("list", { name: "Home" });
      const workList = server.create("list", { name: "Work" });

      server.create("reminder", { list: homeList, text: "Walk the dog" });
      server.create("reminder", { list: homeList, text: "Take out the trash" });
      server.create("reminder", { list: homeList, text: "Tend to garden" });

      server.create("reminder", { list: workList, text: "Switch from Redux to RTK Query" });
      server.create("reminder", { list: workList, text: "Review design doc" });

    },

    routes() {
      this.namespace = "/";

      this.get("/api/v1/reminders", (schema) => {
        return schema.reminders.all();
      });

      this.post("/api/v1/reminders", (schema, request) => {
        const attrs = JSON.parse(request.requestBody);
        return schema.reminders.create(attrs);
      });

      this.delete("/api/v1/reminders/:id", (schema, request) => {
        const id = request.params.id;
        return schema.reminders.find(id).destroy();
      });

      this.get("/api/v1/lists", (schema, request) => {
        return schema.lists.all();
      });

      this.get("/api/v1/lists/:id/reminders", (schema, request) => {
        let listId = request.params.id;
        let list = schema.lists.find(listId);

        return list.reminders;
      });

      this.passthrough("/static/**");
      this.passthrough("https://cognito-idp.us-east-1.amazonaws.com");
    },

  });
}

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const tags = {
  Reminder: "Reminder",
  ReminderList: "ReminderList",
  List: "List",
  ListList: "ListList",
}

export const oeApi = createApi({
  reducerPath: "oeApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/v1/" }),
  tagTypes: [tags.Reminder, tags.ReminderList, tags.List, tags.ListList],

  endpoints: (builder) => ({

    getReminders: builder.query({
      query: () => "reminders",
      providesTags: (result) => {
        if (result) {
          // successful query
          return [
              ...result.reminders.map(({ id }) => ({ type: tags.Reminder, id })),
              { type: tags.ReminderList, id: "ALL" },
          ];
        } else {
          // an error occurred, but we still want to refetch this query when `{ type: 'Reminder', id: 'LIST' }`
          // is invalidated
          return [{ type: tags.ReminderList, id: "ALL" }];
        }
      }
    }),

    postReminder: builder.mutation({
      query: (reminder) => ({
        url: "reminders",
        method: "POST",
        body: reminder,
      }),
      // we're adding a new reminder, so all the existing ones are still valid but the list of all isn't.
      // also if the reminder had a list id then invalidate that specific reminder list.
      invalidatesTags: (result, error, reminder) => {
        if (!error) {
          const toInvalidate = [{ type: tags.ReminderList, id: "ALL" }];
          if (reminder.listId) {
            toInvalidate.push({ type: tags.ReminderList, id: reminder.listId });
          }
          return toInvalidate;
        }
      }
    }),

    deleteReminder: builder.mutation({
      query: (id) => ({
        url: `reminders/${id}`,
        method: "DELETE"
      }),
      // we've removed one, so invalidate it.  This tag exists on any lists containing this reminder.
      invalidatesTags: (result, error, id) => [{ type: tags.Reminder, id }],
    }),

    getRemindersForList: builder.query({
      query: (listId) => `lists/${listId}/reminders`,
      providesTags: (result, error, listId) => {
        if (result) {
          // successful query
          return [
              // add a { type: "Reminder", id: id } tag for each result in the list
              ...result.reminders.map(({ id }) => ({ type: tags.Reminder, id })),
              // also tag it with its list id: { type: "ReminderList", id: 3 }
              { type: tags.ReminderList, id: listId },
          ];
        } else {
          // an error occurred, but we still want to refetch this query when { type: "ReminderList", id: "3" }
          // is invalidated, such as when adding a new reminder to the list.
          return [{ type: tags.ReminderList, id: listId }];
        }
      }
    }),

    getLists: builder.query({
      query: () => "lists",
      providesTags: (result) => {
        if (result) {
          // successful query
          return [
              // add a { type: "List", id: id } tag for each list in the result
              ...result.lists.map(({ id }) => ({ type: tags.List, id })),
              // also tag it with its list id: { type: "ListList", id: "ALL"}
              { type: tags.ListList, id: "ALL" },
          ];
        } else {
          // an error occurred, but we still want to refetch this query when { type: "ListList", id: "ALL" }
          // is invalidated, such as when adding a new list.
          return [{ type: tags.ListList, id: "ALL" }];
        }
      }
    }),

  }),
})

export const {
  useGetRemindersQuery,
  usePostReminderMutation,
  useDeleteReminderMutation,
  useGetRemindersForListQuery,
  useGetListsQuery,
} = oeApi;

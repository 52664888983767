// unused, but the screen still works if you go straight to the URL
export const VIDEO = {
  name: "Video Test",
  to: "/video/123",
  icon: "play",
};

export const DASHBOARD = {
  name: "Dashboard",
  to: "/",
  icon: "chart-pie",
};

const IMPORTANT_TO_KNOW = {
  name: "Important To Know",
  to: "/my/important",
};

export const ABOUT_MY = {
  name: "Personal Binder",
  to: "/my",
  icon: ["fas", "user-circle"],
  children: [
    IMPORTANT_TO_KNOW,
    {
      to: "/my/health",
      name: "Health",
      children: [
        { to: "/my/health/directives", name: "Advance Directives" },
        { to: "/my/health/allergies", name: "Allergies" },
        { to: "/my/health/medications", name: "Medications" },
        { to: "/my/health/physical", name: "Physical Information" },
        { to: "/my/health/items", name: "Items I Need" },
        { to: "/my/health/insurance", name: "Health Insurance" },
        { to: "/my/health/professionals", name: "Health Professionals" },
        { to: "/my/health/instructions", name: "Health Instructions" },
      ],
    },
    {
      to: "/my/finance",
      name: "Finances",
      children: [
        { to: "/my/finance/bills", name: "Bills" },
        { to: "/my/finance/assets/bank_accounts", name: "Bank Accounts" },
        { to: "/my/finance/assets/retirement_accounts", name: "Retirement Accounts" },
        { to: "/my/finance/assets/investments", name: "Investments" },
        { to: "/my/possessions/business_property", name: "Business Property", disabled: true },
        { to: "/my/finance/assets/other", name: "Other Assets", disabled: true },
        { to: "/my/finance/assets/credit_cards", name: "Credit Cards", disabled: true },
        { to: "/my/finance/liabilities/debts", name: "Debts", disabled: true },
        { to: "/my/finance/liabilities/gifts", name: "Gifts and Charities", disabled: true },
        { to: "/my/finance/liabilities/other", name: "Other Liabilities", disabled: true },
        { to: "/my/finance/tax_returns", name: "Tax Returns", disabled: true },
        { to: "/my/finance/professionals", name: "Financial Professionals", disabled: true },
        { to: "/my/finance/instructions", name: "Financial Instructions" },
      ],
    },
    {
      to: "/my/legal",
      name: "Legal Information",
      children: [
        { to: "/my/legal/wills", name: "Will" }, // TODO(frank): should this be plural for consistency?
        { to: "/my/legal/trusts", name: "Trusts" },
        { to: "/my/legal/poas", name: "Powers of Attorney" },
        { to: "/my/legal/other", name: "Other Legal Documents", disabled: true },
        { to: "/my/legal/professionals", name: "Legal Professionals" },
        { to: "/my/legal/instructions", name: "Legal Instructions" },
      ],
    },
    {
      to: "/my/personal",
      name: "Personal Information",
      children: [
        { to: "/my/spiritual/belief", name: "Beliefs and Faith", disabled: true },
        { to: "/my/personal/id", name: "IDs and Licenses" },
        { to: "/my/spiritual/professionals", name: "Important Advisors", disabled: true },
        { to: "/my/personal/degrees", name: "Degrees and Service", disabled: true },
        { to: "/my/personal/photos", name: "Photos", disabled: true },
        { to: "/my/personal/instructions", name: "Wild Ideas", disabled: true },
        { to: "/my/personal/other", name: "Other Personal Info", disabled: true },
      ],
    },
    {
      to: "/my/insurance",
      name: "Insurance",
      children: [
        { to: "/my/insurance/health", name: "Health" },
        { to: "/my/insurance/life", name: "Life" },
        { to: "/my/insurance/home", name: "Home" },
        { to: "/my/insurance/auto", name: "Auto", disabled: true },
        { to: "/my/insurance/other", name: "Other Insurance", disabled: true },
        // { to: '/my/insurance/professionals', name: 'Insurance Professionals' },
        { to: "/my/insurance/instructions", name: "Insurance Instructions", disabled: true },
      ],
    },
    {
      to: "/my/home",
      name: "Home",
      children: [
        { to: "/my/home/information", name: "Information" },
        { to: "/my/home/insurance", name: "Insurance" },
        // { to: '/my/home/maintenance', name: 'Maintenance' },
        { to: "/my/home/instructions", name: "Home Instructions" },
      ],
    },
    {
      to: "/my/possessions",
      name: "Possessions",
      children: [
        { to: "/my/possessions/items", name: "Items" },
        { to: "/my/possessions/vehicles", name: "Vehicles" },
        // { to: '/my/possessions/storage', name: 'Item Storage' },
        // { to: '/my/possessions/intellectual_property', name: 'Intellectual Property' },
        { to: "/my/possessions/other", name: "Other Possessions", disabled: true },
        // { to: '/my/possessions/instructions', name: 'Possession Instructions' },
      ],
    },
    {
      to: "/my/digital",
      name: "Digital Life",
      children: [
        { to: "/my/digital/devices", name: "Devices and Access" },
        { to: "/my/digital/social_media", name: "Social Media", disabled: true },
        { to: "/my/digital/email", name: "Email", disabled: true },
        { to: "/my/digital/subscriptions", name: "Subscriptions", disabled: true },
        { to: "/my/digital/websites", name: "Websites and Subscriptions", disabled: true },
        { to: "/my/digital/storage", name: "Digital Storage", disabled: true },
        { to: "/my/digital/instructions", name: "Digital Instructions", disabled: true },
      ],
    },
    {
      to: "/my/family",
      name: "Family",
      children: [
        { to: "/my/family/emergency", name: "Emergency Contacts" },
        { to: "/my/family/members", name: "Members", disabled: true },
        { to: "/my/family/pets", name: "Pets", disabled: true },
        { to: "/my/community/friends", name: "Friends", disabled: true },
        { to: "/my/family/ancestors", name: "Ancestors", disabled: true },
        { to: "/my/family/instructions", name: "Family Instructions", disabled: true },
      ],
    },
    // {
    //   to: '/my/community', name: 'Community', children: [
    //     { to: '/my/community/friends', name: 'Friends' },
    //     { to: '/my/community/neighbors', name: 'Neighbors' },  // use the same Main as /my/home/neighbors
    //     { to: '/my/community/volunteering', name: 'Volunteering' },
    //     { to: '/my/community/groups', name: 'Groups' },
    //     { to: '/my/community/orgs', name: 'Organizations' },
    //     { to: '/my/community/instructions', name: 'Community Instructions' },
    //   ]
    // },
    {
      to: "/my/career",
      name: "Career",
      children: [
        { to: "/my/career/employers", name: "Employers", disabled: true },
        { to: "/my/career/businesses", name: "Businesses", disabled: true },
        { to: "/my/career/colleagues", name: "Colleagues", disabled: true },
        { to: "/my/career/professional_orgs", name: "Professional Orgs", disabled: true },
        { to: "/my/career/instructions", name: "Career Instructions", disabled: true },
      ],
    },
    // {
    //   to: '/my/interests', name: 'Interests', children: [
    //     { to: '/my/interests/music', name: 'Arts and Music' },
    //     { to: '/my/interests/food', name: 'Food and Drink' },
    //     { to: '/my/interests/media', name: 'TV and Movies' },
    //     { to: '/my/interests/hobbies', name: 'Hobbies' },
    //     { to: '/my/interests/reading', name: 'Reading' },
    //     { to: '/my/interests/games', name: 'Games' },
    //     { to: '/my/interests/sports', name: 'Sports' },
    //     { to: '/my/interests/instructions', name: 'Interests Instructions' },
    //   ]
    // },
    {
      to: "/my/thoughts",
      name: "Thoughts and Messages",
      children: [
        // { to: '/my/thoughts/letters', name: 'Letters' },
        { to: "/my/thoughts/messages", name: "Messages" },
        { to: "/my/thoughts/wishes", name: "Wishes", disabled: true },
        { to: "/my/thoughts/instructions", name: "Legacy Instructions" },
      ],
    },
  ],
};

export const FAMILY_BINDER = {
  name: "Family Binder",
  to: "/family",
  icon: ["fas", "users"],
  children: [
    {
      to: "/my/health",
      name: "Health",
      children: [
        { to: "/family/health/directives", name: "Advance Directives" },
        { to: "/family/health/professionals", name: "Medical Professionals" },
      ],
    },
    {
      to: "/my/finance",
      name: "Finances",
      children: [
        { to: "/family/finance/bills", name: "Bills" },
      ],
    },
    {
      to: "/my/legal",
      name: "Legal",
      children: [
        { to: "/family/legal/trusts", name: "Trusts" },
        { to: "/family/legal/poas", name: "Powers of Attorney" },
      ],
    },
  ],
};

export const RECOMMENDATIONS = {
  name: "Recommendations",
  to: "/my/recommendations",
  icon: ["fas", "star"],
  children: [
    { to: "/my/recommendations/life-facts", name: "Life Facts" },
    { to: "/my/recommendations/recommended-cards", name: "Recommended Cards" },
  ],
};

export const INBASKET = {
  name: "In Basket",
  to: "/my/inbasket",
  // icon: "arrow-right",
  icon: "mail-bulk",
  children: [{ to: "/my/inbasket/feed", name: "Feed" }],
};

export const SHARES = {
  name: "Sharing Overview",
  to: "/sharing/overview",
  icon: "people-arrows",
  children: [
    { to: "/sharing/overview", name: "My Shares" },
    { to: "/sharing/events", name: "My Events" },
    { to: "/sharing/verifications", name: "My Verifications" },
  ],
};

export const OTHERS_SHARES = {
  name: "Shared With Me",
  to: "/sharing/with_me",
  icon: ["fas", "people-carry"],
};
